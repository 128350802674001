import React, { useEffect, useCallback, useMemo, useState } from 'react'
import Modal from 'react-modal'
import Cabecalho from './cabecalho/index'
import Breadcrumb from '../breadcrumb'
import SelecionadorDeTipoDeAtendimento from './atendimento/selecionador-de-tipo-de-atendimento'
import Atendimento from './atendimento'
import ProvedorDeContextoDoProntuario from './contexto/com-redux'
import CancelarAtendimentoIniciado from './atendimento/cancelar-atendimento-iniciado'
import AdicionarTemplate from './atendimento/adicionar-template'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Imprimir from './linha-do-tempo/impressao'
import GestaoDeCondicoesClinicas from './cabecalho/gestao-de-condicoes-clinicas'
import AssinaturaComCertificadoFisico from './assinatura-fisico'
import AssinaturaComCertificadoEmNuvem from './assinatura-em-nuvem'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { converterObjetoParaArray } from '../../bibliotecas/conversao'
import { MemedProvider } from '../../configuracoes/memed'
import { componentesQuePrecisamDoHistorico } from './componentes-que-precisam-do-historico'
import LinhaDoTempo from "./linha-do-tempo";

export default function Prontuario(props) {
  const {
    adicionarTagNoPacienteDoProntuario,
    adicionarTemplateDoProntuario,
    alterarTermoDaTimeline,
    cancelarAtendimento,
    fotosDaSugestao,
    history,
    listarAtendimentosFinalizados,
    listarHistoricoDeCamposDoProntuarioDoPaciente,
    listarMedicamentosDoProntuario,
    listarOrientacoesDePaginas,
    listarPosologias,
    listarProfissionaisDeSaudeDeSugestao,
    listarSugestoesDePaciente,
    listarTagsDePacientes,
    listarTamanhosDePaginas,
    listarTemplatesDoProntuario,
    listarTiposDeAtendimentoDoUsuarioLogado,
    listarTokensDeSugestao,
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    listarSituacoesAssociadasAAlteracoesGastrointestinais,
    listarSituacoesAssociadasADorNoPeito,
    listarSituacoesAssociadasAQueda,
    listarSituacoesAssociadasASintomasUrinarios,
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica,
    listarIntensidadesDasDoresDaTriagem,
    match,
    medicamentos,
    orientacoesDePagina,
    prontuario,
    recuperarAtendimentoIniciado,
    recuperarCabecalhoDoProntuario,
    recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador,
    recuperarPrescricaoPdfNaMemed,
    recuperarTeleconsultaDoAtendimento,
    recuperarVersaoDoTipoDeAtendimento,
    removerImagemDaMemoriaPorIdentificador,
    removerTagDoPacienteDoProntuario,
    salvarSecaoDoAtendimento,
    selecionarItemDaTimeline,
    sugestoes,
    tamanhosDePaginas,
    recuperarCertificadosEmNuvemDoUsuario,
    usuario,
    listarTriagensDoPaciente,
  } = props

  const { identificador, identificadorDoAtendimento, identificadorDoAgendamento } = match.params
  const identificadorDoUsuario = usuario ? usuario.identificador : null
  const atendimento = prontuario && prontuario.atendimento

  const [exibirImprimirProntuario, setExibirImprimirProntuario] = useState(false)
  const [exibirFinalizarAtendimento, setExibirFinalizarAtendimento] = useState(false)
  const [exibirCancelarAtendimento, setExibirCancelarAtendimento] = useState(false)
  const [exibirAdicionarTemplate, setExibirAdicionarTemplate] = useState(false)
  const [exibirAssinaturaComCertificadoFisico, setExibirAssinaturaComCertificadoFisico] = useState(false)
  const [exibirAssinaturaComCertificadoEmNuvem, setExibirAssinaturaComCertificadoEmNuvem] = useState(false)
  const [statusDaSecao, setStatusDaSecao] = useState([])
  const [template, setTemplate] = useState('')
  const [componente, setComponente] = useState('')
  const [reiniciarMemed, setReiniciarMemed] = useState(false)

  const podeUtilizarTokens = usePossuiAsPermissoes([permissoes.LISTAR_TOKENS_DO_PACIENTE, permissoes.LISTAR_TOKENS_DE_TEMPLATE])
  const podeImprimirProntuario = usePossuiAsPermissoes([permissoes.FAZER_DOWNLOAD_DO_PRONTUARIO_DO_PACIENTE])
  const podeIniciarAtendimento = usePossuiAsPermissoes([permissoes.INICIAR_ATENDIMENTO])
  const podeListarMedicamentos = usePossuiAsPermissoes([permissoes.LISTAR_MEDICAMENTOS])
  const podeListarTiposDeAtendimento = usePossuiAsPermissoes([permissoes.LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO])
  const podeListarAtendimentoIniciados = usePossuiAsPermissoes([permissoes.LISTAR_ATENDIMENTOS_INICIADOS_PELO_USUARIO])

  const url = `/cadastros/pacientes/${identificador}/prontuario`

  useEffect(() => {
    if (identificadorDoUsuario && podeListarTiposDeAtendimento) {
      listarTiposDeAtendimentoDoUsuarioLogado(identificadorDoUsuario, identificadorDoAgendamento)
    }
  }, [listarTiposDeAtendimentoDoUsuarioLogado, identificadorDoUsuario, identificadorDoAgendamento, podeListarTiposDeAtendimento])

  useEffect(() => {
    recuperarCabecalhoDoProntuario(identificador)
    listarHistoricoDeCamposDoProntuarioDoPaciente(identificador)
    setReiniciarMemed(true)
  }, [recuperarCabecalhoDoProntuario, listarHistoricoDeCamposDoProntuarioDoPaciente, identificador])

  useEffect(() => {
    if (podeListarAtendimentoIniciados) {
      recuperarAtendimentoIniciado(identificador)
    }
  }, [recuperarAtendimentoIniciado, identificador, podeListarAtendimentoIniciados])

  useEffect(() => {
    if (atendimento && (!identificadorDoAtendimento || parseInt(identificadorDoAtendimento) !== atendimento.identificador)) {
      history.push(`${url}/${atendimento.identificador}`)
    } else if (identificadorDoAgendamento === undefined && (!atendimento || !atendimento.identificador)) {
      history.push(`${url}`)
    }
  }, [history, identificadorDoAtendimento, identificadorDoAgendamento, atendimento, url, setReiniciarMemed])

  useEffect(() => {
    if (podeListarMedicamentos) { listarMedicamentosDoProntuario() }
    listarTamanhosDePaginas()
    listarOrientacoesDePaginas()
    listarPosologias()
    listarTagsDePacientes()
  }, [listarTamanhosDePaginas, listarOrientacoesDePaginas, listarMedicamentosDoProntuario, listarPosologias,
    listarTagsDePacientes, listarProfissionaisDeSaudeDeSugestao, listarTokensDeSugestao, podeListarMedicamentos])

  useEffect(() => {
    if (identificador && podeUtilizarTokens) {
      listarTokensDeSugestao()
      listarSugestoesDePaciente(identificador)
      listarProfissionaisDeSaudeDeSugestao()
      listarTemplatesDoProntuario()
    }
  }, [listarProfissionaisDeSaudeDeSugestao, listarSugestoesDePaciente, listarTokensDeSugestao, listarTemplatesDoProntuario, identificador, podeUtilizarTokens])

  useEffect(() => { listarAtendimentosFinalizados(identificador) }, [listarAtendimentosFinalizados, identificador])
  useEffect(() => { listarTriagensDoPaciente(identificador) }, [listarTriagensDoPaciente, identificador])

  const dadosDeSugestao = useMemo(() => extrairDadosDeSugestoes(sugestoes, identificadorDoUsuario), [sugestoes, identificadorDoUsuario])

  useEffect(() => {
    const profissionaisDeSaude = converterObjetoParaArray(dadosDeSugestao.profissionaisDeSaude)

    profissionaisDeSaude && profissionaisDeSaude.filter(x => x.foto).forEach(item => {
      recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador(item.identificador, item.foto)
    })

    return () => profissionaisDeSaude && profissionaisDeSaude.filter(x => x.foto).forEach(item => {
      removerImagemDaMemoriaPorIdentificador(item.foto)
    })
  }, [dadosDeSugestao.profissionaisDeSaude, recuperarMiniaturaDaFotoDoProfissionalDeSaudePorIdentificador, removerImagemDaMemoriaPorIdentificador])

  useEffect(() => {
    listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia()
    listarSituacoesAssociadasAAlteracoesGastrointestinais()
    listarSituacoesAssociadasADorNoPeito()
    listarSituacoesAssociadasAQueda()
    listarSituacoesAssociadasASintomasUrinarios()
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica()
    listarIntensidadesDasDoresDaTriagem()
  }, [listarSituacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
    listarSituacoesAssociadasAAlteracoesGastrointestinais,
    listarSituacoesAssociadasADorNoPeito,
    listarSituacoesAssociadasAQueda,
    listarSituacoesAssociadasASintomasUrinarios,
    listarSintomasAssociadosAoLocalDaDorMusculoesqueletica,
    listarIntensidadesDasDoresDaTriagem])

  const usuariosDaSugestao = useMemo(() => dadosDeSugestao.profissionaisDeSaude.reduce((acumulado, atual) => {
    return acumulado.concat({ id: atual.identificador, display: atual.nome, foto: atual.foto && fotosDaSugestao[atual.foto] })
  }, []), [dadosDeSugestao.profissionaisDeSaude, fotosDaSugestao])

  const dadosDaSugestaoDePaciente = useMemo(() => dadosDeSugestao.tokens.reduce((acumulado, atual, idx) => {
    return acumulado.concat({ id: `${idx} ${dadosDeSugestao.sugestoesDePaciente[atual.nome]}`, display: atual.nome.substring(2) })
  }, []), [dadosDeSugestao.sugestoesDePaciente, dadosDeSugestao.tokens])

  const decodificador = useMemo(() => dadosDeSugestao.tokens.reduce((acumulado, atual) => {
    return acumulado.concat({ id: atual.nome, display: dadosDeSugestao.sugestoesDePaciente[atual.nome] })
  }, []), [dadosDeSugestao.sugestoesDePaciente, dadosDeSugestao.tokens])

  const tokensDeSugestao = {
    usuariosDaSugestao: usuariosDaSugestao,
    dadosDaSugestao: dadosDaSugestaoDePaciente,
    templates: dadosDeSugestao.templates,
    decodificador: decodificador,
  }

  const salvarSecao = useCallback((atendimento, dadosDaSecao) => salvarSecaoDoAtendimento(identificador, atendimento, dadosDaSecao),
    [identificador, salvarSecaoDoAtendimento]
  )

  if (!prontuario) {
    return null
  }

  const finalizar = async () => {
    const finalizou = await props.finalizarAtendimento(identificador, atendimento)

    if (finalizou) {
      fecharFinalizarAtendimento()
      history.push(url)
      listarAtendimentosFinalizados(identificador, prontuario.timeline.termo)
      listarHistoricoDeCamposDoProntuarioDoPaciente(identificador, componentesQuePrecisamDoHistorico)

      return true
    }

    return false
  }

  const atendimentoDoProntuario = atendimento && {
    ...atendimento,
    paciente: {
      ...atendimento.paciente,
      idade: prontuario.cabecalho && prontuario.cabecalho.identificacao && prontuario.cabecalho.identificacao.idade ? prontuario.cabecalho.identificacao.idade : null
    }
  }

  function abrirImprimirProntuario() {
    setExibirImprimirProntuario(true)
  }

  function fecharImprimirProntuario() {
    setExibirImprimirProntuario(false)
  }

  function abrirFinalizarAtendimento() {
    setExibirFinalizarAtendimento(true)
  }

  function fecharFinalizarAtendimento() {
    setExibirFinalizarAtendimento(false)
  }

  function abrirCancelarAtendimento() {
    setExibirCancelarAtendimento(true)
  }

  function fecharCancelarAtendimento() {
    setExibirCancelarAtendimento(false)
  }

  function abrirAdicionarTemplate(template, componente) {
    setTemplate(template)
    setComponente(componente)
    setExibirAdicionarTemplate(true)
  }

  function fecharAdicionarTemplate() {
    setExibirAdicionarTemplate(false)
  }

  const abrirAssinaturaComCertificadoFisico = async () => {
    const finalizou = await finalizar()

    if (finalizou) {
      setExibirAssinaturaComCertificadoFisico(true)
    }
  }

  const abrirAssinaturaComCertificadoEmNuvem = async () => {
    const finalizou = await finalizar()

    if (finalizou) {
      setExibirAssinaturaComCertificadoEmNuvem(true)
    }
  }

  function fecharAssinaturaDigital() {
    setExibirAssinaturaComCertificadoFisico(false)
    setExibirAssinaturaComCertificadoEmNuvem(false)
  }

  const cancelarAtendimentoIniciado = async dados => {
    const cancelou = await cancelarAtendimento(identificador, atendimento, dados)

    if (cancelou) {
      fecharCancelarAtendimento()
      setStatusDaSecao([])
      history.push(url)
    }
  }

  return (
    <MemedProvider>
      <Modal
        isOpen={exibirImprimirProntuario}
        className='modal'
        contentLabel='Modal para impressão do prontuario'
        style={{ content: { width: '400px' } }}
      >
        <Imprimir
          fechar={fecharImprimirProntuario}
          atendimento={atendimento}
          orientacoesDePagina={orientacoesDePagina}
          tamanhosDePaginas={tamanhosDePaginas}
          identificadorDoPaciente={identificador}
        />
      </Modal>
      <Modal
        isOpen={exibirCancelarAtendimento}
        className='modal-cancelar-atendimento'
      >
        <CancelarAtendimentoIniciado
          cancelar={cancelarAtendimentoIniciado}
          fechar={fecharCancelarAtendimento}
        />
      </Modal>
      <Modal
        isOpen={exibirAdicionarTemplate}
        className='modal-adicionar-template'
      >
        <AdicionarTemplate
          fechar={fecharAdicionarTemplate}
          salvar={adicionarTemplateDoProntuario}
          template={template}
          componente={componente}
        />
      </Modal>
      <Modal
        isOpen={exibirAssinaturaComCertificadoFisico}
        className='modal'
        contentLabel='Modal para Assinatura Digital'
      >
        <AssinaturaComCertificadoFisico
          fecharModal={fecharAssinaturaDigital}
        />
      </Modal>
      <Modal
        isOpen={exibirAssinaturaComCertificadoEmNuvem}
        className='modal'
        contentLabel='Modal para Assinatura Digital'
      >
        <AssinaturaComCertificadoEmNuvem
          fecharModal={fecharAssinaturaDigital}
          origem='prontuario'
        />
      </Modal>
      <ProvedorDeContextoDoProntuario identificador={identificador}>
        <div className='page-configuracoes-agendamento prontuario'>
          <div className='container'>
            <div className='main-content-form'>
              <div className='cabecalho-fixo-prontuario'>
                <div className='header-page-form'>
                  <Breadcrumb />
                  <div className='header-page-tools'>
                    <div className='header-page-tools'>
                      <RotaComAutenticacao
                        exact
                        path={[
                          `/cadastros/pacientes/:identificador/prontuario`,
                          `/cadastros/pacientes/:identificador/prontuario/novo/origem/:identificadorDoAgendamento`,
                        ]}
                        render={() => (
                          <>
                            {podeImprimirProntuario && <button className='button --light' onClick={() => abrirImprimirProntuario()}>Imprimir</button>}
                            {podeIniciarAtendimento &&
                              <SelecionadorDeTipoDeAtendimento
                                fecharFinalizarAtendimento={fecharFinalizarAtendimento}
                                identificadorDoAgendamento={identificadorDoAgendamento}
                                iniciarAtendimento={tipo => props.iniciarAtendimento(identificador, tipo)}
                                paciente={prontuario.cabecalho?.identificacao}
                                tiposDeAtendimento={prontuario.tiposDeAtendimento}
                              />
                            }
                          </>
                        )}
                      />
                      <RotaComAutenticacao
                        exact
                        path={`cadastros/pacientes/:identificador/prontuario/:identificadorDoAtendimento/confirmacao`}
                        render={() => (
                          <>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <Cabecalho
                  cabecalho={prontuario.cabecalho}
                  identificador={identificador}
                  recuperarFotoDoPacientePorIdentificador={props.recuperarFotoDoPacientePorIdentificador}
                  recuperarFotoOriginalDoPacientePorIdentificador={props.recuperarFotoOriginalDoPacientePorIdentificador}
                  removerImagemDaMemoriaPorIdentificador={props.removerImagemDaMemoriaPorIdentificador}
                  adicionarTagNoPacienteDoProntuario={adicionarTagNoPacienteDoProntuario}
                  removerTagDoPacienteDoProntuario={removerTagDoPacienteDoProntuario}
                />
              </div>
              <div className='corpo-fixo-prontuario'>
                <GestaoDeCondicoesClinicas
                  identificador={identificador}
                  medicamentos={medicamentos}
                  cabecalho={prontuario.cabecalho}
                  historicoDeCampos={prontuario.historicoDeCampos}
                  gestaoDeCondicoesClinicas={prontuario.gestaoDeCondicoesClinicas}
                  dados={prontuario.dados}
                />
                {atendimento &&
                  <Atendimento
                    abrirCancelarAtendimento={abrirCancelarAtendimento}
                    abrirFinalizarAtendimento={abrirFinalizarAtendimento}
                    abrirAssinaturaComCertificadoFisico={abrirAssinaturaComCertificadoFisico}
                    abrirAssinaturaComCertificadoEmNuvem={abrirAssinaturaComCertificadoEmNuvem}
                    recuperarCertificadosEmNuvemDoUsuario={recuperarCertificadosEmNuvemDoUsuario}
                    recuperarTeleconsultaDoAtendimento={recuperarTeleconsultaDoAtendimento}
                    atendimento={atendimentoDoProntuario}
                    historicoDeCampos={prontuario.historicoDeCampos}
                    recuperarVersaoDoTipoDeAtendimento={recuperarVersaoDoTipoDeAtendimento}
                    salvarSecaoDoAtendimento={salvarSecao}
                    recuperarPrescricaoPdfNaMemed={recuperarPrescricaoPdfNaMemed}
                    tiposDeAtendimento={prontuario.tiposDeAtendimento}
                    setStatusDaSecao={setStatusDaSecao}
                    statusDaSecao={statusDaSecao}
                    finalizarAtendimento={finalizar}
                    exibirFinalizarAtendimento={exibirFinalizarAtendimento}
                    fecharFinalizarAtendimento={fecharFinalizarAtendimento}
                    history={history}
                    tokensDeSugestao={tokensDeSugestao}
                    abrirAdicionarTemplate={abrirAdicionarTemplate}
                    reiniciarMemed={reiniciarMemed}
                    setReiniciarMemed={setReiniciarMemed}
                    paciente={prontuario.cabecalho.identificacao}
                    teleconsultaDoAtendimento={prontuario.teleconsulta}
                  />
                }
                <LinhaDoTempo
                  alterarTermoDaTimeline={alterarTermoDaTimeline}
                  atendimentosCarregados={prontuario.timeline.atendimentosCarregados}
                  atendimentosFinalizados={prontuario.timeline.historico}
                  itemSelecionado={prontuario.timeline.selecionado}
                  cancelarAtendimento={cancelarAtendimento}
                  identificador={identificador}
                  listarAtendimentosFinalizados={listarAtendimentosFinalizados}
                  selecionarItem={selecionarItemDaTimeline}
                  termo={prontuario.timeline.termo}
                  triagens={prontuario.gestaoDeCondicoesClinicas.triagens}
                  usuarioLogado={usuario}
                  recuperarCertificadosEmNuvemDoUsuario={recuperarCertificadosEmNuvemDoUsuario}
                  dados={prontuario.dados}
                />
              </div>
            </div>
          </div>
        </div>
      </ProvedorDeContextoDoProntuario>
    </MemedProvider>
  )
}

const ordenaTemplates = (templates, identificadorDoUsario) => {
  if (templates.length === 0) return []

  let templatesCriadosPorOutros = []
  let templatesCriadosPeloUsuario = []

  templates.forEach(x => {
    if (x.quemCriou.identificador === identificadorDoUsario) {
      const template = { ...x, nome: x.compartilhado ? x.nome + ' (Compartilhado)' : x.nome }
      templatesCriadosPeloUsuario.push(template)
    } else {
      templatesCriadosPorOutros.push(x)
    }
  })

  return [
    { grupo: 'Criados por mim', options: templatesCriadosPeloUsuario },
    { grupo: 'Compartilhados comigo', options: templatesCriadosPorOutros }
  ]
}

const extrairDadosDeSugestoes = (sugestao, identificadorDoUsuario) => ({
  profissionaisDeSaude: sugestao.profissionaisDeSaude,
  sugestoesDePaciente: sugestao.sugestoes,
  tokens: sugestao.tokens,
  templates: ordenaTemplates(sugestao.templates, identificadorDoUsuario)
})