import moment from 'moment'

const HOJE = moment()
const FORMATO_DATA_LOCAL = 'DD/MM/YYYY'
const FORMATO_HORA_LOCAL = 'HH:mm'
const FORMATO_DATA_UNIVERSAL = 'YYYY-MM-DD'
const FORMATO_DATA_E_HORA_LOCAL = 'DD/MM/YYYY HH:mm'
const FORMATO_DATA_E_HORA_UNIVERSAL = 'YYYY-MM-DD HH:mm'
const PRECISAO_ANO = 'ANO'
const PRECISAO_MES_ANO = 'MÊS/ANO'
const DIAS = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']

export const desabilitarDataFutura = data => {
  return data && data > HOJE.endOf('day')
}

export const formatarDataParaFormatoLocal = data => {
  if (!data) return null

  return moment(data).format(FORMATO_DATA_LOCAL)
}

export const formatarDataEHoraParaFormatoLocal = data => {
  if (!data) return null

  return moment(data).format(FORMATO_DATA_E_HORA_LOCAL)
}

export const formatarDataEHoraParaFormatoUniversal = data => {
  if (!data) return null

  return moment(data, FORMATO_DATA_E_HORA_LOCAL).format(FORMATO_DATA_E_HORA_UNIVERSAL)
}

export const formatarDataComPrecisaoParaFormatoLocal = (valor, precisao) => {
  if (!valor || !precisao) return null
  const data = moment(valor).toDate()

  if (precisao.toUpperCase() === PRECISAO_ANO) {
    return moment(data).format('YYYY')
  } else if (precisao.toUpperCase() === PRECISAO_MES_ANO) {
    return moment(data).format('MM/YY')
  }

  return formatarDataParaFormatoLocal(data)
}

export const formatarDataComPrecisaoParaFormatoUniversal = (data, precisao) => {
  if (!data || !precisao) return null

  if (precisao.toUpperCase() === PRECISAO_ANO) {
    return moment(data, FORMATO_DATA_LOCAL).startOf('year').format(FORMATO_DATA_UNIVERSAL)
  }

  if (precisao.toUpperCase() === PRECISAO_MES_ANO) {
    return moment(data, FORMATO_DATA_LOCAL).startOf('month').format(FORMATO_DATA_UNIVERSAL)
  }

  return moment(data, FORMATO_DATA_LOCAL).format(FORMATO_DATA_UNIVERSAL)
}

export const formatarDataParaFormatoUniversal = data => {
  if (!data) return null

  if (moment.isMoment(data)) {
    return data.format(FORMATO_DATA_UNIVERSAL)
  }

  if (moment(data, FORMATO_DATA_LOCAL).isValid()) {
    return moment(data, FORMATO_DATA_LOCAL).format(FORMATO_DATA_UNIVERSAL)
  }

  if (typeof data.getMonth === 'function') {
    return moment(data).format(FORMATO_DATA_UNIVERSAL)
  }

  if (typeof new Date(data).getMonth === 'function') {
    return moment.utc(new Date(data)).format(FORMATO_DATA_UNIVERSAL)
  }

  return null
}

export const formatarHoraParaFormatoLocal = data => {
  if (!data) return null

  return moment(data).format(FORMATO_HORA_LOCAL)
}

export const formatarDataParaFormatoDiaDaSemana = data => {
  if (!data) return null

  const str = formatarDataParaFormatoLocal(data)
  const partes = str.split('/').map(Number);
  const novaData = new Date(partes[2], partes[1] - 1, partes[0]);
  return DIAS[novaData.getDay() % 7];
}

export const diferencaEmMinutosEntreDatas = (inicio, fim) => {
  const ms = Math.abs(moment(inicio).diff(moment(fim)))
  const duration = moment.duration(ms)
  const tempo = Math.floor(duration.asHours()) + moment.utc(ms).format(':mm:ss')

  const tokens = tempo.split(':')
  let minutos = tokens[0] * 60
  minutos += + tokens[1]

  return minutos
}

export const diferencaEmDiasEntreDatas = (inicio, fim) => {
  const ms = Math.abs(moment(inicio).diff(moment(fim)))
  const duration = moment.duration(ms)
  const dias = Math.floor(duration.asDays())  

  return dias
}

export const diferencaEmMesesEntreDatas = (inicio, fim) => {
  const ms = Math.abs(moment(inicio).diff(moment(fim)))
  const duration = moment.duration(ms)
  const meses = Math.floor(duration.asMonths())

  return meses
}

export const dataIgualOuAnterior = (dataInicial, dataFinal) => {
  const inicio = formatarDataParaFormatoUniversal(dataInicial)
  const fim = formatarDataParaFormatoUniversal(dataFinal)

  if (!inicio || !fim) return null

  return moment(inicio).isSameOrBefore(moment(fim));
}

export const dataAnterior = (dataInicial, dataFinal) => {
  const inicio = formatarDataParaFormatoUniversal(dataInicial)
  const fim = formatarDataParaFormatoUniversal(dataFinal)

  if (!inicio || !fim) return null

  return moment(inicio).isBefore(moment(fim))
}

export const dataIgualOuPosterior = (dataInicial, dataFinal) => {
  const inicio = formatarDataParaFormatoUniversal(dataInicial)
  const fim = formatarDataParaFormatoUniversal(dataFinal)

  if (!inicio || !fim) return null

  return moment(inicio).isSameOrAfter(moment(fim));
}

export const dataPosterior = (dataInicial, dataFinal) => {
  const inicio = formatarDataParaFormatoUniversal(dataInicial)
  const fim = formatarDataParaFormatoUniversal(dataFinal)

  if (!inicio || !fim) return null

  return moment(inicio).isAfter(moment(fim))
}

export const formatarSegundosENanosegundosParaMoment = (time) => {
  if (!time) return null

  const data = new Date(time.seconds * 1000 + time.nanoseconds / 1000000)

  return moment(data)
}

export const formatarSegundosENanosegundosParaFormatoLocal = (time) => {
  if (!time) return null

  return formatarDataEHoraParaFormatoLocal(formatarSegundosENanosegundosParaMoment(time))
}

export const diferencaEntreDataEAtualNoFormatoSegundosENanosegundosReduzido = (time) => {
  if (!time) return null

  return formatarSegundosENanosegundosParaMoment(time).fromNow()
}

export const diferencaEntreDatasNoFormatoSegundosENanosegundosReduzido = (timeInicial, timeFinal) => {
  if (!timeInicial || !timeFinal) return null

  return formatarSegundosENanosegundosParaMoment(timeInicial).from(formatarSegundosENanosegundosParaMoment(timeFinal))
}

export const formatarDataParaTimestamp = data => {
  if (!data) return null

  return moment(data).valueOf()
}