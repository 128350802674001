import React, { useCallback, useEffect } from 'react'
import { Formulario as FormularioDoFormik, Input, MultiplaEscolha, MultiplaSelecao } from '../../../../formik/formulario'
import { contemValor } from '../../../../../bibliotecas/validacoes'
import Spinner from '../../../../spinner'
import { contemNaLista } from "../../../../../bibliotecas/texto";
import { useFormikContext } from "formik"

import {
    ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
    ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
    LISTAR_COGNICOES_DOS_PACIENTES,
    LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
    LISTAR_COMORBIDADES_CARDIOVASCULARES,
    LISTAR_COMORBIDADES_ENDOCRINAS,
    LISTAR_COMORBIDADES_GASTROINTESTINAIS,
    LISTAR_COMORBIDADES_NEUROLOGICAS,
    LISTAR_COMORBIDADES_ONCOLOGICAS,
    LISTAR_COMORBIDADES_OSTEOMUSCULARES,
    LISTAR_COMORBIDADES_PSIQUIATRICAS,
    LISTAR_COMORBIDADES_PULMONARES,
    LISTAR_COMORBIDADES_RENAIS,
    LISTAR_COMORBIDADES_REUMATOLOGICAS,
    LISTAR_CONDICOES_DOS_PACIENTES,
    LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
    LISTAR_ESCALAS_DE_FRAGILIDADE,
    LISTAR_ESTADOS_DE_DEAMBULACAO,
    LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
    LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
    LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
    LISTAR_VACINAS_EM_DIA, RECUPERAR_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE
} from '../../../../../acoes/tipos'

const obrigatorio = valor => {
  if (contemValor(valor)) {
    if (Array.isArray(valor) && valor.length === 0) {
      return 'Obrigatório'
    }

    return undefined
  }

  return 'Obrigatório'
}

const opcoesDesabilitadas = (valores, itens, valorParaComparacao) => {
  const opcoes = itens.map(x => x.codigo)

  if (valores.includes(valorParaComparacao)) {
    return opcoes.filter(x => x !== valorParaComparacao)
  } else if (valores.length > 0) {
    return opcoes.filter(x => x === valorParaComparacao)
  } else {
    return []
  }
}

const obterOpcoesDesabilitadas = (valores, itens, valorParaComparacao, todosOsItens) => {
    if(valores.includes(valorParaComparacao)) return opcoesDesabilitadas(valores, todosOsItens, valorParaComparacao)

    return valores.some(x => itens.includes(x)) ? itens.filter(x => !valores.includes(x)) : opcoesDesabilitadas(valores, todosOsItens, valorParaComparacao)
}

const renderizarTitulo = valor => valor.nome
const renderizarCfs = valor => `${valor.gravidade} - ${valor.nome}`
const renderizarDescricao = valor => valor.descricao

const renderizarDetalhesDaResposta = (valor, sugestao, itens, campo) => {
    if(typeof valor !== 'boolean' && !valor) return ''
    if(Array.isArray(valor) && valor.length === 0) return ''

    function RespostaUsuario({ sugestao, campo }) {
        const { setFieldValue } = useFormikContext()

        const handleClick = useCallback(() => {
            setFieldValue(campo, sugestao)
        }, [campo, sugestao, setFieldValue])

        return (
            <div className="box-alerta my-1">
                <div className="box-alerta__item">
                    <i className="icon icon-attention-circled"></i>
                </div>
                <p className="box-alerta__item">Resposta informada pelo usuário.</p>
                <button type='button' className="tarefas-button-warning yes" onMouseDown={handleClick}>Voltar</button>
            </div>
        )
    }

    if(!sugestao) return <RespostaUsuario sugestao={''} campo={campo} />

    function RespostaSistema({ regra }) {
        if(Array.isArray(regra)) {
            return (
                regra.map((x, index) => (
                    <React.Fragment key={index}>
                        <div className="box-info my-1">
                            <div className="box-info__item">
                                <i className="icon icon-ok-circled"></i>
                            </div>
                            <p className="box-info__item">{x}</p>
                        </div>
                    </React.Fragment>
                )
            ))
        }
    }

    if (Array.isArray(valor)) {
        const listaDeSugestoes = sugestao.respostas.map(x => x.resposta)
        const conferencia = valor.map(x => contemNaLista(listaDeSugestoes, x))
        if (valor.length === sugestao.respostas.length && conferencia.every(x => x === true)) {
            const regras = sugestao.respostas.map(x => `${itens.find(y => y.codigo === x.resposta).nome}: ${x.regras.join(" ")}`)
            return <RespostaSistema regra={regras} />
        } else {
            return <RespostaUsuario sugestao={listaDeSugestoes} campo={campo} />
        }
    }

    if (valor === sugestao.resposta) {
        return <RespostaSistema regra={sugestao.regras} />
    }

    return <RespostaUsuario sugestao={sugestao.resposta} campo={campo} />
}

export default function Formulario({selecionado, valores, sugestoes, fechar, ...rest}) {
    const salvar = async (valores, {resetForm}) => {
        const {
            paciente,
            usuario,
            ...formulario
        } = valores

        const salvou = await rest.salvar(formulario)

    if (salvou) {
      resetForm({})
      fechar()
    }
  }

  return (
    <Spinner operacoes={[
      ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
      ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
      LISTAR_VACINAS_EM_DIA,
      LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
      LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
      LISTAR_ESTADOS_DE_DEAMBULACAO,
      LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
      LISTAR_CONDICOES_DOS_PACIENTES,
      LISTAR_COMORBIDADES_REUMATOLOGICAS,
      LISTAR_COMORBIDADES_RENAIS,
      LISTAR_COMORBIDADES_PULMONARES,
      LISTAR_COMORBIDADES_PSIQUIATRICAS,
      LISTAR_COMORBIDADES_OSTEOMUSCULARES,
      LISTAR_COMORBIDADES_ONCOLOGICAS,
      LISTAR_COMORBIDADES_NEUROLOGICAS,
      LISTAR_COMORBIDADES_GASTROINTESTINAIS,
      LISTAR_COMORBIDADES_ENDOCRINAS,
      LISTAR_COMORBIDADES_CARDIOVASCULARES,
      LISTAR_COGNICOES_DOS_PACIENTES,
      LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
      LISTAR_ESCALAS_DE_FRAGILIDADE,
      LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
      RECUPERAR_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE
    ]}>
      <FormularioDoFormik
        reinicializar={false}
        acao={salvar}
        valoresIniciais={valores}
      >
        {({ values, setFieldValue }) => (
          <fieldset>
            <h2 className="form-title">{selecionado ? 'Alterar Questionário do Idoso' : 'Adicionar Questionário do Idoso'}</h2>
              <Alerta />
            <r-grid columns-md={6} columns-lg={12} class="componente-fisioterapia">
              <Cabecalho/>
              <EscalaDeFragilidade escalaDeFragilidade={values.escalaDeFragilidade.resposta} escalasDeFragilidade={rest.escalasDeFragilidade} sugestao={sugestoes.escalaDeFragilidade} setFieldValue={setFieldValue}/>
              <PacienteMoraComQuem moraComQuem={values.moraComQuem.resposta} comQuemOsPacientesMoram={rest.comQuemOsPacientesMoram} sugestao={sugestoes.moraComQuem} setFieldValue={setFieldValue}/>
              <MedicamentosEmUso medicamentosEmUso={values.medicamentosEmUso.resposta} usosDeMedicamentosPelosPacientes={rest.usosDeMedicamentosPelosPacientes} sugestao={sugestoes.medicamentosEmUso} setFieldValue={setFieldValue}/>
              <VacinasEmDia vacinasEmDia={values.vacinasEmDia.respostas} vacinasEmDiaDosPacientes={rest.vacinasEmDia} sugestao={sugestoes.vacinasEmDia} setFieldValue={setFieldValue}/>
              <Cognicao cognicao={values.cognicao.resposta} cognicoesDosPacientes={rest.cognicoesDosPacientes} sugestao={sugestoes.cognicao} setFieldValue={setFieldValue}/>
              <Condicoes condicoes={values.condicoes.respostas} condicoesDosPacientes={rest.condicoesDosPacientes} sugestao={sugestoes.condicoes} setFieldValue={setFieldValue}/>
              <Deambulacao deambulacao={values.deambulacao.resposta} estadosDeDeambulacao={rest.estadosDeDeambulacao} sugestao={sugestoes.deambulacao} setFieldValue={setFieldValue}/>
              <QuedasNosUltimosDozeMeses quedasNosUltimosDozeMeses={values.quedasNosUltimosDozeMeses.resposta} quedasNosUltimosDozeMesesDosPacientes={rest.quedasNosUltimosDozeMeses} sugestao={sugestoes.quedasNosUltimosDozeMeses} setFieldValue={setFieldValue}/>
              <DispositivosEmUso dispositivosEmUso={values.dispositivosEmUso.respostas} dispositivosEmUsoDosPacientes={rest.dispositivosEmUsoDosPacientes} sugestao={sugestoes.dispositivosEmUso} setFieldValue={setFieldValue}/>
              <EstadoTerminal estadoTerminal={values.estadoTerminal.resposta} sugestao={sugestoes.estadoTerminal} />
              <NecessidadeDeControleDeSintomas necessidadeDeControleDeSintomas={values.necessidadeDeControleDeSintomas.resposta} sugestao={sugestoes.necessidadeDeControleDeSintomas} estadoTerminal={values.estadoTerminal} />
              <PossuiComorbidades/>
              <ComorbidadesCardiovasculares comorbidadesCardiovasculares={values.comorbidadesCardiovasculares.respostas} comorbidadesCardiovascularesDosPacientes={rest.comorbidadesCardiovasculares} sugestao={sugestoes.comorbidadesCardiovasculares} setFieldValue={setFieldValue}/>
              <ComorbidadesEndocrinas comorbidadesEndocrinas={values.comorbidadesEndocrinas.respostas} comorbidadesEndocrinasDosPacientes={rest.comorbidadesEndocrinas} sugestao={sugestoes.comorbidadesEndocrinas} setFieldValue={setFieldValue}/>
              <ComorbidadesGastrointestinais comorbidadesGastrointestinais={values.comorbidadesGastrointestinais.respostas} comorbidadesGastrointestinaisDosPacientes={rest.comorbidadesGastrointestinais} sugestao={sugestoes.comorbidadesGastrointestinais} setFieldValue={setFieldValue}/>
              <ComorbidadesNeurologicas comorbidadesNeurologicas={values.comorbidadesNeurologicas.respostas} comorbidadesNeurologicasDosPacientes={rest.comorbidadesNeurologicas} sugestao={sugestoes.comorbidadesNeurologicas} setFieldValue={setFieldValue}/>
              <ComorbidadesOncologicas comorbidadesOncologicas={values.comorbidadesOncologicas.respostas} comorbidadesOncologicasDosPacientes={rest.comorbidadesOncologicas} sugestao={sugestoes.comorbidadesOncologicas} setFieldValue={setFieldValue}/>
              <ComorbidadesOsteomusculares comorbidadesOsteomusculares={values.comorbidadesOsteomusculares.respostas} comorbidadesOsteomuscularesDosPacientes={rest.comorbidadesOsteomusculares} sugestao={sugestoes.comorbidadesOsteomusculares} setFieldValue={setFieldValue}/>
              <ComorbidadesPsiquiatricas comorbidadesPsiquiatricas={values.comorbidadesPsiquiatricas.respostas} comorbidadesPsiquiatricasDosPacientes={rest.comorbidadesPsiquiatricas} sugestao={sugestoes.comorbidadesPsiquiatricas} setFieldValue={setFieldValue}/>
              <ComorbidadesPulmonares comorbidadesPulmonares={values.comorbidadesPulmonares.respostas} comorbidadesPulmonaresDosPacientes={rest.comorbidadesPulmonares} sugestao={sugestoes.comorbidadesPulmonares} setFieldValue={setFieldValue}/>
              <ComorbidadesRenais comorbidadesRenais={values.comorbidadesRenais.respostas} comorbidadesRenaisDosPacientes={rest.comorbidadesRenais} sugestao={sugestoes.comorbidadesRenais} setFieldValue={setFieldValue}/>
              <ComorbidadesReumatologicas comorbidadesReumatologicas={values.comorbidadesReumatologicas.respostas} comorbidadesReumatologicasDosPacientes={rest.comorbidadesReumatologicas} sugestao={sugestoes.comorbidadesReumatologicas} setFieldValue={setFieldValue}/>
            </r-grid>
              <Observacoes />
            <div className="list-btn">
              <button type="button" className="button --light" onClick={() => fechar()}>Fechar</button>
              <button type="submit" className="button --primary">{selecionado ? 'Salvar Alterações' : 'Adicionar'}</button>
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}

function Alerta() {
    return (
        <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
                <div className='form-choice-alerta'>
                    <div className='form-choice-alerta__item'>
                        <i className='icon icon-attention-circled'></i>
                    </div>
                    <div className='form-choice-alerta__item'>
                       É importante revisar TODAS as informações para assegurar que estejam completas e corretas, evitando possíveis erros.
                    </div>
                </div>
            </r-cell>
        </r-grid>
    )
}

function Cabecalho() {
    return (
        <r-cell span={4} span-md={6} span-lg={12} class="cabecalho">
            <div>
                <strong>QUESTIONÁRIO DO IDOSO</strong>
            </div>
        </r-cell>
    )
}

function EscalaDeFragilidade({escalasDeFragilidade, escalaDeFragilidade, sugestao, setFieldValue}) {
    useEffect(() => {
        if (!escalaDeFragilidade) {
            setFieldValue('escalaDeFragilidade.resposta', null)
        }
    }, [setFieldValue, escalaDeFragilidade])

    return (
        <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
            <MultiplaEscolha
                className="pergunta"
                nome="escalaDeFragilidade.resposta"
                opcoes={escalasDeFragilidade}
                pergunta="Qual é o Clinical Frailty Scale (CFS) do paciente? *"
                renderizarTitulo={renderizarCfs}
                renderizarDescricao={renderizarDescricao}
                validar={obrigatorio}
                detalhesDaResposta={renderizarDetalhesDaResposta(escalaDeFragilidade, sugestao, [], 'escalaDeFragilidade.resposta')}
      />
    </r-cell>
  )
}

function PacienteMoraComQuem({ comQuemOsPacientesMoram, moraComQuem, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!moraComQuem) {
      setFieldValue('moraComQuem.resposta', null)
    }
  }, [setFieldValue, moraComQuem])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="moraComQuem.resposta"
        opcoes={comQuemOsPacientesMoram}
        pergunta="Paciente mora com quem? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(moraComQuem, sugestao, [], 'moraComQuem.resposta')}
      />
    </r-cell>
  )
}

function MedicamentosEmUso({ medicamentosEmUso, usosDeMedicamentosPelosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!medicamentosEmUso) {
      setFieldValue('medicamentosEmUso.resposta', null)
    }
  }, [setFieldValue, medicamentosEmUso])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="medicamentosEmUso.resposta"
        opcoes={usosDeMedicamentosPelosPacientes}
        pergunta="Quantidade de medicamentos utilizados? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(medicamentosEmUso, sugestao, [], 'medicamentosEmUso.resposta')}
      />
    </r-cell>
  )
}

function VacinasEmDia({ vacinasEmDia, vacinasEmDiaDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!vacinasEmDia) {
      setFieldValue('vacinasEmDia.respostas', [])
    }
  }, [setFieldValue, vacinasEmDia])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="vacinasEmDia.respostas"
        opcoes={vacinasEmDiaDosPacientes}
        pergunta="Marque as vacinas do paciente que estão em dia: *"
        renderizarTitulo={renderizarTitulo}
        valores={vacinasEmDia}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(vacinasEmDia, vacinasEmDiaDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(vacinasEmDia, sugestao, vacinasEmDiaDosPacientes, 'vacinasEmDia.respostas')}
      />
    </r-cell>
  )
}

function Cognicao({ cognicao, cognicoesDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!cognicao) {
      setFieldValue('cognicao.resposta', null)
    }
  }, [setFieldValue, cognicao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="cognicao.resposta"
        opcoes={cognicoesDosPacientes}
        pergunta="Cognição? *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(cognicao, sugestao, [], 'cognicao.resposta')}
      />
    </r-cell>
  )
}

function Condicoes({ condicoes, condicoesDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!condicoes) {
      setFieldValue('condicoes.respostas', [])
    }
  }, [setFieldValue, condicoes])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="condicoes.respostas"
        opcoes={condicoesDosPacientes}
        pergunta="Paciente apresenta alguma dessas condições? *"
        renderizarTitulo={renderizarTitulo}
        valores={condicoes}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(condicoes, condicoesDosPacientes, 'nenhuma_condicao')}
        detalhesDaResposta={renderizarDetalhesDaResposta(condicoes, sugestao, condicoesDosPacientes, 'condicoes.respostas')}
      />
    </r-cell>
  )
}

function Deambulacao({ deambulacao, estadosDeDeambulacao, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!deambulacao) {
      setFieldValue('deambulacao.resposta', null)
    }
  }, [setFieldValue, deambulacao])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="deambulacao.resposta"
        opcoes={estadosDeDeambulacao}
        pergunta="Sobre deambulação (indicar a situação predominante) *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(deambulacao, sugestao, [], 'deambulacao.resposta')}
      />
    </r-cell>
  )
}

function QuedasNosUltimosDozeMeses({ quedasNosUltimosDozeMeses, quedasNosUltimosDozeMesesDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!quedasNosUltimosDozeMeses) {
      setFieldValue('quedasNosUltimosDozeMeses.resposta', null)
    }
  }, [setFieldValue, quedasNosUltimosDozeMeses])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="quedasNosUltimosDozeMeses.resposta"
        opcoes={quedasNosUltimosDozeMesesDosPacientes}
        pergunta="Queda nos últimos 12 meses: *"
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(quedasNosUltimosDozeMeses, sugestao, [], 'quedasNosUltimosDozeMeses.resposta')}
      />
    </r-cell>
  )
}

function DispositivosEmUso({ dispositivosEmUso, dispositivosEmUsoDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!dispositivosEmUso) {
      setFieldValue('dispositivosEmUso.respostas', [])
    }
  }, [setFieldValue, dispositivosEmUso])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="dispositivosEmUso.respostas"
        opcoes={dispositivosEmUsoDosPacientes}
        pergunta="Paciente faz uso de algum dispositivo? *"
        renderizarTitulo={renderizarTitulo}
        renderizarDescricao={renderizarDescricao}
        valores={dispositivosEmUso}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(dispositivosEmUso, dispositivosEmUsoDosPacientes, 'nao_usa_dispositivos')}
        detalhesDaResposta={renderizarDetalhesDaResposta(dispositivosEmUso, sugestao, dispositivosEmUsoDosPacientes, 'dispositivosEmUso.respostas')}
      />
    </r-cell>
  )
}

function EstadoTerminal({ estadoTerminal, sugestao }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="estadoTerminal.resposta"
        opcoes={[{ codigo: true, nome: 'Sim' }, { codigo: false, nome: 'Não' }]}
        pergunta="Paciente está em estado terminal (Sobrevida estimada menor que 12 meses)? *"
        informacaoAdicional="Sugestão de ferramenta: SPICT-BR (Supportive and Palliative Care Indicators Tool) "
        renderizarTitulo={renderizarTitulo}
        validar={obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(estadoTerminal, sugestao, [], 'estadoTerminal.resposta')}
      />
    </r-cell>
  )
}

function NecessidadeDeControleDeSintomas({ necessidadeDeControleDeSintomas, estadoTerminal, sugestao }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="necessidadeDeControleDeSintomas.resposta"
        opcoes={[{ codigo: true, nome: 'Sim' }, { codigo: false, nome: 'Não' }]}
        pergunta={`Tem necessidade de controle de sintomas no momento? ${estadoTerminal ? "*" : ""}`}
        renderizarTitulo={renderizarTitulo}
        validar={estadoTerminal && obrigatorio}
        detalhesDaResposta={renderizarDetalhesDaResposta(necessidadeDeControleDeSintomas, sugestao, [], 'necessidadeDeControleDeSintomas.resposta')}
      />
    </r-cell>
  )
}

function PossuiComorbidades() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <p>Assinale a(s) comorbidade(s) do paciente:</p>
    </r-cell>
  )
}

function ComorbidadesCardiovasculares({ comorbidadesCardiovasculares, comorbidadesCardiovascularesDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesCardiovasculares) {
      setFieldValue('comorbidadesCardiovasculares.respostas', [])
    }
  }, [setFieldValue, comorbidadesCardiovasculares])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesCardiovasculares.respostas"
        opcoes={comorbidadesCardiovascularesDosPacientes}
        pergunta="Cardiovascular"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesCardiovasculares}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesCardiovasculares, comorbidadesCardiovascularesDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesCardiovasculares, sugestao, comorbidadesCardiovascularesDosPacientes, 'comorbidadesCardiovasculares.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesPulmonares({ comorbidadesPulmonares, comorbidadesPulmonaresDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesPulmonares) {
      setFieldValue('comorbidadesPulmonares.respostas', [])
    }
  }, [setFieldValue, comorbidadesPulmonares])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesPulmonares.respostas"
        opcoes={comorbidadesPulmonaresDosPacientes}
        pergunta="Pulmonar"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesPulmonares}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesPulmonares, comorbidadesPulmonaresDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesPulmonares, sugestao, comorbidadesPulmonaresDosPacientes, 'comorbidadesPulmonares.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesNeurologicas({ comorbidadesNeurologicas, comorbidadesNeurologicasDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesNeurologicas) {
      setFieldValue('comorbidadesNeurologicas.respostas', [])
    }
  }, [setFieldValue, comorbidadesNeurologicas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesNeurologicas.respostas"
        opcoes={comorbidadesNeurologicasDosPacientes}
        pergunta="Neurológica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesNeurologicas}
        validar={obrigatorio}
        opcoesDesabilitadas={obterOpcoesDesabilitadas(comorbidadesNeurologicas, ['demencia_leve', 'demencia_moderada', 'demencia_avancada', 'nenhuma'], 'nenhuma', comorbidadesNeurologicasDosPacientes)}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesNeurologicas, sugestao, comorbidadesNeurologicasDosPacientes, 'comorbidadesNeurologicas.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesPsiquiatricas({ comorbidadesPsiquiatricas, comorbidadesPsiquiatricasDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesPsiquiatricas) {
      setFieldValue('comorbidadesPsiquiatricas.respostas', [])
    }
  }, [setFieldValue, comorbidadesPsiquiatricas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesPsiquiatricas.respostas"
        opcoes={comorbidadesPsiquiatricasDosPacientes}
        pergunta="Psiquiátrica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesPsiquiatricas}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesPsiquiatricas, comorbidadesPsiquiatricasDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesPsiquiatricas, sugestao, comorbidadesPsiquiatricasDosPacientes, 'comorbidadesPsiquiatricas.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesRenais({ comorbidadesRenais, comorbidadesRenaisDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesRenais) {
      setFieldValue('comorbidadesRenais.respostas', [])
    }
  }, [setFieldValue, comorbidadesRenais])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesRenais.respostas"
        opcoes={comorbidadesRenaisDosPacientes}
        pergunta="Renal"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesRenais}
        validar={obrigatorio}
        opcoesDesabilitadas={obterOpcoesDesabilitadas(comorbidadesRenais, ['drc_dialitica', 'drc_nao_dialitica', 'nenhuma'], 'nenhuma', comorbidadesRenaisDosPacientes)}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesRenais, sugestao, comorbidadesRenaisDosPacientes, 'comorbidadesRenais.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesEndocrinas({ comorbidadesEndocrinas, comorbidadesEndocrinasDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesEndocrinas) {
      setFieldValue('comorbidadesEndocrinas.respostas', [])
    }
  }, [setFieldValue, comorbidadesEndocrinas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesEndocrinas.respostas"
        opcoes={comorbidadesEndocrinasDosPacientes}
        pergunta="Endócrina"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesEndocrinas}
        validar={obrigatorio}
        opcoesDesabilitadas={obterOpcoesDesabilitadas(comorbidadesEndocrinas, ['diabetes_insulino_requerente', 'diabetes_nao_insulino_requerente', 'nenhuma'], 'nenhuma', comorbidadesEndocrinasDosPacientes)}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesEndocrinas, sugestao, comorbidadesEndocrinasDosPacientes, 'comorbidadesEndocrinas.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesReumatologicas({ comorbidadesReumatologicas, comorbidadesReumatologicasDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesReumatologicas) {
      setFieldValue('comorbidadesReumatologicas.respostas', [])
    }
  }, [setFieldValue, comorbidadesReumatologicas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesReumatologicas.respostas"
        opcoes={comorbidadesReumatologicasDosPacientes}
        pergunta="Reumatológica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesReumatologicas}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesReumatologicas, comorbidadesReumatologicasDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesReumatologicas, sugestao, comorbidadesReumatologicasDosPacientes, 'comorbidadesReumatologicas.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesGastrointestinais({ comorbidadesGastrointestinais, comorbidadesGastrointestinaisDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesGastrointestinais) {
      setFieldValue('comorbidadesGastrointestinais.respostas', [])
    }
  }, [setFieldValue, comorbidadesGastrointestinais])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesGastrointestinais.respostas"
        opcoes={comorbidadesGastrointestinaisDosPacientes}
        pergunta="Gastrointestinal"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesGastrointestinais}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesGastrointestinais, comorbidadesGastrointestinaisDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesGastrointestinais, sugestao, comorbidadesGastrointestinaisDosPacientes, 'comorbidadesGastrointestinais.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesOncologicas({ comorbidadesOncologicas, comorbidadesOncologicasDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesOncologicas) {
      setFieldValue('comorbidadesOncologicas.respostas', [])
    }
  }, [setFieldValue, comorbidadesOncologicas])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesOncologicas.respostas"
        opcoes={comorbidadesOncologicasDosPacientes}
        pergunta="Oncológica"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesOncologicas}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesOncologicas, comorbidadesOncologicasDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesOncologicas, sugestao, comorbidadesOncologicasDosPacientes, 'comorbidadesOncologicas.respostas')}
      />
    </r-cell>
  )
}

function ComorbidadesOsteomusculares({ comorbidadesOsteomusculares, comorbidadesOsteomuscularesDosPacientes, sugestao, setFieldValue }) {
  useEffect(() => {
    if (!comorbidadesOsteomusculares) {
      setFieldValue('comorbidadesOsteomusculares.respostas', [])
    }
  }, [setFieldValue, comorbidadesOsteomusculares])

  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesOsteomusculares.respostas"
        opcoes={comorbidadesOsteomuscularesDosPacientes}
        pergunta="Osteomuscular"
        renderizarTitulo={renderizarTitulo}
        valores={comorbidadesOsteomusculares}
        validar={obrigatorio}
        opcoesDesabilitadas={opcoesDesabilitadas(comorbidadesOsteomusculares, comorbidadesOsteomuscularesDosPacientes, 'nenhuma')}
        detalhesDaResposta={renderizarDetalhesDaResposta(comorbidadesOsteomusculares, sugestao, comorbidadesOsteomuscularesDosPacientes, 'comorbidadesOsteomusculares.respostas')}
      />
    </r-cell>
  )
}

function Observacoes() {
    return (
        <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                    classname="componente-zarit paciente-leitura my-1"
                    placeholder={"Justifique as respostas informadas pelo usuário."}
                    as='textarea'
                    nome='observacoes'
                    titulo='Observações'
                />
            </r-cell>
        </r-grid>
    )
}