import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Modal from 'react-modal'
import Spinner from '../spinner'
import { Cep, Checkbox, Cpf, DatePicker, Formulario as Form, Input, InputFoto, MonthPicker, Select, Telefone, Toglle } from '../formik/formulario'
import { FieldArray } from 'formik'
import { formatarCep, formatarCpf } from '../../bibliotecas/formatacao'
import { ehUmCepValido } from '../../bibliotecas/validacoes'
import { dataAnterior, formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { v4 as uuidv4 } from 'uuid'
import IndicadoresDoPaciente from '../indicadores-do-paciente'
import ProximosAgendamentos from '../agendamentos/proximos-agendamentos'
import { CalendarCheck, WhatsApp } from '../../design/icones'

import {
  ADICIONAR_FOTO_DO_PACIENTE,
  ADICIONAR_PACIENTE,
  LISTAR_ASSOCIACOES,
  LISTAR_CELULAS,
  LISTAR_CONSULTORES_DO_PACIENTE,
  LISTAR_ENFERMEIROS_DO_PACIENTE,
  LISTAR_ESTADOS_CIVIS,
  LISTAR_GRAUS_DE_RELACOES,
  LISTAR_MEDICOS_DO_PACIENTE,
  LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
  LISTAR_PROPRIETARIOS_DE_TELEFONES,
  LISTAR_PSICOLOGOS_DO_PACIENTE,
  LISTAR_SEXOS,
  LISTAR_UNIDADES_FEDERATIVAS,
  RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
  RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  SALVAR_PACIENTE,
} from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    adicionarFotoDoPaciente,
    associacoes,
    celulas,
    consultores,
    enfermeiros,
    estadosCivis,
    grausDeRelacoes,
    listarAssociacoes,
    listarCelulas,
    listarConsultoresDoPaciente,
    listarEnfermeirosDoPaciente,
    listarEstadosCivis,
    listarGrausDeRelacoes,
    listarMedicosDoPaciente,
    listarMotivosDeInativacaoDoPaciente,
    listarProprietariosDeTelefones,
    listarPsicologosDoPaciente,
    listarSexos,
    listarUnidadesFederativas,
    match,
    medicos,
    motivosDeInativacao,
    paciente = {},
    proprietariosDeTelefones,
    psicologos,
    recuperarPorIdentificador,
    recuperarEnderecoDoPacientePorCep,
    recuperarFotoOriginalDoPacientePorIdentificador,
    removerFotoDoPaciente,
    reinicializar,
    salvar,
    sexos,
    unidades,
    unidadesFederativas
  } = props

  const DATA_INICIAL_DO_CPF_OBRIGATORIO = '2024-10-31'

  const { identificador } = match.params
  const [cidades, setCidades] = useState([])
  const [cep, setCep] = useState('')
  const [unidadeFederativaSelecionada, setUnidadeFederativaSelecionada] = useState(null)
  const [exibirDadosInformacaoPaciente, setExibirDadosInformacaoPaciente] = useState(true)
  const [exibirAlertaDeInativacao, setExibirAlertaDeInativacao] = useState(false)
  const [formularioDoPaciente, setFormularioDoPaciente] = useState({})
  const habilitarFuncoes = usePossuiAsPermissoes([permissoes.ALTERAR_PACIENTE])
  const periodos = ['Dia', 'Mês/Ano']
  const exibirBotaoEditar = identificador
  const pacienteAtivo = paciente && !paciente.ativo

  const permissaoDeIniciarAtendimento = usePossuiAsPermissoes([permissoes.INICIAR_ATENDIMENTO])
  const permissaoDeListarAtendimentos = usePossuiAsPermissoes([permissoes.LISTAR_ATENDIMENTOS_DO_PACIENTE])
  const permissaoDeInativarPaciente = usePossuiAsPermissoes([permissoes.ALTERAR_DADOS_DE_INATIVACAO_DO_PACIENTE])
  const exibirBotaoProntuario = permissaoDeIniciarAtendimento || permissaoDeListarAtendimentos

  useEffect(() => { listarGrausDeRelacoes() }, [listarGrausDeRelacoes])
  useEffect(() => { listarMedicosDoPaciente() }, [listarMedicosDoPaciente])
  useEffect(() => { listarEnfermeirosDoPaciente() }, [listarEnfermeirosDoPaciente])
  useEffect(() => { listarSexos() }, [listarSexos])
  useEffect(() => { listarUnidadesFederativas() }, [listarUnidadesFederativas])
  useEffect(() => { listarAssociacoes() }, [listarAssociacoes])
  useEffect(() => { listarEstadosCivis() }, [listarEstadosCivis])
  useEffect(() => { listarProprietariosDeTelefones() }, [listarProprietariosDeTelefones])
  useEffect(() => { listarPsicologosDoPaciente() }, [listarPsicologosDoPaciente])
  useEffect(() => { listarCelulas() }, [listarCelulas])
  useEffect(() => { listarMotivosDeInativacaoDoPaciente() }, [listarMotivosDeInativacaoDoPaciente])

  useEffect(() => {
    if (!consultores || consultores.length === 0) {
      listarConsultoresDoPaciente({ ativo: ['Ativo'] }, 1, { nome: 'nome', ordem: '+' }, 2000)
    }
  }, [listarConsultoresDoPaciente, consultores])

  const operacoesDoSpinner = [
    RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
    SALVAR_PACIENTE,
    ADICIONAR_PACIENTE,
    RECUPERAR_ENDERECO_DO_PACIENTE_POR_CEP,
    LISTAR_CELULAS,
    LISTAR_GRAUS_DE_RELACOES,
    LISTAR_MEDICOS_DO_PACIENTE,
    LISTAR_ENFERMEIROS_DO_PACIENTE,
    LISTAR_CONSULTORES_DO_PACIENTE,
    LISTAR_SEXOS,
    LISTAR_UNIDADES_FEDERATIVAS,
    LISTAR_ASSOCIACOES,
    LISTAR_ESTADOS_CIVIS,
    LISTAR_PROPRIETARIOS_DE_TELEFONES,
    LISTAR_PSICOLOGOS_DO_PACIENTE,
    LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE
  ]

  useEffect(() => {
    if (identificador) {
      recuperarPorIdentificador(identificador)
      setExibirDadosInformacaoPaciente(false)
    }
  }, [identificador, recuperarPorIdentificador])

  useEffect(() => {
    if (unidadeFederativaSelecionada) {
      const encontrada = unidadesFederativas.find(x => x.sigla === unidadeFederativaSelecionada)
      if (encontrada) {
        setCidades(encontrada.cidades)
      } else {
        setCidades([])
      }
    } else {
      setCidades([])
    }
  }, [unidadeFederativaSelecionada, unidadesFederativas])

  useEffect(() => {
    if (paciente.endereco) {
      setUnidadeFederativaSelecionada(paciente.endereco.unidadeFederativa)

      if (paciente.endereco.cep && paciente.endereco.unidadeFederativa) {
        setCep(formatarCep(paciente.endereco.cep))
      } else {
        setCep(paciente.endereco.cep)
      }
    }
  }, [paciente.endereco])

  const retornarConsultores = (consultores, consultor) => {
    let listaDeConsultores = []

    if (consultor && !consultores.find(x => x.identificador === consultor.identificador)) {
      listaDeConsultores = [...consultores, consultor]
    } else {
      listaDeConsultores = consultores
    }

    return listaDeConsultores
  }

  const aoSairDoCep = async (cepAtualizado, setFieldValue) => {
    if (cepAtualizado === cep) return

    let endereco = {}

    if (cepAtualizado && ehUmCepValido(cepAtualizado)) {
      endereco = await recuperarEnderecoDoPacientePorCep(cepAtualizado)
    }

    if (endereco) {
      setFieldValue('logradouro', endereco.logradouro || '')
      setFieldValue('bairro', endereco.bairro || '')
      setFieldValue('uf', endereco.uf || '')
      setFieldValue('cidade', endereco.localidade || '')
      setFieldValue('numero', '')
      setFieldValue('complemento', '')
      setUnidadeFederativaSelecionada(endereco.uf || '')
    }

    setCep(cepAtualizado)
  }

  const aoInformarStatusDoPaciente = (status, setFieldValue) => {
    if (status) {
      setFieldValue('motivoDaInativacao', null)
      setFieldValue('precisaoDaDataDoObito', null)
      setFieldValue('dataDoObito', null)
      setFieldValue('comoOObitoFoiInformado', '')
    }
  }

  const aoInformarMotivoDeInativacao = (motivo, setFieldValue) => {
    if (motivo) {
      setFieldValue('precisaoDaDataDoObito', null)
      setFieldValue('dataDoObito', null)
      setFieldValue('comoOObitoFoiInformado', '')
    }
  }

  const ordenarTelefones = (a, b) => {
    return a.ordem.localeCompare(b.ordem)
  }

  const salvarPaciente = formulario => {
    if (pacienteAtivo && formulario.obito) {
      setFormularioDoPaciente(formulario)
      setExibirAlertaDeInativacao(true)
      return
    }

    salvar(formulario)
  }

  const renderizarAlerta = mensagens => {
    return (
      <>
        {mensagens.map((x, index) => x.tipo === 'Alerta' ?
          <div className='form-choice-alerta' key={index}>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
            </div>
          </div>
          :
          <div className='form-choice-erro' key={index}>
            <div className='form-choice-erro__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-erro__item'>
              <div dangerouslySetInnerHTML={{ __html: x.texto }} />
            </div>
          </div>
        )}
        <hr className='separator mt-1 mb-1'></hr>
      </>
    )
  }

  const renderizarTelefones = (index, values, remove, setFieldValue) => {
    return (
      <React.Fragment key={index}>
        <r-cell span={4} span-md={6} span-lg='1-2'>
          <Select
            nome={`telefones.${index}.proprietario`}
            tabIndex={8}
            titulo='Proprietário do telefone *'
            itens={proprietariosDeTelefones}
            campoCodigo='nome'
            campoDescricao='nome'
            onChange={() =>
              setFieldValue(`telefones.${index}.nome`, '') &&
              setFieldValue(`telefones.${index}.grauDeRelacao`, '') &&
              setFieldValue(`telefones.${index}.numero`, '')
            }
            isDisabled={!habilitarFuncoes}
          />
        </r-cell>
        <r-cell span={4} span-md={6} span-lg={3}>
          <Input
            type='text'
            nome={`telefones.${index}.nome`}
            tabIndex={9}
            titulo={values.telefones[index].proprietario === 'Paciente' ? 'Nome' : 'Nome *'}
            disabled={values.telefones[index].proprietario === 'Paciente' || !habilitarFuncoes}
          />
        </r-cell>
        <r-cell span={4} span-md={5} span-lg={2}>
          <Select
            nome={`telefones.${index}.grauDeRelacao`}
            tabIndex={10}
            titulo={values.telefones[index].proprietario === 'Paciente' ? 'Grau de Relação' : 'Grau de Relação *'}
            itens={grausDeRelacoes}
            campoCodigo='nome'
            campoDescricao='nome'
            isDisabled={values.telefones[index].proprietario === 'Paciente' || !habilitarFuncoes}
          />
        </r-cell>
        <r-cell span={4} span-md={habilitarFuncoes ? (values.telefones.length === 1 ? '5' : '4') : '5'} span-lg={habilitarFuncoes ? (values.telefones.length === 1 ? '3' : '2') : '3'}>
          <Telefone
            type='text'
            nome={`telefones.${index}.numero`}
            tabIndex={11}
            titulo='Telefone *'
            disabled={!habilitarFuncoes}
          />
        </r-cell>
        <r-cell span={1} span-md={1} span-lg={1} title='Telefone é WhatsApp'>
          <Checkbox
            nome={`telefones.${index}.whatsApp`}
            tabIndex={12}
            titulo={<WhatsApp size={24} className='mt-5n' />}
            classname='mt-35'
            title='Telefone é WhatsApp'
          />
        </r-cell>
        <r-cell span={1} span-md={1} span-lg={1} title='Telefone de Confirmação de Consulta'>
          <Checkbox
            nome={`telefones.${index}.confirmacaoDeConsulta`}
            tabIndex={13}
            titulo={<CalendarCheck size={24} className='mt-5n' />}
            classname='mt-35'
          />
        </r-cell>
        {values.telefones.length > 1 && habilitarFuncoes &&
          <r-cell span={2} span-md='1' span-lg='1' class='d-flex justify-content-end'>
            <button
              type='button'
              onClick={() => remove(index)}
              className='excluir-telefone'
            />
          </r-cell>
        }
      </React.Fragment>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirAlertaDeInativacao}
        contentLabel='Modal para alerta de óbito'
        className='modal-vacinas'
      >
        <AlertaDeInativacao
          nomeDoPaciente={paciente.nome}
          salvar={() => salvar(formularioDoPaciente)}
          fechar={() => setExibirAlertaDeInativacao(false)}
        />
      </Modal>
      <Spinner operacoes={[RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR, ADICIONAR_FOTO_DO_PACIENTE]}>
        {({ processando }) => (
          <Spinner operacoes={operacoesDoSpinner}>
            <Form
              reinicializar={reinicializar}
              valoresIniciais={{
                identificador: paciente.identificador || '',
                nome: paciente.nome || '',
                sexo: paciente.sexo || '',
                dataDeNascimento: paciente.dataDeNascimento ? paciente.dataDeNascimento : '',
                cpf: paciente.cpf ? formatarCpf(paciente.cpf) : '',
                nomeDaMae: paciente.nomeDaMae || '',
                estadoCivil: paciente.estadoCivil || null,
                foto: paciente.foto || '',
                email: paciente.email || '',
                cep: paciente.endereco ? (paciente.endereco.cep && paciente.endereco.unidadeFederativa ? formatarCep(paciente.endereco.cep) : paciente.endereco.cep) : '',
                logradouro: paciente.endereco ? paciente.endereco.logradouro : '',
                numero: paciente.endereco ? paciente.endereco.numero : '',
                complemento: paciente.endereco ? paciente.endereco.complemento : '',
                bairro: paciente.endereco ? paciente.endereco.bairro : '',
                uf: paciente.endereco ? paciente.endereco.unidadeFederativa : '',
                cidade: paciente.endereco ? paciente.endereco.cidade : '',
                celula: paciente.celula ? paciente.celula : '',
                medicoDeReferencia: paciente.medicoDeReferencia ? (paciente.medicoDeReferencia.nome || '') : '',
                enfermeiroDeReferencia: paciente.enfermeiroDeReferencia ? (paciente.enfermeiroDeReferencia.nome || '') : '',
                consultorDeReferencia: paciente.consultorDeReferencia ? (paciente.consultorDeReferencia.nome || '') : '',
                psicologoDeReferencia: paciente.psicologoDeReferencia ? (paciente.psicologoDeReferencia.nome || '') : '',
                unidadePreferencial: paciente.unidadePreferencial ? paciente.unidadePreferencial.identificador : '',
                associacao: paciente.associacao ? paciente.associacao.identificador : '',
                precisaDeAcompanhamento: paciente.precisaDeAcompanhamento !== undefined ? paciente.precisaDeAcompanhamento : false,
                observacoes: paciente.observacoes || '',
                ativo: paciente.ativo !== undefined ? paciente.ativo : true,
                motivoDaInativacao: paciente.motivoDaInativacao || null,
                precisaoDaDataDoObito: paciente.precisaoDaDataDoObito || null,
                dataDoObito: paciente.dataDoObito || null,
                comoOObitoFoiInformado: paciente.comoOObitoFoiInformado || null,
                telefones: paciente.telefones && paciente.telefones.length > 0 ?
                  paciente.telefones.map(x => ({
                    identificador: x.identificador,
                    proprietario: x.proprietario,
                    nome: x.nome || '',
                    whatsApp: x.whatsApp,
                    confirmacaoDeConsulta: x.confirmacaoDeConsulta,
                    grauDeRelacao: x.grauDeRelacao || '',
                    numero: x.numero,
                    ordem: x.proprietario === 'Paciente' ? '1' : '2'
                  })).sort(ordenarTelefones)
                  : [{
                    identificador: uuidv4(),
                    proprietario: 'Paciente',
                    nome: '',
                    grauDeRelacao: '',
                    numero: ''
                  }]
              }}
              acao={salvarPaciente}
              esquemaDeValidacoes={Yup.object().shape({
                nome: Yup.string().required('Obrigatório'),
                sexo: Yup.string()
                  .oneOf(['Feminino', 'Masculino'])
                  .required('Obrigatório'),
                cpf: Yup.string().ehUmCpfValido('CPF deve ser válido.')
                  .test(
                    'cadastro_teste',
                    'Obrigatório',
                    function (value) {
                      const { ativo } = this.parent
                      const cadastroFoiAntesDaObrigatoriedadeDoCpf = dataAnterior(paciente.cadastro?.dataEHora, DATA_INICIAL_DO_CPF_OBRIGATORIO)
                      return value || cadastroFoiAntesDaObrigatoriedadeDoCpf || !ativo
                    }
                  ),
                nomeDaMae: Yup.string().required('Obrigatório'),
                dataDeNascimento: Yup.string()
                  .nullable()
                  .required('Obrigatório'),
                email: Yup.string().ehUmEmailValido('E-mail deve ser válido.'),
                telefones: Yup.array()
                  .of(
                    Yup.object().shape({
                      proprietario: Yup.string().required('Obrigatório.'),
                      numero: Yup.string().ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999').required('Obrigatório.'),
                      nome: Yup.string()
                        .when('proprietario', {
                          is: val => val !== 'Paciente',
                          then: Yup.string().required('Obrigatório.').min(3, 'O nome deve possuir no mínimo 3 caracteres.')
                        }),
                      grauDeRelacao: Yup.string()
                        .when('proprietario', {
                          is: val => val !== 'Paciente',
                          then: Yup.string().required('Obrigatório.')
                        }),
                    })
                  ),
                cep: Yup.string().ehUmCepValido('CEP deve ser válido. Exemplo: 99999-999'),
                logradouro: Yup.string()
                  .when('cep', {
                    is: val => val,
                    then: Yup.string().required('Obrigatório.')
                  }),
                numero: Yup.string()
                  .when('cep', {
                    is: val => val,
                    then: Yup.string().required('Obrigatório.')
                  }),
                bairro: Yup.string()
                  .when('cep', {
                    is: val => val,
                    then: Yup.string().required('Obrigatório.')
                  }),
                motivoDaInativacao: Yup.string().nullable()
                    .when('ativo', {
                      is: val => val === false,
                      then: Yup.string().required('Obrigatório.')
                    }),
                precisaoDaDataDoObito: Yup.string().nullable()
                  .when('motivoDaInativacao', {
                    is: val => val === 'obito',
                    then: Yup.string().required('Obrigatório.')
                  }),
                dataDoObito: Yup.string().nullable()
                  .when('motivoDaInativacao', {
                    is: val => val === 'obito',
                    then: Yup.string().required('Obrigatório.')
                  }),
                comoOObitoFoiInformado: Yup.string().nullable()
                  .when('motivoDaInativacao', {
                    is: val => val === 'obito',
                    then: Yup.string().required('Obrigatório.')
                  }),
              })}
            >
              {({ values, setFieldValue }) => (
                <>
                  <div className='mt-2'>
                    <fieldset>
                      <div className='d-flex justify-content-between'>
                        <h2 className='form-title'>Dados Pessoais</h2>                        
                        {identificador && exibirBotaoProntuario &&
                          <Link to={`/cadastros/pacientes/${identificador}/prontuario`} className='button --primary --icon  d-flex d-lg-none botao-prontuario'>
                            <i className='icon icon-doctor'></i>
                          </Link>
                        }
                      </div>

                      {paciente.precisaDeAcompanhamento &&  renderizarAlerta([{ tipo: 'Alerta', texto: 'O paciente precisa de acompanhamento o tempo todo.' }])}
                      <div className='with-thumb'>
                        <div className='with-thumb-sidebar'>                   
                          <InputFoto
                            bytes={paciente.bytesDaFoto}
                            carregandoFoto={processando}
                            habilitarFuncoes={habilitarFuncoes}
                            nome='foto'
                            recuperarFotoOriginal={() => paciente.identificador && recuperarFotoOriginalDoPacientePorIdentificador(paciente.identificador)}
                            remover={() => paciente.identificador && removerFotoDoPaciente(paciente.identificador)}
                            selecionar={foto => foto && adicionarFotoDoPaciente(foto)}
                          />
                          <IndicadoresDoPaciente
                            aniversario={paciente.aniversario}
                            identificadorPublicoDoPaciente={paciente.identificadorPublico}
                            obito={paciente.dataDoObito}
                          />
                        </div>
                        <r-grid columns-md={6} columns-lg={12}>                       
                          <r-cell span={4} span-md={6} span-lg={6}>
                            {paciente.nome && <button title='Copiar' className='botao-copiar' type='button' onClick={() => { navigator.clipboard.writeText(paciente.nome) }}><i className='icon icon-copy'></i></button>}
                            <Input
                              type='text'
                              nome='nome'
                              tabIndex={1}
                              titulo='Nome *'
                              disabled={!exibirDadosInformacaoPaciente}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={3} span-lg={3}>
                            <Select
                              nome='sexo'
                              tabIndex={2}
                              titulo='Sexo *'
                              itens={sexos}
                              campoCodigo='nome'
                              campoDescricao='nome'
                              isDisabled={!exibirDadosInformacaoPaciente}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={3} span-lg={3}>
                            {paciente.dataDeNascimento && <button title='Copiar' className='botao-copiar' type='button' onClick={() => { navigator.clipboard.writeText(formatarDataParaFormatoLocal(paciente.dataDeNascimento)) }}><i className='icon icon-copy'></i></button>}
                            <DatePicker
                              type='text'
                              nome='dataDeNascimento'
                              tabIndex={3}
                              titulo='Data de Nascimento *'
                              isDisabled={!exibirDadosInformacaoPaciente}
                              maxDate={new Date()}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={6} span-lg={6}>
                            {paciente.nomeDaMae && paciente.nomeDaMae !== 'NÃO INFORMADO' && <button title='Copiar' className='botao-copiar' type='button' onClick={() => { navigator.clipboard.writeText(paciente.nomeDaMae) }}><i className='icon icon-copy'></i></button>}
                            <Input
                              type='text'
                              nome='nomeDaMae'
                              tabIndex={4}
                              titulo='Nome da Mãe *'
                              disabled={!exibirDadosInformacaoPaciente}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={3} span-lg={3}>
                            {paciente.cpf && <button title='Copiar' className='botao-copiar' type='button' onClick={() => { navigator.clipboard.writeText(formatarCpf(paciente.cpf)) }}><i className='icon icon-copy'></i></button>}
                            <Cpf
                              type='text'
                              nome='cpf'
                              tabIndex={5}
                              titulo={!values.ativo || dataAnterior(paciente.cadastro?.dataEHora, DATA_INICIAL_DO_CPF_OBRIGATORIO) ? 'CPF' : 'CPF *'}
                              disabled={!exibirDadosInformacaoPaciente}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={3} span-lg={3}>
                            <Select
                              nome='estadoCivil'
                              tabIndex={6}
                              titulo='Estado Civil'
                              itens={estadosCivis}
                              campoCodigo='nome'
                              campoDescricao='nome'
                              isDisabled={!exibirDadosInformacaoPaciente}
                            />
                          </r-cell>
                          <r-cell span={4} span-md={6} span-lg={12}>
                            {exibirBotaoEditar &&
                              <r-cell span={4} span-md={6}>
                                <Permissao permissoes={[permissoes.ALTERAR_DADOS_DE_IDENTIFICACAO_DO_PACIENTE]}>
                                  <div span={3} className='editar'>
                                    <button type='button' className='botao-editar-paciente' onClick={() => setExibirDadosInformacaoPaciente(!exibirDadosInformacaoPaciente)}>
                                      {exibirDadosInformacaoPaciente ? 'Bloquear' : 'Editar'}
                                    </button>
                                  </div>
                                </Permissao>
                              </r-cell>
                            }
                          </r-cell>
                          <r-cell span={4} span-md={6} span-lg={12}>
                            <ProximosAgendamentos paciente={paciente.identificador} />
                          </r-cell>                      
                        </r-grid>
                      </div>
                    </fieldset>
                  </div>
                  <div className='mt-2'>
                    <fieldset>
                      <h2 className='form-title'>Dados de Contato</h2>
                      <r-grid columns-md={12} columns-lg={12}>
                        <r-cell span={4} span-md={12} span-lg={6}>
                          <Input
                            type='text'
                            nome='email'
                            tabIndex={7}
                            titulo='Email do paciente'
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <FieldArray
                          name='telefones'
                        >
                          {({ push, remove }) => (
                            <>
                              {values.telefones.map((x, index) => renderizarTelefones(index, values, remove, setFieldValue))}
                              {habilitarFuncoes &&
                                <r-cell span={4} span-md={12} span-lg={12} class='d-flex justify-content-center'>
                                  <button
                                    className='button --light'
                                    type='button'
                                    onClick={() => push({
                                      identificador: uuidv4(),
                                      proprietario: '',
                                      nome: '',
                                      grauDeRelacao: '',
                                      numero: ''
                                    })}
                                  >
                                    Adicionar Telefone
                                  </button>
                                </r-cell>
                              }
                            </>
                          )}
                        </FieldArray>
                      </r-grid>
                    </fieldset>
                  </div>
                  <div className='mt-2'>
                    <fieldset>
                      <h2 className='form-title'>Endereço</h2>
                      <r-grid columns-md={6} columns-lg={12}>
                        <r-cell span={4} span-md={2} span-lg={2}>
                          <Cep
                            type='text'
                            nome='cep'
                            tabIndex={19}
                            titulo='CEP'
                            onBlur={() => aoSairDoCep(values.cep, setFieldValue)}
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={5}>
                          <Input
                            type='text'
                            nome='logradouro'
                            tabIndex={20}
                            titulo={`Logradouro ${values.cep ? '*' : ''}`}
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={1} span-lg={2}>
                          <Input
                            type='text'
                            nome='numero'
                            tabIndex={21}
                            titulo={`Número ${values.cep ? '*' : ''}`}
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={4} span-lg={3}>
                          <Input
                            type='text'
                            nome='complemento'
                            tabIndex={22}
                            titulo='Complemento'
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={2} span-lg={2}>
                          <Select
                            nome='uf'
                            tabIndex={23}
                            titulo={`UF ${values.cep ? '*' : ''}`}
                            itens={unidadesFederativas}
                            campoCodigo='sigla'
                            campoDescricao='sigla'
                            onChange={setUnidadeFederativaSelecionada}
                            isDisabled={values.cep}
                          />
                        </r-cell>
                        <r-cell span={3} span-md={3} span-lg={5}>
                          <Select
                            nome='cidade'
                            tabIndex={24}
                            titulo={`Cidade ${values.cep ? '*' : ''}`}
                            itens={cidades}
                            isDisabled={values.cep}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={3} span-lg={5}>
                          <Input
                            type='text'
                            nome='bairro'
                            tabIndex={25}
                            titulo={`Bairro ${values.cep ? '*' : ''}`}
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                      </r-grid>
                    </fieldset>
                  </div>
                  <div className='mt-2'>
                    <fieldset>
                      <h2 className='form-title'>Célula</h2>
                      <r-grid columns-md={6} columns-lg={12} class='align-items-end'>
                        <r-cell span={4} span-md={2} span-lg={2}>
                          <Select
                            nome='celula'
                            tabIndex={26}
                            titulo='Célula'
                            itens={celulas}
                            campoCodigo='nome'
                            campoDescricao='nome'
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={2} span-lg={5}>
                          <Select
                            nome='medicoDeReferencia'
                            tabIndex={27}
                            titulo='Médico de Referência'
                            itens={medicos}
                            campoCodigo='nome'
                            campoDescricao='label'
                            isDisabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={2} span-lg={5}>
                          <Select
                            nome='enfermeiroDeReferencia'
                            tabIndex={28}
                            titulo='Enfermeiro de Referência'
                            itens={enfermeiros}
                            campoCodigo='nome'
                            campoDescricao='label'
                            isDisabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md='3-4' span-lg='3-7'>
                          <Select
                            nome='psicologoDeReferencia'
                            tabIndex={29}
                            titulo='Psicólogo de Referência'
                            itens={psicologos}
                            campoCodigo='nome'
                            campoDescricao='label'
                            isDisabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={2} span-lg={5}>
                          <Select
                            nome='consultorDeReferencia'
                            tabIndex={30}
                            titulo='Consultor de Referência'
                            itens={retornarConsultores(consultores, paciente.consultorDeReferencia)}
                            campoCodigo='nome'
                            campoDescricao='label'
                            isDisabled={!habilitarFuncoes}
                          />
                        </r-cell>
                      </r-grid>
                    </fieldset>
                  </div>
                  <div className='mt-2'>
                    <fieldset>
                      <h2 className='form-title'>Dados de Atendimento</h2>
                      <r-grid columns-md={6} columns-lg={12} class='align-items-end'>
                        <r-cell span={4} span-md={2} span-lg={2}>
                          <Checkbox
                            nome='precisaDeAcompanhamento'
                            tabIndex={31}
                            titulo='Precisa de Acompanhante'
                            classname={!habilitarFuncoes ? 'is-disabled mb-2' : 'mb-2'}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={2} span-lg={5}>
                          <Select
                            nome='unidadePreferencial'
                            tabIndex={32}
                            titulo='Unidade Preferencial'
                            itens={unidades}
                            campoCodigo='identificador'
                            campoDescricao='nome'
                            isDisabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md={2} span-lg={5}>
                          <Select
                            nome='associacao'
                            tabIndex={33}
                            titulo='Associação'
                            itens={associacoes}
                            campoCodigo='identificador'
                            campoDescricao='nome'
                            isDisabled={!habilitarFuncoes}
                          />
                        </r-cell>
                        <r-cell span={4} span-md='row' span-lg='row'>
                          <Input
                            as='textarea'
                            nome='observacoes'
                            tabIndex={34}
                            titulo='Observações'
                            disabled={!habilitarFuncoes}
                          />
                        </r-cell>
                      </r-grid>
                      {paciente.identificador &&
                        <div className='informacoes-de-auditoria'>
                          <span className='titulo-do-usuario'>Cadastro: </span>
                          <span className='dados-do-usuario'>{`${paciente.cadastro.usuario.nome} | ${formatarDataEHoraParaFormatoLocal(paciente.cadastro.dataEHora)} `}</span>
                          <span className='titulo-do-usuario'>Última alteração: </span>
                          <span className='dados-do-usuario'>{`${paciente.ultimaAlteracao.usuario.nome} | ${formatarDataEHoraParaFormatoLocal(paciente.ultimaAlteracao.dataEHora)}`}</span>
                        </div>
                      }
                    </fieldset>
                  </div>
                  {values.identificador &&
                    <div className='mt-2'>
                      <fieldset>
                        <h2 className='form-title'>Status</h2>
                        <r-grid columns-md={6} columns-lg={12}>
                          <r-cell span={4} span-md={6} span-lg={12}>
                            <Toglle
                                className='form-toggle-2'
                                nome='ativo'
                                titulo='Ativo'
                                onChange={status => aoInformarStatusDoPaciente(status, setFieldValue)}
                                disabled={!permissaoDeInativarPaciente}
                            />
                          </r-cell>
                          {!values.ativo &&
                            <>
                              <r-cell span={4} span-md={6} span-lg={3}>
                                <Select
                                    nome='motivoDaInativacao'
                                    tabIndex={35}
                                    titulo='Motivo da Inativação *'
                                    itens={motivosDeInativacao}
                                    campoCodigo='codigo'
                                    campoDescricao='nome'
                                    onChange={motivo => aoInformarMotivoDeInativacao(motivo, setFieldValue)}
                                    isDisabled={!permissaoDeInativarPaciente}
                                />
                              </r-cell>
                              {values.motivoDaInativacao === 'obito' &&
                                  <>
                                    <r-cell span={4} span-md={3} span-lg={3}>
                                      <Select
                                          nome='precisaoDaDataDoObito'
                                          tabIndex={36}
                                          titulo='Período *'
                                          itens={periodos}
                                          campoCodigo=''
                                          campoDescricao=''
                                          isDisabled={!permissaoDeInativarPaciente}
                                      />
                                    </r-cell>
                                    {values.precisaoDaDataDoObito === 'Dia' &&
                                        <r-cell span={4} span-md={3} span-lg={3}>
                                          <DatePicker
                                              nome='dataDoObito'
                                              titulo='Dia *'
                                              tabIndex={37}
                                              maxDate={new Date()}
                                              isDisabled={!permissaoDeInativarPaciente}
                                          />
                                        </r-cell>
                                    }
                                    {values.precisaoDaDataDoObito === 'Mês/Ano' &&
                                        <r-cell span={4} span-md={3} span-lg={3}>
                                          <MonthPicker
                                              nome='dataDoObito'
                                              titulo='Mês/Ano *'
                                              tabIndex={37}
                                              maxDate={new Date()}
                                              isDisabled={!permissaoDeInativarPaciente}
                                          />
                                        </r-cell>
                                    }
                                    <r-cell span={4} span-md='row' span-lg='row'>
                                      <Input
                                          as='textarea'
                                          nome='comoOObitoFoiInformado'
                                          titulo='Como o óbito foi informado? *'
                                          tabIndex={38}
                                          disabled={!permissaoDeInativarPaciente}
                                      />
                                    </r-cell>
                                  </>
                              }
                            </>
                          }
                        </r-grid>
                      </fieldset>
                    </div>
                  }
                  <div className='list-btn'>
                    <Link
                        to={`/cadastros/pacientes`}
                        className='button --light'
                        title='Voltar'
                    >
                      Voltar
                    </Link>
                    <Permissao permissoes={[permissoes.ALTERAR_PACIENTE, permissoes.ADICIONAR_PACIENTE]}>
                      <button type='submit' className='button --primary'>{identificador ? 'Salvar Alterações' : 'Adicionar'}</button>
                    </Permissao>
                  </div>
                </>
              )}
            </Form>
          </Spinner>
        )}
      </Spinner>
    </>
  )
}

function AlertaDeInativacao({ nomeDoPaciente, motivoDeInativacao, salvar, fechar }) {
  const salvarPacienteInativado = () => {
    salvar()
    fechar()
  }

  const renderizaInformacoes = motivo => {
    switch (motivo) {
      case 'obito':
        return (
        <>
          <h2>{`Tem certeza da informação do óbito de ${nomeDoPaciente}?`}</h2>
          <div className='form-choice-alerta my-1'>
            <div className='form-choice-alerta__item'>
              <i className='icon icon-attention-circled'></i>
            </div>
            <div className='form-choice-alerta__item'>
              <ul>
                <li>ATENÇÃO: Ao indicar que o paciente faleceu, todos os agendamentos, encaminhamentos e programas serão
                  cancelados!
                </li>
                <li><strong>Este processo não poderá ser revertido.</strong></li>
              </ul>
            </div>
          </div>
        </>)
      default:
        return (
            <>
              <h2>{`Tem certeza da inativação de ${nomeDoPaciente}?`}</h2>
              <div className='form-choice-alerta my-1'>
                <div className='form-choice-alerta__item'>
                  <i className='icon icon-attention-circled'></i>
                </div>
                <div className='form-choice-alerta__item'>
                  <ul>
                    <li>ATENÇÃO: Ao indicar que o paciente é inativo, não poderá ser realizado mais agendamentos e atendimentos para o mesmo.
                    </li>
                  </ul>
                </div>
              </div>
            </>)
    }
  }

  return (
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={12}>
          <div className={`form`}>
            <fieldset className='form-group'>
              {renderizaInformacoes(motivoDeInativacao)}
              <div className='list-btn'>
                <button className='button --light' type='button' onClick={fechar}>Não</button>
                <button className='button --danger' type='button' onClick={salvarPacienteInativado}>Sim</button>
              </div>
            </fieldset>
          </div>
        </r-cell>
      </r-grid>
  )
}