import 'react-input-range/lib/css/index.css'
import React, { useEffect, useState } from 'react'
import InputRange from 'react-input-range'

export const PerguntaSimNao = ({ secao, chave, texto, respostaPadrao = '', aoSelecionarResposta, desabilitado = false, valor }) => {
  const [resposta, definirResposta] = useState(respostaPadrao)

  const aoClicar = resposta => {
    if (aoSelecionarResposta) {
      aoSelecionarResposta(secao, chave, resposta)
    }

    definirResposta(resposta)
  }

  useEffect(() => { definirResposta(valor) }, [valor])

  return (
    <div className={`pergunta-container ${desabilitado ? 'is-disabled' : ''}`}>
      <div className='texto-pergunta'>{texto}</div>
      <div className='botoes-resposta'>
        <button
          className={resposta ? 'selecionado' : ''}
          onClick={() => aoClicar(true)}
          disabled={desabilitado}
        >
          Sim
        </button>
        <button
          className={!resposta ? 'selecionado' : ''}
          onClick={() => aoClicar(false)}
          disabled={desabilitado}
        >
          Não
        </button>
      </div>
    </div>
  )
}

export const PerguntaDeOpcaoUnica = ({ secao, chave, texto, opcoes, respostaPadrao = '', aoSelecionarResposta, desabilitado, }) => {
  const [resposta, definirResposta] = useState(respostaPadrao)

  const aoClicar = resposta => {
    if (aoSelecionarResposta) {
      aoSelecionarResposta(secao, chave, resposta)
    }

    definirResposta(resposta)
  }

  return (
    <div className={`pergunta-container ${desabilitado ? 'is-disabled' : ''}`}>
      <div className='texto-pergunta'>{texto}</div>
      <div className='botoes-resposta'>
        {opcoes.map(opcao =>
          <button
            className={resposta === opcao.codigo ? 'selecionado' : ''}
            disabled={desabilitado}
            onClick={() => aoClicar(opcao.codigo)}
          >
            {opcao.nome}
          </button>,
        )}
      </div>
    </div>
  )
}

export const PerguntaSelecionarItens = ({ secao, chave, texto, itens, respostaPadrao = [], aoSelecionarResposta, desabilitado, }) => {
  const [selecionados, definirSelecionados] = useState(respostaPadrao)

  const aoSelecionarItem = item => {
    let novosSelecionados

    if (selecionados.includes(item)) {
      novosSelecionados = selecionados.filter(i => i !== item)
    } else {
      novosSelecionados = [...selecionados, item]
    }

    definirSelecionados(novosSelecionados)
    aoSelecionarResposta && aoSelecionarResposta(secao, chave, novosSelecionados)
  }

  return (
    <div className={`pergunta-container ${desabilitado ? 'is-disabled' : ''}`}>
      <div className='texto-pergunta'>{texto}</div>
      <div className='lista-itens'>
        {itens.map((item, index) => (
          <div key={`${chave}_${item.codigo}_${index}`} className='checkbox'>
            <input
              type='checkbox'
              id={`checkbox_${item.codigo}`}
              name={`checkbox_${index}`}
              disabled={desabilitado}
              checked={selecionados.includes(item.codigo)}
              onChange={() => aoSelecionarItem(item.codigo)}
            />
            <label htmlFor={`checkbox_${item.codigo}`}>
              <span>{item.nome}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export const PerguntaEscala = ({ secao, chave, texto, inicio = 1, fim = 10, respostaPadrao = '', aoSelecionarResposta, desabilitado, }) => {
  const intensidades = ['', 'um', 'dois', 'tres', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove', 'dez']
  const indexDaIntensidade = intensidades.indexOf(respostaPadrao)

  const [resposta, definirResposta] = useState(indexDaIntensidade > 0)
  const [intensidade, definirIntensidade] = useState(intensidades.indexOf(respostaPadrao))

  const aoClicarSimNao = resposta => {
    definirResposta(resposta)

    if (!resposta) {
      definirIntensidade(0)
      aoSelecionarResposta && aoSelecionarResposta(secao, chave, intensidades[0])
    } else {
      definirIntensidade(1)
      aoSelecionarResposta && aoSelecionarResposta(secao, chave, intensidades[1])
    }
  }

  const aoDefinirIntensidade = valor => {
    definirIntensidade(valor)
    aoSelecionarResposta && aoSelecionarResposta(secao, chave, intensidades[valor])
  }

  return (
    <div className={`pergunta-container ${desabilitado ? 'is-disabled' : ''}`}>
      {texto &&
        <>
          <div className='texto-pergunta'>{texto}</div>
          <div className='botoes-resposta'>
            <button
              className={resposta ? 'selecionado' : ''}
              onClick={() => aoClicarSimNao(true)}
            >
              Sim
            </button>
            <button
              className={!resposta ? 'selecionado' : ''}
              onClick={() => aoClicarSimNao(false)}
            >
              Não
            </button>
          </div>
        </>
      }
      {(resposta || !texto) && (
        <div className='escala-intensidade'>
          <label>{`Intensidade da dor (${inicio}-${fim}):`}</label>
          <InputRange
            minValue={inicio}
            maxValue={fim}
            value={intensidade}
            onChange={valor => aoDefinirIntensidade(valor)}
          />
        </div>
      )}
    </div>
  )
}

export const PerguntaObservacoes = ({ secao, chave, texto, respostaPadrao = '', aoSelecionarResposta, desabilitado, }) => {
  const [observacao, definirObservacao] = useState(respostaPadrao ? respostaPadrao : '')

  const aoAlterarTexto = e => {
    definirObservacao(e.target.value)
    aoSelecionarResposta && aoSelecionarResposta(secao, chave, e.target.value)
  }

  return (
    <div className={`pergunta-container ${desabilitado ? 'is-disabled' : ''}`}>
      <div className='texto-pergunta'>{texto}</div>
      <textarea
        value={observacao}
        onChange={aoAlterarTexto}
      />
    </div>
  )
}