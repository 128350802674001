import { ALTEROU_CID, LISTOU_CIDS, LISTOU_CIDS_DO_CADASTRO, RECUPEROU_CID_PELO_IDENTIFICADOR } from '../acoes/tipos'

const estadoInicial = {
  filtros: {
    termo: '',
    habilitadoPara: ['Diagnósticos', 'Internações', 'Idas ao PA'],
    csap: ['Sim', 'Não']
  },
  itemAtual: null,
  ordenacao: { nome: 'codigo', ordem: '+' },
  paginaDeDados: {},
  itens: [],
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_CIDS: {
      return {
        ...state,
        itens: action.cids
      }
    }

    case LISTOU_CIDS_DO_CADASTRO: {
      return {
        ...state,
        filtros: action.filtros,
        itemAtual: null,
        paginaDeDados: action.paginaDeDados,
      }
    }

    case RECUPEROU_CID_PELO_IDENTIFICADOR:
    case ALTEROU_CID: {
      return {
        ...state,
        itemAtual: action.cid,
      }
    }

    default: {
      return state
    }
  }
}