import api from './api-major'

const listarCids = (filtros = {}, pagina, ordenacao, tamanhoDaPagina) => {
  const { habilitadoPara, csap, termo } = filtros

  const paginacao = pagina ? `?pagina=${pagina}` : '?pagina=1'
  const parametroOrdem = ordenacao ? `&ordem=${ordenacao.ordem}${ordenacao.nome}` : '&ordem=+codigo'
  const tamanhoDaPaginaFormatado = tamanhoDaPagina ? `&tamanhoDaPagina=${tamanhoDaPagina}` : '&tamanhoDaPagina=100000'
  const parametroTermo = termo ? `&termo=${termo}` : ''
  const parametroCsap = csap !== undefined && csap.length === 1 ? `&csap=${csap[0] === 'Sim'}` : ''
  const habilitadoParaDiagnosticos = habilitadoPara !== undefined ? `&habilitadoParaDiagnosticos=${habilitadoPara.includes('Diagnósticos')}` : ''
  const habilitadoParInternacoes = habilitadoPara !== undefined ? `&habilitadoParaInternacoes=${habilitadoPara.includes('Internações')}` : ''
  const habilitadoParaIdasAoPa = habilitadoPara !== undefined ? `&habilitadoParaIdasAoProntoAtendimento=${habilitadoPara.includes('Idas ao PA')}` : ''

  return api.get(`/cids${paginacao}${tamanhoDaPaginaFormatado}${parametroOrdem}${parametroTermo}${parametroCsap}${habilitadoParaDiagnosticos}${habilitadoParInternacoes}${habilitadoParaIdasAoPa}`)
}

const recuperarCidPeloIdentificador = identificador => api.get(`/cids/${identificador}`)
const alterarCid = cid => api.put(`/cids/${cid.identificador}`, cid)

export default {
  listarCids,
  recuperarCidPeloIdentificador,
  alterarCid
}