import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Spinner from '../../../spinner'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela } from '../../../tabela-dnd'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import AdicionarMedicamento from './adicionar'
import AlterarMedicamento from './alterar'
import ConfirmarMedicamento from './confirmar'
import RemoverMedicamento from './remover'
import { LISTAR_MEDICAMENTOS_DO_PACIENTE, ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Medicamentos({ medicamentosDoPaciente, medicamentos, posologias, identificadorDoPaciente }) {
  const {
    adicionarMedicamentoDoPaciente,
    alterarMedicamentoDoPaciente,
    alterarStatusDoMedicamentoDoPaciente,
    listarMedicamentosDoPaciente,
    alterarPosicaoDosMedicamentosDoPaciente
  } = useProntuario()

  const [exibirAdicionarMedicamento, setExibirAdicionarMedicamento] = useState(false)
  const [exibirAlterarMedicamento, setExibirAlterarMedicamento] = useState(false)
  const [exibirConfirmarMedicamento, setExibirConfirmarMedicamento] = useState(false)
  const [exibirRemoverMedicamento, setExibirRemoverMedicamento] = useState(false)
  const [medicamentoSelecionado, setMedicamentoSelecionado] = useState(false)

  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_DIAGNOSTICO_DO_PACIENTE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_MEDICAMENTO_DO_PACIENTE])
  const podeAlterar = usePossuiAsPermissoes([permissoes.ALTERAR_DOSE_DO_MEDICAMENTO_DO_PACIENTE])

  useEffect(() => {
    if (medicamentosDoPaciente === undefined) {
      listarMedicamentosDoPaciente(identificadorDoPaciente)
    }
  }, [listarMedicamentosDoPaciente, identificadorDoPaciente, medicamentosDoPaciente])

  function abrirAdicionarMedicamento() {
    setExibirAdicionarMedicamento(true)
  }

  function fecharAdicionarMedicamento() {
    setExibirAdicionarMedicamento(false)
  }

  function abrirAlterarMedicamento(item) {
    setMedicamentoSelecionado(item)
    setExibirAlterarMedicamento(true)
  }

  function fecharAlterarMedicamento() {
    setExibirAlterarMedicamento(false)
  }

  function abrirConfirmarMedicamento(item) {
    setMedicamentoSelecionado(item)
    setExibirConfirmarMedicamento(true)
  }

  function fecharConfirmarMedicamento() {
    setExibirConfirmarMedicamento(false)
  }

  function abrirRemoverMedicamento(item) {
    setMedicamentoSelecionado(item)
    setExibirRemoverMedicamento(true)
  }

  function fecharRemoverMedicamento() {
    fecharAlterarMedicamento()
    setExibirRemoverMedicamento(false)
  }

  const alterarPosicaoDoMedicamento = async (result) => {
    if (!result.destination) return

    const [medicamento] = medicamentosDoPaciente.splice(result.source.index, 1)
    const posicao = result.destination.index + 1
    medicamentosDoPaciente.splice(result.destination.index, 0, medicamento)

    if (medicamento.posicao !== posicao) {
      const alterou = await alterarPosicaoDosMedicamentosDoPaciente(identificadorDoPaciente, medicamento.identificador, { posicao }, medicamentosDoPaciente)

      if (!alterou) listarMedicamentosDoPaciente(identificadorDoPaciente)
    }
  }

  const retornarDadosDoProfissional = (usuario, profissao) => {
    let texto = profissao ? "Profissional: " : "Usuário: "

    if (usuario.tratamento) {
      texto += usuario.tratamento
      texto += " "
    }

    texto += usuario.nome

    if (profissao) {
      texto += " | "
      texto += profissao.nome
    }

    return texto
  }

  return (
    <>
      <Modal
        isOpen={exibirAdicionarMedicamento}
        className='modal'
        contentLabel='Modal para adição de medicamento'
      >
        <AdicionarMedicamento
          fechar={fecharAdicionarMedicamento}
          medicamentos={medicamentos}
          posologias={posologias}
          identificadorDoPaciente={identificadorDoPaciente}
          adicionarMedicamento={adicionarMedicamentoDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirAlterarMedicamento}
        className='modal'
        contentLabel='Modal para alteração de medicamento'
      >
        <AlterarMedicamento
          fechar={fecharAlterarMedicamento}
          medicamentos={medicamentos}
          medicamentoSelecionado={medicamentoSelecionado}
          posologias={posologias}
          identificadorDoPaciente={identificadorDoPaciente}
          alterarMedicamento={alterarMedicamentoDoPaciente}
          removerMedicamento={(item) => abrirRemoverMedicamento(item)}
        />
      </Modal>
      <Modal
        isOpen={exibirConfirmarMedicamento}
        className='modal'
        contentLabel='Modal para confirmação do medicamento'
      >
        <ConfirmarMedicamento
          fechar={fecharConfirmarMedicamento}
          alterarStatusDoMedicamentoDoPaciente={alterarStatusDoMedicamentoDoPaciente}
          medicamento={medicamentoSelecionado}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirRemoverMedicamento}
        className='modal'
        contentLabel='Modal para remoção do medicamento'
      >
        <RemoverMedicamento
          alterarStatusDoMedicamentoDoPaciente={alterarStatusDoMedicamentoDoPaciente}
          fechar={fecharRemoverMedicamento}
          medicamento={medicamentoSelecionado}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={7}>
        <Spinner operacoes={[LISTAR_MEDICAMENTOS_DO_PACIENTE, ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE]}>
          <Tabela
            acoes={[
              item => (
                <Permissao key='remover' permissoes={[permissoes.REMOVER_MEDICAMENTO_CONFIRMADO_DO_PACIENTE, permissoes.REMOVER_MEDICAMENTO_DO_PACIENTE]}>
                  <button
                    className='table-items__bt --remove'
                    title='Remover'
                    onClick={() => abrirRemoverMedicamento(item)}
                    type='button'
                    key={`remove_${item.dataEHora}`}
                  >
                    <i className='icon icon-remove'></i>
                  </button>
                </Permissao>
              ),
              item => {
                const permissaoParaConfirmar = podeConfirmar && item.status !== 'Confirmado' && !item.outroMedicamentoEConcentracao
                return (
                  permissaoParaConfirmar &&
                  <button
                    className='table-items__bt --edit'
                    title='Confirmar'
                    onClick={() => abrirConfirmarMedicamento(item)}
                    type='button'
                    key={`confirma_${item.dataEHora}`}
                  >
                    <i className='icon icon-checked'></i>
                  </button>
                )
              },
              item => {
                const permissaoParaAlterar = podeAlterar && !item.outroMedicamentoEConcentracao
                return (
                  permissaoParaAlterar &&
                  <button
                    className='table-items__bt --edit'
                    title='Alterar'
                    onClick={() => abrirAlterarMedicamento(item)}
                    type='button'
                    key={`altera_${item.dataEHora}`}
                  >
                    <i className='icon icon-pencil'></i>
                  </button>
                )
              },
              item =>
                <div key='informacao'>
                  <button
                    data-tip
                    data-for={`info_${item.identificador}`}
                    key={`info_${item.identificador}`}
                    className='table-items__bt --edit'
                    type='button'
                  >
                    <i className='icon icon-info'></i>
                  </button>
                  <ReactTooltip
                    id={`info_${item.identificador}`}
                    place='left'
                    effect='solid'
                    type='info'
                    key={item.identificador}
                    className='tamanho-tooltip-diagnostico'
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'rgb(232, 232, 232)'
                      }}><strong>{item.medicamento ? `${item.medicamento.nome} - ${item.concentracao.valor}` : item.outroMedicamentoEConcentracao}</strong></div>
                    <span>{retornarDadosDoProfissional(item.usuario, item.usuario.profissao)}</span> <br />
                    <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    {item.observacoes &&
                      <pre>
                        <strong>Observações: </strong>
                        {item.observacoes}
                      </pre>
                    }
                  </ReactTooltip>
                </div>
              ,
            ]}
            chave='identificador'
            className='--secondary'
            dados={medicamentosDoPaciente}
            exibirBotaoAdicionar={podeAdicionar}
            acaoDoBotaoAdicionar={abrirAdicionarMedicamento}
            mensagemTabelaVazia='Não existem registros de medicamentos.'
            classNameAdicionar='col-xs-6 col-lg-3'
            nome='medicamentos'
            handleOnDragEndDrop={alterarPosicaoDoMedicamento}
          >
            <Coluna
              className='col-xs-6 col-lg-5'
              principal={true}
              exibirTitulo={true}
              nome='Medicamentos'
              renderizar={item => item.status !== 'Confirmado' ?
                <span style={{ color: '#e74c3c' }}>
                  {item.medicamento ? `${item.medicamento.nome} - ${item.concentracao.valor}` : item.outroMedicamentoEConcentracao}
                </span>
                : item.medicamento ? `${item.medicamento.nome} - ${item.concentracao.valor}` : item.outroMedicamentoEConcentracao}
            />
            <Coluna
              campoDeDados='frequencia'
              className='col-lg-4'
              nome='Frequência'
            />
          </Tabela>
        </Spinner>
      </r-cell>
    </>
  )
}