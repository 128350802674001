import React, { useState, useEffect, useMemo } from 'react'
import Modal from 'react-modal'
import { Formulario as Form, Input } from '../../formik/formulario'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import { contem } from '../../../bibliotecas/texto'
import { useProntuario } from '../contexto'
import { confirmAlert } from 'react-confirm-alert'
import { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import CampoDePesquisa from '../../campo-de-pesquisa'
import Spinner from '../../spinner'
import * as Yup from 'yup'
import ResumoAtendimento from '../atendimento/resumo'
import ResumoTriagem from '../cabecalho/triagens/resumo'
import Imprimir from './impressao'
import CancelarAtendimentoFinalizado from './cancelar-atendimento-finalizado'
import AssinaturaComCertificadoFisico from '../assinatura-fisico'
import AssinaturaComCertificadoEmNuvem from '../assinatura-em-nuvem'
import { useUsuarioLogado } from '../../seguranca/provedor-de-autenticacao'
import privado from '../../../design/img/lock.png'
import Resultado from '../cabecalho/triagens/resultado'

import {
  CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  ADICIONAR_INFORMACOES,
  ADICIONAR_ARQUIVO,
  LISTAR_ATENDIMENTOS_FINALIZADOS,
  FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO, LISTAR_RESULTADOS_DAS_TRIAGENS,
} from '../../../acoes/tipos'

export default function LinhaDoTempo(props) {
  const {
    alterarTermoDaTimeline,
    atendimentosCarregados,
    atendimentosFinalizados,
    itemSelecionado,
    cancelarAtendimento,
    identificador,
    listarAtendimentosFinalizados,
    recuperarCertificadosEmNuvemDoUsuario,
    selecionarItem,
    termo,
    usuarioLogado,
    triagens,
  } = props

  const {
    carregarDetalhesDoAtendimentoFinalizado,
    adicionarInformacoes,
    adicionarArquivo,
    assinarAtendimentoFinalizado,
    orientacoesDePagina,
    tamanhosDePaginas
  } = useProntuario()

  const { usuario } = useUsuarioLogado()

  const [exibirImprimirAtendimento, setExibirImprimirAtendimento] = useState(false)
  const [exibirAdicionarInformacoes, setExibirAdicionarInformacoes] = useState(false)
  const [exibirCancelarAtendimentoFinalizado, setExibirCancelarAtendimentoFinalizado] = useState(false)
  const [exibirFinalizarAtendimento, setExibirFinalizarAtendimento] = useState(false)
  const [exibirAssinaturaComCertificadoFisico, setExibirAssinaturaComCertificadoFisico] = useState(false)
  const [exibirAssinaturaComCertificadoEmNuvem, setExibirAssinaturaComCertificadoEmNuvem] = useState(false)

  const identificadorDoAtendimento = itemSelecionado && itemSelecionado.atendimento ? itemSelecionado.atendimento.identificador : null
  const atendimento = identificadorDoAtendimento && atendimentosCarregados && atendimentosCarregados[identificadorDoAtendimento].atendimento
  const secoes = identificadorDoAtendimento && atendimentosCarregados && atendimentosCarregados[identificadorDoAtendimento].secoes

  const ehProfissionalDeSaude = usuarioLogado.profissao
  const mesmoConselho = atendimento && ehProfissionalDeSaude && !atendimento.privado && atendimento.usuario.profissao && (usuarioLogado.profissao.conselho.nome === atendimento.usuario.profissao.conselho)
  const ehOProfissionalDoAtendimento = atendimento && !atendimento.privado && (usuarioLogado.nome === atendimento.usuario.nome)

  const podeAdicionarInformacao = usePossuiAsPermissoes([permissoes.ADICIONAR_INFORMACOES_NO_ATENDIMENTO])
  const podeImprimirAtendimento = usePossuiAsPermissoes([permissoes.FAZER_DOWNLOAD_DO_PRONTUARIO_DO_PACIENTE])
  const podeCancelarAtendimentoGeral = usePossuiAsPermissoes([permissoes.CANCELAR_ATENDIMENTO_FINALIZADO])
  const podeCancelarAtendimentoDeOutrasProfissoes = usePossuiAsPermissoes([permissoes.CANCELAR_ATENDIMENTO_DE_OUTRA_PROFISSAO])
  const podeCancelarAtendimentoDaMesmaProfissao = usePossuiAsPermissoes([permissoes.CANCELAR_ATENDIMENTO_DA_MESMA_PROFISSAO])
  const podeAssinar = usePossuiAsPermissoes([permissoes.ASSINAR_ATENDIMENTO])

  const podeCancelarAtendimento = ehOProfissionalDoAtendimento ||
    (mesmoConselho ? podeCancelarAtendimentoDaMesmaProfissao : podeCancelarAtendimentoDeOutrasProfissoes)

  const alterarTermo = termo => {
    alterarTermoDaTimeline(identificador, termo)
  }

  let itens = []

  const carregarAtendimentos = (itens, atendimentos = []) => {
    atendimentos.forEach(atendimento => {
      itens.push({
        tipo: 'atendimento',
        dataEHora: new Date(atendimento.inicio),
        atendimento: atendimento,
      })
    })

    return itens
  }

  const carregarTriagens = (itens, triagens = []) => {
    triagens.forEach(triagem => {
      itens.push({
        tipo: 'triagem',
        dataEHora: new Date(triagem.horario),
        triagem: triagem,
      })
    })

    return itens
  }

  const ordernarItensPelaDataEHora = itens => {
    return itens.sort((a, b) => b.dataEHora - a.dataEHora)
  }

  itens = carregarAtendimentos(itens, atendimentosFinalizados)
  itens = carregarTriagens(itens, triagens)
  itens = ordernarItensPelaDataEHora(itens)

  const itensFiltrados = useMemo(() => {
    if (!termo) return itens

    const tokens = termo.split(' ').filter(x => x)

    return itens.filter(x => {
      const informacoesDoUsuario = x.atendimento ? x.atendimento.usuario : x.triagem.profissional
      const data = formatarDataParaFormatoLocal(x.atendimento ? x.atendimento.inicio : x.triagem.horario)

      let baseDaPesquisa = ''
      baseDaPesquisa = data + ' '
      baseDaPesquisa += x.atendimento ? x.atendimento.tipo.nome : 'TRIAGEM'
      baseDaPesquisa += (informacoesDoUsuario.nome || '') + ' '

      if (informacoesDoUsuario.profissao) {
        baseDaPesquisa += (informacoesDoUsuario.profissao.nome || '') + ' '
      }

      for (const token of tokens) {
        if (!contem(baseDaPesquisa, token)) {
          return false
        }
      }

      return true
    })
  }, [itens, termo])

  useEffect(() => {
    if (itens && itens.length > 0) {
      const item = itens[0]

      if (!itemSelecionado) {
        if (item.atendimento) {
          carregarDetalhesDoAtendimentoFinalizado(item.atendimento.paciente.identificador, item.atendimento.identificador,
            item.atendimento.tipo)
        }

        selecionarItem(item)
      }
    }
  }, [carregarDetalhesDoAtendimentoFinalizado, itemSelecionado, itens, selecionarItem])

  const abrirAtendimento = async atendimento => {
    if (atendimento._status !== 'carregado') {
      await carregarDetalhesDoAtendimentoFinalizado(atendimento.paciente.identificador, atendimento.identificador, atendimento.tipo)
    } else {
      selecionarItem({ atendimento: atendimentosCarregados[atendimento.identificador].atendimento })
    }
  }

  const selecionarTriagem = async triagem => {
    selecionarItem({ triagem: triagem })
  }

  const formatarNomeDoProfissional = usuario => {
    let nomeDoProfissional = usuario.nome

    if (usuario.profissao && usuario.profissao.especialidades.length === 0) {
      nomeDoProfissional += ` (${usuario.profissao.nome})`
    }

    if (usuario.profissao && usuario.profissao.especialidades.length > 0) {
      const especialidades = usuario.profissao.especialidades.join(', ')
      nomeDoProfissional += ` (${usuario.profissao.nome} - ${especialidades})`
    }

    return `${nomeDoProfissional}`
  }

  function abrirImprimirAtendimento() {
    setExibirImprimirAtendimento(true)
  }

  function fecharImprimirAtendimento() {
    setExibirImprimirAtendimento(false)
  }

  function abrirAdicionarInformacoes() {
    setExibirAdicionarInformacoes(true)
  }

  function fecharAdicionarInformacoes() {
    setExibirAdicionarInformacoes(false)
  }

  function abrirCancelarAtendimentoFinalizado() {
    setExibirCancelarAtendimentoFinalizado(true)
  }

  function fecharCancelarAtendimentoFinalizado() {
    setExibirCancelarAtendimentoFinalizado(false)
  }

  function abrirAssinarAtendimento() {
    setExibirFinalizarAtendimento(true)
  }

  function fecharAssinarAtendimento() {
    setExibirFinalizarAtendimento(false)
  }

  const abrirAssinaturaComCertificadoFisico = async () => {
    await assinarAtendimentoFinalizado(atendimento.paciente.identificador, atendimento)
    setExibirAssinaturaComCertificadoFisico(true)
  }

  const abrirAssinaturaComCertificadoEmNuvem = async () => {
    await assinarAtendimentoFinalizado(atendimento.paciente.identificador, atendimento)
    setExibirAssinaturaComCertificadoEmNuvem(true)
  }

  function fecharAssinaturaDigital() {
    fecharAssinarAtendimento()
    setExibirAssinaturaComCertificadoFisico(false)
    setExibirAssinaturaComCertificadoEmNuvem(false)
    listarAtendimentosFinalizados(atendimento.paciente.identificador)
  }

  const MenuAtendimento = ({ atendimento }) => {
    return (
      <div
        className={(identificadorDoAtendimento && (atendimento.identificador === identificadorDoAtendimento)) ? 'box-panel__sidebar__item is-selected' : 'box-panel__sidebar__item'}
        onClick={() => abrirAtendimento(atendimento)}
        key={`atendimento_${atendimento.identificador}`}
      >
        <div className='box-panel__sidebar__indicator'/>
        <div
          className={`evolution-info ${(atendimento.status === 'Cancelado após finalizado' || atendimento.cancelamento) && 'cancelado'}`}>
          <p className='evolution-info__data'>
            {formatarDataEHoraParaFormatoLocal(atendimento.inicio)}{' - '}{atendimento.unidade.nome}
          </p>
          <p className='evolution-info__data'>
            <strong title={`${atendimento.tipo.nome}`}>{atendimento.tipo.nome}</strong>
          </p>
          <p
            className='evolution-info__name'
            title={formatarNomeDoProfissional(atendimento.usuario)}
          >
            {formatarNomeDoProfissional(atendimento.usuario)}
          </p>
          {atendimento.precisaAssinar &&
            <i title='Atendimento não assinado' className='icon icon-attention-circled time-line-warning__bt'/>
          }
        </div>
      </div>
    )
  }

  const MenuTriagem = ({ triagem }) => {
    const triagemSelecionada = itemSelecionado && itemSelecionado.triagem && triagem.identificador === itemSelecionado.triagem.identificador

    return (
      <div
        className={triagemSelecionada ? 'box-panel__sidebar__item is-selected' : 'box-panel__sidebar__item'}
        onClick={() => selecionarTriagem(triagem)}
        key={`triagem_${triagem.identificador}`}
      >
        <div className='box-panel__sidebar__indicator'/>
        <div className={'evolution-info'}>
          <p className='evolution-info__data'>
            {formatarDataEHoraParaFormatoLocal(triagem.horario)}
          </p>
          <p className='evolution-info__data'><strong>TRIAGEM</strong></p>
          <p
            className='evolution-info__name'
            title={formatarNomeDoProfissional(triagem.profissional)}
          >
            {formatarNomeDoProfissional(triagem.profissional)}
          </p>
        </div>
        <Resultado codigo={triagem.resultado}/>
      </div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirImprimirAtendimento}
        className='modal'
        contentLabel='Modal para impressão do atendimento'
        style={{ content: { width: '400px' } }}
      >
        <Imprimir
          fechar={fecharImprimirAtendimento}
          atendimento={atendimento}
          orientacoesDePagina={orientacoesDePagina}
          tamanhosDePaginas={tamanhosDePaginas}
        />
      </Modal>
      <Modal
        isOpen={exibirAdicionarInformacoes}
        className='modal'
        contentLabel='Modal para adição de informações'
      >
        <AdicionarInformacao
          fechar={fecharAdicionarInformacoes}
          adicionar={adicionarInformacoes}
          adicionarArquivo={adicionarArquivo}
          atendimento={atendimento}
          listarAtendimentosFinalizados={listarAtendimentosFinalizados}
          abrirAssinarAtendimento={abrirAssinarAtendimento}
        />
      </Modal>
      <Modal
        isOpen={exibirCancelarAtendimentoFinalizado}
        className='modal-cancelar-atendimento'
        contentLabel='Modal para cancelamento de atendimento finalizado.'
        style={{ content: { maxWidth: '600px' } }}
      >
        <CancelarAtendimentoFinalizado
          fechar={fecharCancelarAtendimentoFinalizado}
          identificador={identificador}
          atendimento={atendimento}
          cancelar={cancelarAtendimento}
        />
      </Modal>
      <Modal
        isOpen={exibirFinalizarAtendimento}
        className='modal modal-finalizar-atendimento'
        contentLabel='Modal para finalização do atendimento'
      >
        <ResumoAtendimento
          atendimento={atendimento}
          secoes={secoes}
          assinarAtendimento={true}
          fecharModal={fecharAssinarAtendimento}
          abrirAssinaturaComCertificadoFisico={abrirAssinaturaComCertificadoFisico}
          abrirAssinaturaComCertificadoEmNuvem={abrirAssinaturaComCertificadoEmNuvem}
          recuperarCertificadosEmNuvemDoUsuario={recuperarCertificadosEmNuvemDoUsuario}
        />
      </Modal>
      <Modal
        isOpen={exibirAssinaturaComCertificadoFisico}
        className='modal'
        contentLabel='Modal para Assinatura Digital'
      >
        <AssinaturaComCertificadoFisico
          fecharModal={fecharAssinaturaDigital}
        />
      </Modal>
      <Modal
        isOpen={exibirAssinaturaComCertificadoEmNuvem}
        className='modal'
        contentLabel='Modal para Assinatura Digital em Nuvem'
      >
        <AssinaturaComCertificadoEmNuvem
          fecharModal={fecharAssinaturaDigital}
          origem='prontuario'
        />
      </Modal>
      <Spinner
        operacoes={[CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO, LISTAR_ATENDIMENTOS_FINALIZADOS, FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO, FAZER_DOWNLOAD_DO_ANEXO_DO_ATENDIMENTO, LISTAR_RESULTADOS_DAS_TRIAGENS]}>
        {({ processando }) => (
          <div className={`box-agenda ${processando && 'is-loading'}`}>
            <div className='box-panel mt-2 mb-0'>
              <div className='box-panel__sidebar'>
                <div className='box-panel__sidebar__actions'>
                  <CampoDePesquisa
                    pesquisar={alterarTermo}
                    valor={termo}
                    placeholder='Data, Tipo de Atendimento, Profissional ou Profissão'
                  />
                </div>
                <div className='box-panel__sidebar__items'>
                  {
                    itensFiltrados && itensFiltrados.map((x,index) => {
                      if (x.atendimento) {
                        return <React.Fragment key={index}><MenuAtendimento atendimento={x.atendimento}/></React.Fragment>
                      }

                      if (x.triagem) {
                        return <React.Fragment key={index}><MenuTriagem triagem={x.triagem}/></React.Fragment>
                      }

                      return null
                    })
                  }
                </div>
              </div>
              <div className='box-panel__content form'>
                {(!processando && itensFiltrados && itensFiltrados.length === 0) ?
                  <div className='box-panel__content form sem-atendimento'>
                    <div className='table-items__result mensagem'>
                      <p><strong>O paciente não possui atendimentos.</strong></p>
                    </div>
                  </div>
                  :
                  atendimento && atendimento.privado ?
                    <>
                      <fieldset>
                        <div className='table-items__result sem-atendimento'>
                          <img className='icon-atendimento-privado' src={privado} alt='privado'/>
                          <span>Este atendimento foi sinalizado como <strong>privado</strong> por <strong>{atendimento.profissional}.</strong></span><br/>
                          <span>Para visualizá-lo, favor entrar em contato com o Responsável Técnico da empresa.</span>
                        </div>
                      </fieldset>
                    </>
                    : atendimento ?
                      <>
                        <fieldset>
                          {!atendimento.cancelamento ?
                            <div className='box-panel__content__actions'>
                              {podeImprimirAtendimento && <button type='button' className='button --light' onClick={() => abrirImprimirAtendimento()}>Imprimir</button>}
                              {podeAdicionarInformacao && <button type='button' className='button --light' onClick={() => abrirAdicionarInformacoes()}>Adicionar Informação</button>}
                              {podeAssinar && atendimento.precisaAssinar && atendimento.usuario.identificador === usuario.identificador &&
                                <button type='button' className='button --primary' onClick={() => abrirAssinarAtendimento()}>Assinar</button>
                              }
                              {podeCancelarAtendimentoGeral && podeCancelarAtendimento &&
                                <button type='button' className='button --danger' onClick={() => abrirCancelarAtendimentoFinalizado()}>Cancelar Atendimento do Histórico</button>
                              }
                            </div>
                            :
                            <div className='form-choice-alerta mb-2'>
                              <div className='form-choice-alerta__item'>
                                <i className='icon icon-attention-circled'></i>
                              </div>
                              <div className='form-choice-alerta__item'>
                                <strong>ATENDIMENTO CANCELADO</strong><br/>
                                <pre><strong>Motivo: </strong>{atendimento.cancelamento.motivo}</pre>
                                <p className='m-0'>
                                  <em>{atendimento.cancelamento.usuario.nome}</em> | <em>{formatarDataEHoraParaFormatoLocal(atendimento.cancelamento.dataEHora)}</em>
                                </p>
                              </div>
                            </div>
                          }
                          <div className='evolution-info'>
                            <p className='evolution-info__data'>
                              {formatarDataEHoraParaFormatoLocal(atendimento.inicio)}{' - '}{atendimento.unidade.nome}
                              <strong title={`${atendimento.tipo.nome}`}>{atendimento.tipo.nome}</strong>
                            </p>
                            <p className='evolution-info__name'
                               title={formatarNomeDoProfissional(atendimento.usuario)}>{formatarNomeDoProfissional(atendimento.usuario)}
                            </p>
                          </div>
                        </fieldset>
                        <div className='docs-info'>
                          {atendimento && <ResumoAtendimento atendimento={atendimento} secoes={secoes}/>}
                        </div>
                      </>
                      :
                      <div>{itemSelecionado && <ResumoTriagem triagem={itemSelecionado.triagem}/>}</div>
                }
              </div>
            </div>
          </div>
        )}
      </Spinner>
    </>
  )
}

function AdicionarInformacao(props) {
  const { fechar, adicionar, atendimento, adicionarArquivo, listarAtendimentosFinalizados } = props

  const [arquivos, setArquivos] = useState([])
  const [texto, setTexto] = useState('')

  const adicionarArquivos = async evento => {
    if (!evento.target.files[0]) return

    const arquivo = evento.target.files[0]
    const arquivoAdicionado = await adicionarArquivo(arquivo, 'atendimento')

    if (arquivoAdicionado) {
      setArquivos([...arquivos, arquivoAdicionado])
    }
  }

  const confirmarERemover = arquivo => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja remover o arquivo ${arquivo.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => setArquivos(arquivos.filter(x => x.identificador !== arquivo.identificador))
      }, {
        label: 'Não'
      }]
    })
  }

  const adicionarInformacao = async formulario => {
    const dados = {
      texto: formulario.texto,
      arquivos: arquivos.map(x => x.identificador)
    }

    const adicionou = await adicionar(formulario.identificadorDoPaciente, atendimento, dados)

    if (adicionou) {
      listarAtendimentosFinalizados(formulario.identificadorDoPaciente)
      fechar()
    }
  }

  return (
    <Spinner operacoes={[ADICIONAR_INFORMACOES, ADICIONAR_ARQUIVO]}>
      <Form
        valoresIniciais={{
          identificadorDoPaciente: atendimento.paciente.identificador,
          atendimento: atendimento,
          texto: texto,
          arquivos: arquivos
        }}
        reinicializar={true}
        acao={adicionarInformacao}
        esquemaDeValidacoes={Yup.object().shape({
          texto: Yup.string().required('Obrigatório'),
        })}
      >
        <fieldset>
          <h2 className='form-title'>Informações Adicionais</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                nome='texto'
                titulo='Texto *'
                as='textarea'
                tabIndex={1}
                onChange={setTexto}
              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <div className='form-summary'>
                <div className='form-summary-files'>
                  {arquivos.length > 0 && arquivos.map((x, index) =>
                    <div className='file-upload-item' key={index}>
                      <div className='file-upload-item__name'>{x.nome}</div>
                      <button
                        className='file-upload-item__bt --remove'
                        title='Remover'
                        type='button'
                        onClick={() => confirmarERemover(x)}
                      >
                        <i className='icon icon-remove'></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className='mt-2'>
                <label
                  htmlFor='arquivosGenericos'
                  className='button --light w-sm-100'
                >
                  Adicionar Arquivos
                  <i className='icon icon-doc ml-1'></i>
                </label>
                <input
                  type='file'
                  name='arquivosGenericos'
                  id='arquivosGenericos'
                  tabIndex={10}
                  onChange={(evento) => adicionarArquivos(evento)}
                  onClick={evento => {
                    evento.target.value = null
                  }}
                />
              </div>
            </r-cell>
          </r-grid>
          <div className='list-btn mt-4'>
            <button type='button' onClick={() => fechar()} className='button --light'>Sair</button>
            <button type='submit' className='button --primary'>Salvar</button>
          </div>
        </fieldset>
      </Form>
    </Spinner>
  )
}