import React, { useEffect } from 'react'
import { useProntuario } from '../../contexto'
import { Select } from '../../../formik/formulario'
import { FieldArray } from 'formik'
import { deveSerInformado } from '../../../../bibliotecas/validacoes'
import { ehVerdadeiro, filtroPadrao } from '../../../../bibliotecas/texto'

export default function Diagnosticos({ metadados, valor, leitura, setFieldValue, validacaoAoFinalizar = false }) {
  const { cids, listarCids } = useProntuario()
  useEffect(() => { listarCids() }, [listarCids])

  const obrigatorio = ehVerdadeiro(metadados?.obrigatorio)
  const obrigatorioAoFinalizar = ehVerdadeiro(metadados?.obrigatorio_ao_finalizar)
  const rotulo = metadados?.rotulo ? metadados.rotulo : 'Diagnósticos'

  const validarCidPrincipal = cidPrincipal => {
    if (obrigatorio || valor.cidsSecundarios.length > 0) {
      return deveSerInformado(cidPrincipal)
    }
  }

  if (leitura) {
    if (valor.length === 0 && obrigatorioAoFinalizar) return <li>{rotulo} é obrigatório ao finalizar o atendimento.</li>
    if (valor.length === 0 || validacaoAoFinalizar) return null
  
    const identificadorCidPrincipal = valor.find(x => x.principal)?.cid
    const cidPrincipal = identificadorCidPrincipal ? cids.filter(x => x.identificador === identificadorCidPrincipal)[0] : ''

    const identificadoresCidsSecundarios = valor.filter(x => !x.principal).map(x => x.cid)
    const cidsSecundarios = identificadoresCidsSecundarios.length > 0 ? cids.filter(x => identificadoresCidsSecundarios.includes(x.identificador)) : []
        
    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form-group'>
          <pre className='description'>
            {cidPrincipal &&
              <>
                <strong>CID Principal: </strong>
                {cidPrincipal.codigo} - {cidPrincipal.nome}
              </>
            }
            {cidsSecundarios.length > 0 &&
              <div>
                <strong>CIDs Secundários: </strong>
                {cidsSecundarios.map(x => <div key={x.identificador}>- {x.codigo} - {x.nome}</div>)}
              </div>
            }
          </pre>
        </div>
      </r-cell>
    )
  }

  const renderizarItens = ({ value, label, csap, tags }) => {
    return (
      <div key={value}>
        <div>
          <span>{label}</span>
        </div>
        {csap &&
          <div className='segunda-linha-auto-complete-pacientes possui-medico'>
            <div className='medico-do-paciente'>
              <span>Condição Sensível à Atenção Primária</span>
            </div>
          </div>
        }
        {tags && tags.trim().length > 0 &&
          <div className='segunda-linha-auto-complete-pacientes possui-medico'>
            <div className='medico-do-paciente'>
              <span>Tags: {tags}</span>
            </div>
          </div>
        }
      </div>
    )
  }

  const optionsCid = cids.map(x => ({
    value: x.identificador,
    label: `${x.codigo} - ${x.nome}`,
    csap: x.csap,
    tags: x.tags.join(', '),
    baseDePesquisa:`${x.tags.join(' ')} ${x.codigo} - ${x.nome}`.trim()
  }))

  const removerCidsAdicionados = (cidsAdicionados = [], cids = [], cidPrincipal) => {
    const cidsSemOCidPrincipal = cids.filter(x => x.identificador !== cidPrincipal)

    if (cidsAdicionados.length === 0) {
      return cidsSemOCidPrincipal.map(x => ({
        value: x.identificador,
        label: `${x.codigo} - ${x.nome}`,
        csap: x.csap,
        tags: x.tags.join(', '),
        baseDePesquisa:`${x.tags.join(' ')} ${x.codigo} - ${x.nome}`.trim()
      }))
    }

    const filtro = cid => {
      return cidsAdicionados.findIndex(x => x === cid.identificador) === -1
    }

    return cidsSemOCidPrincipal.filter(filtro).map(x => ({
      value: x.identificador,
      label: `${x.codigo} - ${x.nome}`,
      csap: x.csap,
      tags: x.tags.join(', '),
      baseDePesquisa:`${x.tags.join(' ')} ${x.codigo} - ${x.nome}`.trim()
    }))
  }

  const adicionarCidSecundario = (cidSelecionado, setFieldValue, arrayHelpers) => {
    arrayHelpers.push(cidSelecionado)
    setFieldValue('diagnosticos.cidSecundario', '')
  }

  return (
    <r-cell span={4} span-md={6} span-lg={12}>
      <r-grid columns-md={6} columns-lg={12}>
        <r-cell span={4} span-md={6} span-lg={12}>
          <Select
            nome='diagnosticos.cidPrincipal'
            titulo={`CID Principal ${obrigatorioAoFinalizar ? '**' : (obrigatorio ? '*' : '')}`}
            options={optionsCid}
            campoCodigo='value'
            campoDescricao='label'
            validar={validarCidPrincipal}
            formatOptionLabel={renderizarItens}
            noOptionsMessage='Digite 3 caracteres para pesquisar'
            filterOption={(option, inputValue) => {
              if (inputValue.length >= 3) {
                return filtroPadrao(inputValue, option.data.baseDePesquisa)
              }
            }}
          />
        </r-cell>
        <r-cell span={4} span-md={4} span-lg={10}>
          <Select
            nome='diagnosticos.cidSecundario'
            titulo='CID Secundário'
            options={removerCidsAdicionados(valor.cidsSecundarios, cids, valor.cidPrincipal)}
            campoCodigo='value'
            campoDescricao='label'
            isDisabled={!valor?.cidPrincipal}
            formatOptionLabel={renderizarItens}
            noOptionsMessage='Digite 3 caracteres para pesquisar'
            filterOption={(option, inputValue) => {
              if (inputValue.length >= 3) {
                return filtroPadrao(inputValue, option.data.baseDePesquisa)
              }
            }}
          />
        </r-cell>
        <FieldArray
          name='diagnosticos.cidsSecundarios'
          render={arrayHelpers => (
            <>
              <r-cell span={4} span-md={2} span-lg={2}>
                <button
                  type='button'
                  className={valor?.cidSecundario ? 'button --primary w-100 minw-auto mt-24' : 'button --primary is-disabled w-100 minw-auto mt-24'}
                  onClick={() => adicionarCidSecundario(valor.cidSecundario, setFieldValue, arrayHelpers)}
                >
                  Adicionar
                </button>
              </r-cell>
              {valor.cidsSecundarios && valor.cidsSecundarios.length > 0 &&
                <r-cell span={4} span-md={12} span-lg={12}>
                  {valor.cidsSecundarios.map((key, index) => {
                    const cid = cids.find(x => x.identificador === key)
                    return cid ? 
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item --full'>
                          <div>{cid?.codigo} - {cid?.nome}</div>
                          {cid?.csap && <div className='font-italic'>Condição Sensível à Atenção Primária</div>}
                        </div>
                        <button className='form-choice__bt-close' type='button' onClick={() => arrayHelpers.remove(index)}><i className='icon icon-close'></i></button>
                      </div>
                    : null
                  })}
                </r-cell>
              }
            </>
          )}
        />
      </r-grid>
    </r-cell>
  )
}