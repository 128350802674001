import api from '../api'
import notificacao from '../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from './notificacoes'
import {
  ALTERAR_CID,
  ALTEROU_CID,
  ERRO_AO_ALTERAR_CID,
  ERRO_AO_LISTAR_CIDS,
  ERRO_AO_LISTAR_CIDS_DO_CADASTRO,
  ERRO_AO_RECUPERAR_CID_PELO_IDENTIFICADOR,
  LISTAR_CIDS,
  LISTAR_CIDS_DO_CADASTRO,
  LISTOU_CIDS,
  LISTOU_CIDS_DO_CADASTRO,
  RECUPERAR_CID_PELO_IDENTIFICADOR,
  RECUPEROU_CID_PELO_IDENTIFICADOR,
} from './tipos'

const listouCids = cids => ({
  type: LISTOU_CIDS,
  cids,
})

const erroAoListarCids = erro => ({
  type: ERRO_AO_LISTAR_CIDS,
  erro,
})

export const listarCids = () => async dispatch => {
  dispatch({ type: LISTAR_CIDS })

  try {
    const resultado = await api.listarCids()

    dispatch(listouCids(resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCids(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os CIDs.')))
  }
}

const listouCidsDoCadastro = (filtros, paginaDeDados, ordenacao) => ({
  type: LISTOU_CIDS_DO_CADASTRO,
  filtros,
  paginaDeDados,
  ordenacao
})

const erroAoListarCidsDoCadastro = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_CIDS_DO_CADASTRO,
  erro,
  parametros
})

export const listarCidsDoCadastro = (filtros, pagina, ordenacao, tamanhoDaPagina) => async dispatch => {
  dispatch({ type: LISTAR_CIDS_DO_CADASTRO })

  try {
    const resultado = await api.listarCids(filtros, pagina, ordenacao, tamanhoDaPagina)
    
    dispatch(listouCidsDoCadastro(filtros, resultado.data.dados, ordenacao))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCidsDoCadastro(erro, { filtros, pagina, ordenacao, tamanhoDaPagina}))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os CIDs.')))
  }
}

const alterouCid = cid => ({
  type: ALTEROU_CID,
  cid
})

const erroAoAlterarCid = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_CID,
  erro,
  parametros
})

export const alterarCid = cid => async dispatch => {
  dispatch({ type: ALTERAR_CID })

  try {
    const resultado = await api.alterarCid(cid)

    dispatch(alterouCid(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarCid(erro, { cid }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao salvar o CID.')))

    return false
  }
}

const recuperouCidPeloIdentificador = cid => async dispatch => {
  dispatch({ type: RECUPEROU_CID_PELO_IDENTIFICADOR, cid })
}

const erroAoRecuperarCidPeloIdentificador = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_CID_PELO_IDENTIFICADOR,
  erro,
  parametros
})

export const recuperarCidPeloIdentificador = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_CID_PELO_IDENTIFICADOR })
  try {
    const resultado = await api.recuperarCidPeloIdentificador(identificador)

    dispatch(recuperouCidPeloIdentificador(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

  } catch (erro) {
    dispatch(erroAoRecuperarCidPeloIdentificador(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Ocorreu um erro ao recuperar o CID pelo identificador.')))
  }
}