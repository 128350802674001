import React, { useState } from 'react'
import { Foto } from './foto'
import moment from 'moment'
import { formatarDataParaFormatoLocal } from '../../bibliotecas/data'
import chroma from 'chroma-js'

const Resultado = ({ resultado, descricaoDoResultado, sobrescrever, justificativaPadrao = '' }) => {
  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo) {
      case 'vermelho':
        return '#BD3035'
      case 'amarelo':
        return '#F9C72D'
      case 'verde':
        return '#70C968'
      default:
        return ''
    }
  }

  const [exibir, setExibir] = useState(false)

  const [justificativa, setJustificativa] = useState(justificativaPadrao ? justificativaPadrao : '')
  const [cor, setCor] = useState(resultado)
  const codigoDaCor = corDaUrgencia(resultado)

  const color = codigoDaCor && chroma(codigoDaCor)
  const corDaFonte = color && chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

  const dadosModificados = cor !== resultado && (justificativa !== '' && justificativa !== justificativaPadrao)

  const sair = () => {
    setExibir(false)
    setJustificativa('')
    setCor(resultado)
  }

  const alterar = () => {
    sobrescrever(justificativa, cor)
    setExibir(false)
  }

  const removerResultadoFixo = () => {
    setCor('verde')
    setJustificativa('')
    sobrescrever('', '')
    setExibir(false)
  }

  return (
    <div>
      <div
        className='resultado'
        style={{ backgroundColor: color }}
      >
        {exibir &&
          <div className='tooltip__drop custom-scrollbar'>
            <div className='tooltip__drop__body'>
              <div className='tooltip__drop__title'>
                {cor ? 'Alterar' : 'Informar'} resultado
                <button
                  className='tooltip__drop__bt-close'
                  type='button'
                  onClick={sair}>
                  <i className='icon-task icon-close'></i>
                </button>
              </div>
              <div className='tooltip__drop__description'>
                <div className='conteudo-tooltip'>
                  <div className='form-group'>
                    <label className='form-label'>Classificação</label>
                    <div className='week-options'>
                      <div
                        key='verde'
                        className={`week-options__item item-verde ${cor === 'verde' ? 'is-selected' : ''}`}
                        onClick={() => setCor('verde')}
                        style={cor === 'verde' ? { backgroundColor: '#70C968', color: 'white' } : null}
                      >
                        Verde
                      </div>
                      <div
                        key='amarelo'
                        className={`week-options__item item-amarelo ${cor === 'amarelo' ? 'is-selected' : ''}`}
                        style={cor === 'amarelo' ? { backgroundColor: '#F9C72D', color: 'black' } : null}
                        onClick={() => setCor('amarelo')}
                      >
                        Amarelo
                      </div>
                      <div
                        key='vermelho'
                        className={`week-options__item item-vermelho ${cor === 'vermelho' ? 'is-selected' : ''}`}
                        onClick={() => setCor('vermelho')}
                        style={cor === 'vermelho' ? { backgroundColor: '#BD3035', color: 'white' } : null}
                      >
                        Vermelho
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='form-label'>Justificativa do Resultado</label>
                    <textarea
                      name='justificativa'
                      value={justificativa}
                      onChange={e => setJustificativa(e.target.value)}
                    />
                  </div>
                </div>
                <div className='acoes-tooltip'>
                  <button
                    type='button'
                    onClick={removerResultadoFixo}
                    className='button --light'
                  >
                    Remover Resultado Fixo
                  </button>
                  <button
                    type='button'
                    onClick={alterar}
                    className='button --primary'
                    disabled={!dadosModificados}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
        <span style={{ color: corDaFonte }} className='descricao-da-cor'>{descricaoDoResultado}</span>
      </div>
      <button
        className='button --light btn-alterar-resultado-triagem'
        onClick={() => setExibir(true)}
      >
        Alterar Resultado
      </button>
    </div>
  )
}

const Idade = ({ data }) => {
  if (!data) {
    return null
  }

  const nascimento = moment(data)

  if (!nascimento.isValid()) {
    return ''
  }

  const agora = moment()
  const duracao = moment.duration(agora.diff(nascimento))
  const anos = Math.floor(duracao.asYears())
  const meses = Math.floor(duracao.asMonths() % 12)

  return (
    <div>
      <div>
        {anos} anos {meses} meses
      </div>
    </div>
  )
}

const Programa = ({ programas = [] }) => {
  if (programas.length === 0) {
    return <div className='sem-programa'>PACIENTE NÃO POSSUI PROGRAMA</div>
  }

  return <div className='programa'>{programas.join(', ')}</div>
}

const Sexo = ({ sexo }) => {
  if (!sexo) {
    return null
  }

  return <div>{sexo}</div>
}

const Convenio = ({ convenios = [] }) => {
  if (convenios.length === 0) {
    return <div className='sem-convenio'>PACIENTE NÃO POSSUI CONVÊNIO</div>
  }

  return <div className='convenio'>{convenios.join(', ')}</div>
}

export default function Cabecalho({ paciente, resultado, resultadosDasTriagens = [], sobrescreverResultado, justificativa, }) {
  const descricaoDoResultado = resultadosDasTriagens.find(x => x.codigo === resultado)?.nome || ''

  return (
    <div className='cabecalho-triagem w-100'>
      <Foto foto={paciente.foto}/>
      <div className='identificacao'>
        <div className='nome'>{paciente.nome}</div>
        <div>
          <div className='sexo-e-idade'>
            <Sexo sexo={paciente.sexo}/>
            <div> -</div>
            <Idade data={paciente.dataDeNascimento}/>
          </div>
          <div className='data-de-nascimento'>Data de nascimento: {formatarDataParaFormatoLocal(paciente.dataDeNascimento)}</div>
          <div className='data-de-nascimento'>Mãe: {paciente.nomeDaMae}</div>
        </div>
      </div>
      <div className='dados-de-atendimento'>
        <Convenio convenios={[paciente.convenio]}/>
        <Programa programas={paciente.programas}/>
        {paciente.medicoDeReferencia && <div className='medico'>MR: {paciente.medicoDeReferencia} </div>}
        {paciente.enfermeiroDeReferencia && <div className='enfermeiro'>ER: {paciente.enfermeiroDeReferencia} </div>}
      </div>
      <Resultado
        key={justificativa}
        resultado={resultado}
        descricaoDoResultado={descricaoDoResultado}
        sobrescrever={sobrescreverResultado}
        justificativaPadrao={justificativa}
      />
    </div>
  )
}
