export const ALTERAR_EMPRESA_DO_USUARIO = 'ALTERAR_EMPRESA_DO_USUARIO'
export const ATIVAR_UNIDADE_DA_EMPRESA = 'ATIVAR_UNIDADE_DA_EMPRESA'
export const DESATIVAR_UNIDADE_DA_EMPRESA = 'DESATIVAR_UNIDADE_DA_EMPRESA'
export const ADICIONAR_UNIDADE_DA_EMPRESA = 'ADICIONAR_UNIDADE_DA_EMPRESA'
export const ALTERAR_UNIDADE_DA_EMPRESA = 'ALTERAR_UNIDADE_DA_EMPRESA'
export const REMOVER_UNIDADE_DA_EMPRESA = 'REMOVER_UNIDADE_DA_EMPRESA'
export const LISTAR_UNIDADES_DA_EMPRESA = 'LISTAR_UNIDADES_DA_EMPRESA'
export const FAZER_DOWNLOAD_DE_ATENDIMENTOS = 'FAZER_DOWNLOAD_DE_ATENDIMENTOS'
export const FAZER_DOWNLOAD_DE_PACIENTES = 'FAZER_DOWNLOAD_DE_PACIENTES'
export const FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA = 'FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA'
export const FAZER_DOWNLOAD_DE_REINTERNACOES = 'FAZER_DOWNLOAD_DE_REINTERNACOES'
export const FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO = 'FAZER_DOWNLOAD_DE_IDAS_AO_PRONTO_ATENDIMENTO'
export const FAZER_DOWNLOAD_DE_INTERNACOES = 'FAZER_DOWNLOAD_DE_INTERNACOES'
export const GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO = 'GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO'
export const GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO = 'GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO'
export const LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE'
export const LISTAR_USUARIOS = 'LISTAR_USUARIOS'
export const ADICIONAR_USUARIO = 'ADICIONAR_USUARIO'
export const ALTERAR_OUTRO_USUARIO = 'ALTERAR_OUTRO_USUARIO'
export const ALTERAR_USUARIO = 'ALTERAR_USUARIO'
export const LISTAR_CONFIGURACOES_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_CONFIGURACOES_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const LISTAR_HORARIOS_DA_AGENDA = 'LISTAR_HORARIOS_DA_AGENDA'
export const ALTERAR_CONFIGURACAO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_CONFIGURACAO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const REMOVER_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE = 'REMOVER_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE'
export const LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE'
export const ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE = 'ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE = 'ALTERAR_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE'
export const REMOVER_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE = 'REMOVER_INSTRUCAO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE'
export const LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const HABILITAR_TIPO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE = 'HABILITAR_TIPO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE'
export const DESABILITAR_TIPO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE = 'DESABILITAR_TIPO_DE_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE'
export const LISTAR_PERFIS_DO_USUARIO = 'LISTAR_PERFIS_DO_USUARIO'
export const ADICIONAR_PERFIL_DO_USUARIO = 'ADICIONAR_PERFIL_DO_USUARIO'
export const REMOVER_PERFIL_DO_USUARIO = 'REMOVER_PERFIL_DO_USUARIO'
export const LISTAR_UNIDADES_DO_USUARIO = 'LISTAR_UNIDADES_DO_USUARIO'
export const HABILITAR_UNIDADE_DA_EMPRESA_PARA_O_USUARIO = 'HABILITAR_UNIDADE_DA_EMPRESA_PARA_O_USUARIO'
export const DESABILITAR_UNIDADE_DA_EMPRESA_PARA_O_USUARIO = 'DESABILITAR_UNIDADE_DA_EMPRESA_PARA_O_USUARIO'
export const LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO = 'LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO'
export const HABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_USUARIO = 'HABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_USUARIO'
export const DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_USUARIO = 'DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_USUARIO'
export const LISTAR_AVALIACOES_DO_NPS_RESPONDIDAS = 'LISTAR_AVALIACOES_DO_NPS_RESPONDIDAS'
export const ALTERAR_STATUS_DE_UMA_RESPOSTA_DO_NPS = 'ALTERAR_STATUS_DE_UMA_RESPOSTA_DO_NPS'
export const LISTAR_MOTIVOS_DE_INTERNACAO = 'LISTAR_MOTIVOS_DE_INTERNACAO'
export const ADICIONAR_MOTIVO_DE_INTERNACAO = 'ADICIONAR_MOTIVO_DE_INTERNACAO'
export const ALTERAR_MOTIVO_DE_INTERNACAO = 'ALTERAR_MOTIVO_DE_INTERNACAO'
export const REMOVER_MOTIVO_DE_INTERNACAO = 'REMOVER_MOTIVO_DE_INTERNACAO'
export const LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO = 'REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const LISTAR_ESTABELECIMENTOS_DE_SAUDE = 'LISTAR_ESTABELECIMENTOS_DE_SAUDE'
export const ADICIONAR_ESTABELECIMENTO_DE_SAUDE = 'ADICIONAR_ESTABELECIMENTO_DE_SAUDE'
export const ALTERAR_ESTABELECIMENTO_DE_SAUDE = 'ALTERAR_ESTABELECIMENTO_DE_SAUDE'
export const REMOVER_ESTABELECIMENTO_DE_SAUDE = 'REMOVER_ESTABELECIMENTO_DE_SAUDE'
export const LISTAR_OPERADORAS = 'LISTAR_OPERADORAS'
export const ADICIONAR_OPERADORA = 'ADICIONAR_OPERADORA'
export const ALTERAR_OPERADORA = 'ALTERAR_OPERADORA'
export const REMOVER_OPERADORA = 'REMOVER_OPERADORA'
export const ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE = 'ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE'
export const LISTAR_HORARIOS_LIVRES_DA_AGENDA = 'LISTAR_HORARIOS_LIVRES_DA_AGENDA'
export const LISTAR_RECORRENCIAS_DOS_AGENDAMENTOS = 'LISTAR_RECORRENCIAS_DOS_AGENDAMENTOS'
export const LISTAR_TIPOS_DE_AGENDAMENTO = 'LISTAR_TIPOS_DE_AGENDAMENTO'
export const ADICIONAR_TIPO_DE_AGENDAMENTO = 'ADICIONAR_TIPO_DE_AGENDAMENTO'
export const ALTERAR_TIPO_DE_AGENDAMENTO = 'ALTERAR_TIPO_DE_AGENDAMENTO'
export const REMOVER_TIPO_DE_AGENDAMENTO = 'REMOVER_TIPO_DE_AGENDAMENTO'
export const REMOVER_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'REMOVER_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const REMOVER_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE = 'REMOVER_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE'
export const LISTAR_PACIENTES = 'LISTAR_PACIENTES'
export const LISTAR_PROFISSIONAIS_DE_SAUDE = 'LISTAR_PROFISSIONAIS_DE_SAUDE'
export const LISTAR_PROFISSOES = 'LISTAR_PROFISSOES'
export const LISTAR_ESPECIALIDADES = 'LISTAR_ESPECIALIDADES'
export const ADICIONAR_PACIENTE = 'ADICIONAR_PACIENTE'
export const LISTAR_PROGRAMAS = 'LISTAR_PROGRAMAS'
export const ADICIONAR_PROGRAMA = 'ADICIONAR_PROGRAMA'
export const ALTERAR_PROGRAMA = 'ALTERAR_PROGRAMA'
export const REMOVER_PROGRAMA = 'REMOVER_PROGRAMA'
export const ALTERAR_PACIENTE = 'ALTERAR_PACIENTE'
export const LISTAR_PROGRAMAS_DO_PACIENTE = 'LISTAR_PROGRAMAS_DO_PACIENTE'
export const LISTAR_TAGS_ATIVAS_DE_PACIENTES = 'LISTAR_TAGS_ATIVAS_DE_PACIENTES'
export const LISTAR_ATENDIMENTOS_DO_PACIENTE = 'LISTAR_ATENDIMENTOS_DO_PACIENTE'
export const LISTAR_ATENDIMENTOS_INICIADOS_PELO_USUARIO = 'LISTAR_ATENDIMENTOS_INICIADOS_PELO_USUARIO'
export const ALTERAR_DADOS_DE_IDENTIFICACAO_DO_PACIENTE = 'ALTERAR_DADOS_DE_IDENTIFICACAO_DO_PACIENTE'
export const ALTERAR_PROGRAMA_DO_PACIENTE = 'ALTERAR_PROGRAMA_DO_PACIENTE'
export const REMOVER_PROGRAMA_DO_PACIENTE = 'REMOVER_PROGRAMA_DO_PACIENTE'
export const ADICIONAR_TAG_NO_PACIENTE = 'ADICIONAR_TAG_NO_PACIENTE'
export const REMOVER_TAG_DO_PACIENTE = 'REMOVER_TAG_DO_PACIENTE'
export const ADICIONAR_PROGRAMA_DO_PACIENTE = 'ADICIONAR_PROGRAMA_DO_PACIENTE'
export const LISTAR_FERIADOS = 'LISTAR_FERIADOS'
export const ADICIONAR_FERIADO = 'ADICIONAR_FERIADO'
export const ALTERAR_FERIADO = 'ALTERAR_FERIADO'
export const REMOVER_FERIADO = 'REMOVER_FERIADO'
export const LISTAR_MEDICAMENTOS = 'LISTAR_MEDICAMENTOS'
export const ADICIONAR_MEDICAMENTO = 'ADICIONAR_MEDICAMENTO'
export const ALTERAR_MEDICAMENTO = 'ALTERAR_MEDICAMENTO'
export const REMOVER_MEDICAMENTO = 'REMOVER_MEDICAMENTO'
export const LISTAR_CONCENTRACOES_DO_MEDICAMENTO = 'LISTAR_CONCENTRACOES_DO_MEDICAMENTO'
export const ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO = 'ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO'
export const ALTERAR_CONCENTRACAO_DO_MEDICAMENTO = 'ALTERAR_CONCENTRACAO_DO_MEDICAMENTO'
export const REMOVER_CONCENTRACAO_DO_MEDICAMENTO = 'REMOVER_CONCENTRACAO_DO_MEDICAMENTO'
export const LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE = 'LISTAR_AGENDAMENTOS_DE_OUTROS_PROFISSIONAIS_DE_SAUDE'
export const CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'CONFIRMAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const ALTERAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'ALTERAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const CANCELAR_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE = 'CANCELAR_AGENDAMENTOS_RECORRENTES_DO_PROFISSIONAL_DE_SAUDE'
export const CANCELAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE = 'CANCELAR_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE'
export const LISTAR_ASSOCIACOES = 'LISTAR_ASSOCIACOES'
export const ADICIONAR_ASSOCIACAO = 'ADICIONAR_ASSOCIACAO'
export const ALTERAR_ASSOCIACAO = 'ALTERAR_ASSOCIACAO'
export const REMOVER_ASSOCIACAO = 'REMOVER_ASSOCIACAO'
export const ADICIONAR_PROFISSAO = 'ADICIONAR_PROFISSAO'
export const ALTERAR_PROFISSAO = 'ALTERAR_PROFISSAO'
export const REMOVER_PROFISSAO = 'REMOVER_PROFISSAO'
export const ADICIONAR_ESPECIALIDADE = 'ADICIONAR_ESPECIALIDADE'
export const ALTERAR_ESPECIALIDADE = 'ALTERAR_ESPECIALIDADE'
export const REMOVER_ESPECIALIDADE = 'REMOVER_ESPECIALIDADE'
export const CONFIRMAR_CHEGADA_DO_PACIENTE = 'CONFIRMAR_CHEGADA_DO_PACIENTE'
export const REGISTRAR_SAIDA_DO_PACIENTE_SEM_SER_ATENDIDO = 'REGISTRAR_SAIDA_DO_PACIENTE_SEM_SER_ATENDIDO'
export const ADICIONAR_DIAGNOSTICO_DO_PACIENTE = 'ADICIONAR_DIAGNOSTICO_DO_PACIENTE'
export const CONFIRMAR_DIAGNOSTICO_DO_PACIENTE = 'CONFIRMAR_DIAGNOSTICO_DO_PACIENTE'
export const REMOVER_DIAGNOSTICO_CONFIRMADO_DO_PACIENTE = 'REMOVER_DIAGNOSTICO_CONFIRMADO_DO_PACIENTE'
export const REMOVER_DIAGNOSTICO_DO_PACIENTE = 'REMOVER_DIAGNOSTICO_DO_PACIENTE'
export const REJEITAR_DIAGNOSTICO_DO_PACIENTE = 'REJEITAR_DIAGNOSTICO_DO_PACIENTE'
export const ADICIONAR_MEDICAMENTO_DO_PACIENTE = 'ADICIONAR_MEDICAMENTO_DO_PACIENTE'
export const CONFIRMAR_MEDICAMENTO_DO_PACIENTE = 'CONFIRMAR_MEDICAMENTO_DO_PACIENTE'
export const REMOVER_MEDICAMENTO_CONFIRMADO_DO_PACIENTE = 'REMOVER_MEDICAMENTO_CONFIRMADO_DO_PACIENTE'
export const REMOVER_MEDICAMENTO_DO_PACIENTE = 'REMOVER_MEDICAMENTO_DO_PACIENTE'
export const REJEITAR_MEDICAMENTO_DO_PACIENTE = 'REJEITAR_MEDICAMENTO_DO_PACIENTE'
export const ALTERAR_DOSE_DO_MEDICAMENTO_DO_PACIENTE = 'ALTERAR_DOSE_DO_MEDICAMENTO_DO_PACIENTE'
export const LISTAR_PROFISSIONAIS_EXTERNOS = 'LISTAR_PROFISSIONAIS_EXTERNOS'
export const ADICIONAR_PROFISSIONAL_EXTERNO = 'ADICIONAR_PROFISSIONAL_EXTERNO'
export const ALTERAR_PROFISSIONAL_EXTERNO = 'ALTERAR_PROFISSIONAL_EXTERNO'
export const REMOVER_PROFISSIONAL_EXTERNO = 'REMOVER_PROFISSIONAL_EXTERNO'
export const LISTAR_ENCAMINHAMENTOS = 'LISTAR_ENCAMINHAMENTOS'
export const ALTERAR_STATUS_DO_ENCAMINHAMENTO = 'ALTERAR_STATUS_DO_ENCAMINHAMENTO'
export const LISTAR_GRUPOS_DE_CUIDADO = 'LISTAR_GRUPOS_DE_CUIDADO'
export const ADICIONAR_GRUPO_DE_CUIDADO = 'ADICIONAR_GRUPO_DE_CUIDADO'
export const ALTERAR_GRUPO_DE_CUIDADO = 'ALTERAR_GRUPO_DE_CUIDADO'
export const REMOVER_GRUPO_DE_CUIDADO = 'REMOVER_GRUPO_DE_CUIDADO'
export const ATIVAR_USUARIO = 'ATIVAR_USUARIO'
export const DESATIVAR_USUARIO = 'DESATIVAR_USUARIO'
export const ADICIONAR_INFORMACOES_NO_ATENDIMENTO = 'ADICIONAR_INFORMACOES_NO_ATENDIMENTO'
export const ADICIONAR_ARQUIVO_EM_ATENDIMENTO_FINALIZADO = 'ADICIONAR_ARQUIVO_EM_ATENDIMENTO_FINALIZADO'
export const FAZER_DOWNLOAD_DE_ARQUIVO_ADICIONAL_DO_ATENDIMENTO = 'FAZER_DOWNLOAD_DE_ARQUIVO_ADICIONAL_DO_ATENDIMENTO'
export const FAZER_DOWNLOAD_DO_PRONTUARIO_DO_PACIENTE = 'FAZER_DOWNLOAD_DO_PRONTUARIO_DO_PACIENTE'
export const LISTAR_PLANOS_DE_CUIDADO = 'LISTAR_PLANOS_DE_CUIDADO'
export const ADICIONAR_PLANO_DE_CUIDADO = 'ADICIONAR_PLANO_DE_CUIDADO'
export const ALTERAR_PLANO_DE_CUIDADO = 'ALTERAR_PLANO_DE_CUIDADO'
export const REMOVER_PLANO_DE_CUIDADO = 'REMOVER_PLANO_DE_CUIDADO'
export const LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE = 'LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE'
export const ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE = 'REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE'
export const ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE = 'ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE'
export const LISTAR_ORIENTACOES = 'LISTAR_ORIENTACOES'
export const ADICIONAR_ORIENTACAO = 'ADICIONAR_ORIENTACAO'
export const ALTERAR_ORIENTACAO = 'ALTERAR_ORIENTACAO'
export const REMOVER_ORIENTACAO = 'REMOVER_ORIENTACAO'
export const INICIAR_ATENDIMENTO = 'INICIAR_ATENDIMENTO'
export const LISTAR_RELATORIOS_DO_POWER_BI = 'LISTAR_RELATORIOS_DO_POWER_BI'
export const CANCELAR_ATENDIMENTO_INICIADO = 'CANCELAR_ATENDIMENTO_INICIADO'
export const CANCELAR_ATENDIMENTO_FINALIZADO = 'CANCELAR_ATENDIMENTO_FINALIZADO'
export const CANCELAR_ATENDIMENTO_DA_MESMA_PROFISSAO = 'CANCELAR_ATENDIMENTO_DA_MESMA_PROFISSAO'
export const CANCELAR_ATENDIMENTO_DE_OUTRA_PROFISSAO = 'CANCELAR_ATENDIMENTO_DE_OUTRA_PROFISSAO'
export const LISTAR_TOKENS_DE_TEMPLATE = 'LISTAR_TOKENS_DE_TEMPLATE'
export const LISTAR_TOKENS_DO_PACIENTE = 'LISTAR_TOKENS_DO_PACIENTE'
export const LISTAR_TEMPLATES = 'LISTAR_TEMPLATES'
export const ADICIONAR_TEMPLATE_DO_USUARIO = 'ADICIONAR_TEMPLATE_DO_USUARIO'
export const ADICIONAR_TEMPLATE_COMPARTILHADO = 'ADICIONAR_TEMPLATE_COMPARTILHADO'
export const ALTERAR_TEMPLATE_DO_USUARIO = 'ALTERAR_TEMPLATE_DO_USUARIO'
export const ALTERAR_TEMPLATE_COMPARTILHADO = 'ALTERAR_TEMPLATE_COMPARTILHADO'
export const REMOVER_TEMPLATE_DO_USUARIO = 'REMOVER_TEMPLATE_DO_USUARIO'
export const REMOVER_TEMPLATE_COMPARTILHADO = 'REMOVER_TEMPLATE_COMPARTILHADO'
export const LISTAR_EMPRESAS = 'LISTAR_EMPRESAS'
export const VISUALIZAR_RELATORIO_DO_POWER_BI = 'VISUALIZAR_RELATORIO_DO_POWER_BI'
export const VISUALIZAR_RELATORIO___VACINACAO = 'VISUALIZAR_RELATORIO___VACINACAO'
export const VISUALIZAR_RELATORIO___PACIENTES_ELEGIVEIS_DO_PLANO_DE_CUIDADO_JORNADA_DA_DOR = 'VISUALIZAR_RELATORIO___PACIENTES_ELEGIVEIS_DO_PLANO_DE_CUIDADO_JORNADA_DA_DOR'
export const VISUALIZAR_RELATORIO___ATENDIMENTOS_MENSAIS = 'VISUALIZAR_RELATORIO___ATENDIMENTOS_MENSAIS'
export const VISUALIZAR_RELATORIO___CONFIRMACOES_DE_AGENDAMENTOS = 'VISUALIZAR_RELATORIO___CONFIRMACOES_DE_AGENDAMENTOS'
export const VISUALIZAR_RELATORIO___NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO = 'VISUALIZAR_RELATORIO___NOTIFICACOES_DE_INTERNACOES_E_IDAS_AO_PRONTO_ATENDIMENTO'
export const VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA = 'VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA'
export const VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN = 'VISUALIZAR_RELATORIO___PLANO_DE_CUIDADO_DE_CONSULTA_MEDICA_UN'
export const VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_UN = 'VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_UN'
export const VISUALIZAR_RELATORIO___GESTAO_DE_ENCAMINHAMENTOS = 'VISUALIZAR_RELATORIO___GESTAO_DE_ENCAMINHAMENTOS'
export const VISUALIZAR_RELATORIO___GESTAO_DA_AGENDA = 'VISUALIZAR_RELATORIO___GESTAO_DA_AGENDA'

export const VISUALIZAR_RELATORIO___NAVEGACAO_MEDICA = 'VISUALIZAR_RELATORIO___NAVEGACAO_MEDICA'
export const VISUALIZAR_RELATORIO___INDICADORES_DE_DESFECHO_CLINICO = 'VISUALIZAR_RELATORIO___INDICADORES_DE_DESFECHO_CLINICO'
export const VISUALIZAR_RELATORIO___INDICADORES_DE_IMPACTO = 'VISUALIZAR_RELATORIO___INDICADORES_DE_IMPACTO'
export const VISUALIZAR_RELATORIO___INDICADORES_DE_MANUTENCAO_DO_CUIDADO = 'VISUALIZAR_RELATORIO___INDICADORES_DE_MANUTENCAO_DO_CUIDADO'
export const VISUALIZAR_RELATORIO___INDICADORES_DEMOGRAFICOS = 'VISUALIZAR_RELATORIO___INDICADORES_DEMOGRAFICOS'
export const VISUALIZAR_RELATORIO___INDICADORES_DO_PERFIL_DE_FRAGILIDADE = 'VISUALIZAR_RELATORIO___INDICADORES_DO_PERFIL_DE_FRAGILIDADE'
export const VISUALIZAR_RELATORIO___INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO = 'VISUALIZAR_RELATORIO___INDICADOR_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const VISUALIZAR_RELATORIO___INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR = 'VISUALIZAR_RELATORIO___INDICADOR_DE_INTERNACAO_POR_FRATURA_DE_FEMUR'
export const VISUALIZAR_RELATORIO___INDICADOR_DE_REINTERNACAO = 'VISUALIZAR_RELATORIO___INDICADOR_DE_REINTERNACAO'
export const VISUALIZAR_RELATORIO___INDICADORES_OPERACIONAIS = 'VISUALIZAR_RELATORIO___INDICADORES_OPERACIONAIS'
export const VISUALIZAR_RELATORIO___VOLUMETRIA = 'VISUALIZAR_RELATORIO___VOLUMETRIA'
export const VISUALIZAR_RELATORIO___RESOLUTIVIDADE_DO_PRONTO_CUIDAR = 'VISUALIZAR_RELATORIO___RESOLUTIVIDADE_DO_PRONTO_CUIDAR'
export const VISUALIZAR_RELATORIO___NPS = 'VISUALIZAR_RELATORIO___NPS'
export const LISTAR_TAREFAS = 'LISTAR_TAREFAS'
export const ADICIONAR_VACINA_DO_PACIENTE = 'ADICIONAR_VACINA_DO_PACIENTE'
export const ALTERAR_VACINA_DO_PACIENTE = 'ALTERAR_VACINA_DO_PACIENTE'
export const REMOVER_VACINA_DO_PACIENTE = 'REMOVER_VACINA_DO_PACIENTE'
export const LISTAR_VACINAS_DO_PACIENTE = 'LISTAR_VACINAS_DO_PACIENTE'
export const LISTAR_VACINAS = 'LISTAR_VACINAS'
export const LISTAR_LOCAIS_DA_APLICACAO = 'LISTAR_LOCAIS_DA_APLICACAO'
export const ALTERAR_SENHA = 'ALTERAR_SENHA'
export const ADICIONAR_QUEDA_DO_PACIENTE = 'ADICIONAR_QUEDA_DO_PACIENTE'
export const ALTERAR_QUEDA_DO_PACIENTE = 'ALTERAR_QUEDA_DO_PACIENTE'
export const REMOVER_QUEDA_DO_PACIENTE = 'REMOVER_QUEDA_DO_PACIENTE'
export const LISTAR_QUEDAS_DO_PACIENTE = 'LISTAR_QUEDAS_DO_PACIENTE'
export const LISTAR_LOCAIS_DE_QUEDAS = 'LISTAR_LOCAIS_DE_QUEDAS'
export const LISTAR_MICRORGANISMOS = 'LISTAR_MICRORGANISMOS'
export const LISTAR_ANTIBIOTICOS = 'LISTAR_ANTIBIOTICOS'
export const ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE = 'REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE'
export const LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE = 'LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE'
export const ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE = 'LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE'
export const LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO = 'LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO'
export const ADICIONAR_INTERNACAO_DO_PACIENTE = 'ADICIONAR_INTERNACAO_DO_PACIENTE'
export const ALTERAR_INTERNACAO_DO_PACIENTE = 'ALTERAR_INTERNACAO_DO_PACIENTE'
export const REMOVER_INTERNACAO_DO_PACIENTE = 'REMOVER_INTERNACAO_DO_PACIENTE'
export const LISTAR_INTERNACOES_DO_PACIENTE = 'LISTAR_INTERNACOES_DO_PACIENTE'
export const LISTAR_STATUS_DE_INTERNACOES = 'LISTAR_STATUS_DE_INTERNACOES'
export const LISTAR_TIPOS_DE_INTERNACAO = 'LISTAR_TIPOS_DE_INTERNACAO'
export const ADICIONAR_IVCF_DO_PACIENTE = 'ADICIONAR_IVCF_DO_PACIENTE'
export const ALTERAR_IVCF_DO_PACIENTE = 'ALTERAR_IVCF_DO_PACIENTE'
export const REMOVER_IVCF_DO_PACIENTE = 'REMOVER_IVCF_DO_PACIENTE'
export const LISTAR_IVCFS_DO_PACIENTE = 'LISTAR_IVCFS_DO_PACIENTE'
export const LISTAR_NIVEIS_DE_SAUDE_RELATIVA = 'LISTAR_NIVEIS_DE_SAUDE_RELATIVA'
export const ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE = 'LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE'
export const ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE = 'REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE'
export const LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE = 'LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE'
export const ADICIONAR_CASP_16_DO_PACIENTE = 'ADICIONAR_CASP_16_DO_PACIENTE'
export const ALTERAR_CASP_16_DO_PACIENTE = 'ALTERAR_CASP_16_DO_PACIENTE'
export const REMOVER_CASP_16_DO_PACIENTE = 'REMOVER_CASP_16_DO_PACIENTE'
export const LISTAR_CASPS_16_DO_PACIENTE = 'LISTAR_CASPS_16_DO_PACIENTE'
export const LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE = 'LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE'
export const ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE = 'REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE'
export const CONFIGURAR_NPS = 'CONFIGURAR_NPS'
export const ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE = 'ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE'
export const ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE = 'ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE'
export const REMOVER_CONSULTA_EXTERNA_DO_PACIENTE = 'REMOVER_CONSULTA_EXTERNA_DO_PACIENTE'
export const LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE = 'LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE'
export const ASSINAR_ATENDIMENTO = 'ASSINAR_ATENDIMENTO'
export const FAZER_DOWNLOAD_DOS_HORARIOS_DOS_PROFISSIONAIS_DE_SAUDE = 'FAZER_DOWNLOAD_DOS_HORARIOS_DOS_PROFISSIONAIS_DE_SAUDE'
export const ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE = 'LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE'
export const LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN = 'LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN'
export const LISTAR_USUARIOS_BLOQUEADOS = 'LISTAR_USUARIOS_BLOQUEADOS'
export const DESBLOQUEAR_LOGIN_DO_USUARIO = 'DESBLOQUEAR_LOGIN_DO_USUARIO'
export const LISTAR_CONVENIOS_DO_PACIENTE = 'LISTAR_CONVENIOS_DO_PACIENTE'
export const ALTERAR_CONVENIO_DO_PACIENTE = 'ALTERAR_CONVENIO_DO_PACIENTE'
export const REMOVER_CONVENIO_DO_PACIENTE = 'REMOVER_CONVENIO_DO_PACIENTE'
export const ADICIONAR_CONVENIO_DO_PACIENTE = 'ADICIONAR_CONVENIO_DO_PACIENTE'
export const ALTERAR_CONFIGURACAO_DO_USUARIO = 'ALTERAR_CONFIGURACAO_DO_USUARIO'
export const EXIBIR_DASHBOARD_DAS_TAREFAS = 'EXIBIR_DASHBOARD_DAS_TAREFAS'
export const EXIBIR_FLUXO_DAS_TAREFAS = 'EXIBIR_FLUXO_DAS_TAREFAS'
export const REMOVER_ESPECIALIDADE_MEDICA_INTERNA = 'REMOVER_ESPECIALIDADE_MEDICA_INTERNA'
export const ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA = 'ADICIONAR_ESPECIALIDADE_MEDICA_INTERNA'
export const IMPORTAR_PACIENTES = 'IMPORTAR_PACIENTES'
export const LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE = 'LISTAR_CAMPOS_EXTERNOS_DO_PACIENTE'
export const ALTERAR_CAMPO_EXTERNO_DO_PACIENTE = 'ALTERAR_CAMPO_EXTERNO_DO_PACIENTE'
export const REMOVER_CAMPO_EXTERNO_DO_PACIENTE = 'REMOVER_CAMPO_EXTERNO_DO_PACIENTE'
export const ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE = 'ADICIONAR_CAMPO_EXTERNO_DO_PACIENTE'
export const ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE = 'REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE'
export const LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE = 'LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE'
export const ADICIONAR_CELULA = 'ADICIONAR_CELULA'
export const ALTERAR_CELULA = 'ALTERAR_CELULA'
export const REMOVER_CELULA = 'REMOVER_CELULA'
export const LISTAR_CELULAS = 'LISTAR_CELULAS'
export const LISTAR_FABRICANTES = 'LISTAR_FABRICANTES'
export const ADICIONAR_FABRICANTE = 'ADICIONAR_FABRICANTE'
export const ALTERAR_FABRICANTE = 'ALTERAR_FABRICANTE'
export const REMOVER_FABRICANTE = 'REMOVER_FABRICANTE'
export const ADICIONAR_MINI_MENTAL = 'ADICIONAR_MINI_MENTAL'
export const ALTERAR_MINI_MENTAL = 'ALTERAR_MINI_MENTAL'
export const REMOVER_MINI_MENTAL = 'REMOVER_MINI_MENTAL'
export const LISTAR_MINI_MENTAIS = 'LISTAR_MINI_MENTAIS'
export const ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL = 'REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL'
export const VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_ENFERMAGEM = 'VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_ENFERMAGEM'
export const VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_MEDICO = 'VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_MEDICO'
export const ADICIONAR_PHQ9 = 'ADICIONAR_PHQ9'
export const ALTERAR_PHQ9 = 'ALTERAR_PHQ9'
export const REMOVER_PHQ9 = 'REMOVER_PHQ9'
export const LISTAR_PHQS9 = 'LISTAR_PHQS9'
export const ADICIONAR_SALA_NA_UNIDADE = 'ADICIONAR_SALA_NA_UNIDADE'
export const ALTERAR_SALA_DA_UNIDADE = 'ALTERAR_SALA_DA_UNIDADE'
export const REMOVER_SALA_DA_UNIDADE = 'REMOVER_SALA_DA_UNIDADE'
export const LISTAR_SALAS_DA_UNIDADE = 'LISTAR_SALAS_DA_UNIDADE'
export const LISTAR_SETORES = 'LISTAR_SETORES'
export const ADICIONAR_SETOR = 'ADICIONAR_SETOR'
export const ALTERAR_SETOR = 'ALTERAR_SETOR'
export const REMOVER_SETOR = 'REMOVER_SETOR'
export const ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL = 'REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL'
export const LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL = 'LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL'
export const LISTAR_PEDIDOS_DE_CONTATO = 'LISTAR_PEDIDOS_DE_CONTATO'
export const VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_CONTRATUAL_UN = 'VISUALIZAR_RELATORIO___PLANO_DE_CUIDADOS_CONTRATUAL_UN'
export const VISUALIZAR_RELATORIO___INDICADORES_CONTRATUAIS_UN = 'VISUALIZAR_RELATORIO___INDICADORES_CONTRATUAIS_UN'
export const ALTERAR_DADOS_DE_INATIVACAO_DO_PACIENTE = 'ALTERAR_DADOS_DE_INATIVACAO_DO_PACIENTE'
export const LISTAR_TRIAGENS = 'LISTAR_TRIAGENS'
export const REMOVER_TRIAGEM = 'REMOVER_TRIAGEM'
export const ALTERAR_TRIAGEM = 'ALTERAR_TRIAGEM'
export const ADICIONAR_TRIAGEM = 'ADICIONAR_TRIAGEM'
export const LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE = 'LISTAR_EVENTOS_DA_TIMELINE_DO_PACIENTE'
export const LISTAR_QUESTIONARIOS_DOS_IDOSOS = 'LISTAR_QUESTIONARIOS_DOS_IDOSOS'
export const ADICIONAR_QUESTIONARIO_DO_IDOSO = 'ADICIONAR_QUESTIONARIO_DO_IDOSO'
export const ALTERAR_QUESTIONARIO_DO_IDOSO = 'ALTERAR_QUESTIONARIO_DO_IDOSO'
export const REMOVER_QUESTIONARIO_DO_IDOSO = 'REMOVER_QUESTIONARIO_DO_IDOSO'
export const REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO = 'REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO'
export const REMOVER_REGISTRO_DO_ENVIO_DO_QUESTIONARIO_DO_IDOSO = 'REMOVER_REGISTRO_DO_ENVIO_DO_QUESTIONARIO_DO_IDOSO'
export const LISTAR_CIDS = 'LISTAR_CIDS'
export const ALTERAR_CID = 'ALTERAR_CID'