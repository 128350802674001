import { AUTENTICOU_USUARIO, LOGOUT, REMOVEU_UNIDADE_DO_USUARIO } from '../acoes/tipos'
import { combineReducers } from 'redux'
import agenda from './agenda/'
import agendamentos from './agendamentos/'
import antibioticos from './antibioticos'
import arquivosDaInternacao from './arquivos-da-internacao'
import arquivosDoExame from './prontuario/arquivos-de-exames'
import associacoes from './associacoes'
import atendimentosNaoAssinados from './prontuario/nao-assinados'
import celulas from './celulas'
import cids from './cids'
import duracoesDasDores from './duracoes-das-dores'
import empresa from './empresa'
import encaminhamentos from './encaminhamentos'
import erros from './erros'
import especialidadesMedicasInternas from './especialidades-medicas-internas'
import estabelecimentosDeSaude from './estabelecimentos-de-saude'
import estadosCivis from './estados-civis'
import estadosDaMemoriaAtual from './estados-da-memoria-atual'
import estadosDaMemoriaComparadoHaUmAnoAtras from './estados-da-memoria-comparado-ha-um-ano-atras'
import execucoesDoTesteDeFisioterapia from './execucoes-do-teste-de-fisioterapia'
import fabricantes from './fabricantes'
import feriados from './feriados'
import feriadosTrabalhados from './usuarios/agenda/feriados-trabalhados'
import fusosHorarios from './fusos-horarios'
import grausDeRelacoes from './graus-de-relacoes'
import gruposDeCuidado from './grupos-de-cuidado'
import imagens from './imagens'
import importacoes from './importacoes'
import indicadores from './indicadores-do-paciente'
import integracoes from './integracoes'
import intensidadesDasDores from './intensidades-das-dores'
import locaisDaAplicacao from './locais-da-aplicacao'
import locaisDasDores from './locais-das-dores'
import locaisDeQueda from './locais-de-queda'
import login from './login'
import medicamentos from './medicamentos'
import medicamentosDoProntuario from './medicamentos-do-prontuario'
import microrganismos from './microrganismos'
import modalidadesDosTiposDeAgendamento from './modalidades-dos-tipos-de-agendamento'
import motivosDeBloqueioDaAgenda from './motivos-de-bloqueio-da-agenda'
import motivosDeBloqueioDoLogin from './motivos-de-bloqueio-do-login'
import motivosDeIdaAoProntoAtendimento from './motivos-de-ida-ao-pronto-atendimento'
import motivosDeInternacao from './motivos-de-internacao'
import motivosDePrioridade from './motivos-de-prioridade'
import niveisDeSaudeRelativa from './niveis-de-saude-relativa'
import notificacoes from './notificacoes'
import nps from './nps/'
import operacoesEmAndamento from './operacoes-em-andamento'
import operadoras from './operadoras'
import orientacoes from './orientacoes'
import orientacoesDePagina from './orientacoes-de-paginas'
import pacientes from './pacientes'
import pedidosDeContato from './pedidos-de-contato';
import planosDeCuidado from './planos-de-cuidado'
import previsoesDeRetorno from './previsoes-de-retorno'
import profissionaisDeSaude from './profissionais-de-saude'
import profissionaisExternos from './profissionais-externos'
import profissoes from './profissoes'
import programas from './programas'
import prontuario from './prontuario'
import proprietariosDeTelefones from './proprietarios-de-telefones'
import relatorios from './relatorios'
import respostasDoPhq9 from './respostas-do-phq9'
import setores from './setores'
import sexos from './sexos'
import sistema from './sistema'
import sorologias from './sorologias'
import statusDeIdaAoProntoAtendimento from './status-de-ida-ao-pronto-atendimento'
import statusDeInternacoes from './status-de-internacoes'
import sugestoes from './sugestoes'
import tamanhosDePaginas from './tamanhos-de-paginas'
import tarefas from './tarefas/'
import tiposDeAgendamento from './tipos-de-agendamento'
import tiposDeAtendimento from './tipos-de-atendimento'
import tiposDeAtividadeFisicaDeLazer from './tipos-de-atividade-fisica-de-lazer'
import tiposDeConsultaExterna from './tipos-de-consulta-externa'
import tiposDeExame from './prontuario/tipos-de-exame'
import tiposDeHorarios from './usuarios/agenda/tipos-de-horarios'
import tiposDeInternacao from './tipos-de-internacao'
import tiposDeRepeticaoDeSerieDaAgenda from './tipos-de-repeticao-de-serie-da-agenda'
import tiposDeSala from './tiposDeSala'
import tiposDeUnidade from './tipos-de-unidade'
import tratamentos from './tratamentos'
import unidadesDeCalcioIonico from './unidades-de-calcio-ionico'
import unidadesFederativas from './unidades-federativas'
import usuarios from './usuarios/'
import usuariosBloqueados from './usuarios/usuarios-bloqueados'
import vacinas from './vacinas'
import triagem from './triagem'

const reducers = combineReducers({
  agenda,
  agendamentos,
  antibioticos,
  arquivosDaInternacao,
  arquivosDoExame,
  associacoes,
  atendimentosNaoAssinados,
  celulas,
  cids,
  duracoesDasDores,
  empresa,
  encaminhamentos,
  erros,
  especialidadesMedicasInternas,
  estabelecimentosDeSaude,
  estadosCivis,
  estadosDaMemoriaAtual,
  estadosDaMemoriaComparadoHaUmAnoAtras,
  execucoesDoTesteDeFisioterapia,
  fabricantes,
  feriados,
  feriadosTrabalhados,
  fusosHorarios,
  grausDeRelacoes,
  gruposDeCuidado,
  imagens,
  importacoes,
  indicadores,
  integracoes,
  intensidadesDasDores,
  locaisDaAplicacao,
  locaisDasDores,
  locaisDeQueda,
  login,
  medicamentos,
  medicamentosDoProntuario,
  microrganismos,
  modalidadesDosTiposDeAgendamento,
  motivosDeBloqueioDaAgenda,
  motivosDeBloqueioDoLogin,
  motivosDeIdaAoProntoAtendimento,
  motivosDeInternacao,
  motivosDePrioridade,
  niveisDeSaudeRelativa,
  notificacoes,
  nps,
  operacoesEmAndamento,
  operadoras,
  orientacoes,
  orientacoesDePagina,
  pacientes,
  pedidosDeContato,
  planosDeCuidado,
  previsoesDeRetorno,
  profissionaisDeSaude,
  profissionaisExternos,
  profissoes,
  programas,
  prontuario,
  proprietariosDeTelefones,
  relatorios,
  respostasDoPhq9,
  setores,
  sexos,
  sistema,
  sorologias,
  statusDeIdaAoProntoAtendimento,
  statusDeInternacoes,
  sugestoes,
  tamanhosDePaginas,
  tarefas,
  tiposDeAtendimento,
  tiposDeAgendamento,
  tiposDeAtividadeFisicaDeLazer,
  tiposDeConsultaExterna,
  tiposDeExame,
  tiposDeHorarios,
  tiposDeInternacao,
  tiposDeRepeticaoDeSerieDaAgenda,
  tiposDeSala,
  tiposDeUnidade,
  tratamentos,
  unidadesDeCalcioIonico,
  unidadesFederativas,
  usuarios,
  usuariosBloqueados,
  vacinas,
  triagem,
})

const reducerRaiz = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  if (action.type === AUTENTICOU_USUARIO) {
    state = {
      empresa: { ...state.empresa },
      login: { ...state.login },
      sistema: { ...state.sistema },
      imagens: { ...state.imagens }
    }
  }

  if (action.type === REMOVEU_UNIDADE_DO_USUARIO) {
    if (state.login.logado && action.identificadorDoUsuario === state.login.logado.identificador) {
      state = {
        ...state,
        agenda: {
          ...state.agenda,
          unidadesFiltradas: state.agenda.unidadesFiltradas.filter(x => x !== action.identificadorDaUnidade)
        }
      }
    }
  }

  return reducers(state, action)
}

export default reducerRaiz