import React from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

export default function Breadcrumb() {
  const location = useLocation()
  const match = useRouteMatch()
  const urls = recuperarPartesDaUrl(location.pathname)
  const identificador = match.params.identificador
  const identificadorDoAtendimento = match.params.identificadorDoAtendimento
  const prontuario = location.pathname.includes('/prontuario')
  const identificadorDoTipoDeTarefa = match.params.identificadorDoTipoDeTarefa
  const identificadorDaSala = match.params.identificadorDaSala

  const rotas = {
    '/': 'Principal ',
    [`/atendimentos/sala-de-espera`]: '/ Sala de Espera ',
    [`/atendimentos/nao-assinados`]: '/ Assinaturas Pendentes',
    [`/configuracoes/alterar-senha`]: '/ Alterar Senha ',
    [`/configuracoes/empresa`]: '/ Empresa ',
    [`/configuracoes/empresa/unidades`]: '/ Unidades ',
    [`/configuracoes/empresa/unidades/lista`]: '/ Lista ',
    [`/configuracoes/empresa/unidades/${identificador}`]: '/ Editar ',
    [`/configuracoes/empresa/unidades/nova`]: '/ Nova ',
    [`/configuracoes/empresa/unidades/${identificador}/salas`]: '/ Salas ',
    [`/configuracoes/empresa/unidades/${identificador}/salas/nova`]: '/ Nova ',
    [`/configuracoes/empresa/unidades/${identificador}/salas/lista`]: '/ Lista ',
    [`/configuracoes/empresa/unidades/${identificador}/salas/${identificadorDaSala}`]: '/ Editar Sala ',
    [`/configuracoes/medicamentos`]: '/ Medicamentos ',
    [`/configuracoes/medicamentos/lista`]: '/ Lista ',
    [`/configuracoes/medicamentos/novo`]: '/ Novo ',
    [`/agendamentos/tipos-de-agendamento`]: '/ Tipos de Agendamento',
    [`/agendamentos/tipos-de-agendamento/lista`]: '/ Lista ',
    [`/agendamentos/tipos-de-agendamento/novo`]: '/ Novo ',
    [`/agendamentos/tipos-de-agendamento/${identificador}`]: '/ Editar ',
    [`/agendamentos/tipos-de-agendamento/${identificador}/tipos-de-horario`]: '/ Tipos de Horário ',
    [`/configuracoes/usuarios`]: '/ Usuários ',
    [`/configuracoes/usuarios/lista`]: '/ Lista ',
    [`/configuracoes/usuarios/novo`]: '/ Novo ',
    [`/configuracoes/usuarios/${identificador}`]: '/ Editar ',
    [`/configuracoes/usuarios/${identificador}/agenda`]: '/ Agenda ',
    [`/configuracoes/usuarios/${identificador}/agenda/bloqueios`]: '/ Bloqueios ',
    [`/configuracoes/usuarios/${identificador}/agenda/feriados-trabalhados`]: '/ Feriados Trabalhados ',
    [`/configuracoes/usuarios/${identificador}/agenda/instrucoes`]: '/ Instruções ',
    [`/configuracoes/usuarios/${identificador}/agenda/tipos-de-agendamento`]: '/ Tipos de Agendamento ',
    [`/configuracoes/usuarios/${identificador}/unidades`]: '/ Unidades ',
    [`/configuracoes/usuarios/${identificador}/perfil`]: '/ Perfil ',
    [`/configuracoes/usuarios/${identificador}/tipos-de-atendimento`]: '/ Tipos de Atendimento ',
    [`/configuracoes/usuarios/${identificador}/configuracoes`]: '/ Configurações ',
    [`/configuracoes/usuarios-bloqueados`]: '/ Usuários Bloqueados',
    [`/configuracoes/usuarios-bloqueados/lista`]: '/ Lista ',
    [`/configuracoes/importacao-de-pacientes`]: '/ Importação de Pacientes',
    [`/configuracoes/feriados`]: '/ Feriados ',
    [`/configuracoes/feriados/lista`]: '/ Lista ',
    [`/configuracoes/feriados/${identificador}`]: '/ Editar ',
    [`/configuracoes/feriados/novo`]: '/ Novo ',
    [`/configuracoes/templates`]: '/ Templates ',
    [`/configuracoes/templates/lista`]: '/ Lista ',
    [`/configuracoes/templates/${identificador}`]: '/ Editar ',
    [`/configuracoes/templates/novo`]: '/ Novo ',
    [`/configuracoes/nps`]: '/ NPS ',
    [`/configuracoes/nps/tipos-de-atendimento`]: '/ Tipos de Atendimento ',
    [`/configuracoes/nps/profissoes`]: '/ Profissões ',
    [`/configuracoes/setores`]: '/ Setores ',
    [`/configuracoes/setores/lista`]: '/ Lista ',
    [`/configuracoes/setores/novo`]: '/ Novo ',
    [`/configuracoes/setores/${identificador}`]: '/ Editar ',
    [`/configuracoes/pedidos-de-contato`]: '/ Pedidos de Contato ',
    [`/configuracoes/pedidos-de-contato/lista`]: '/ Lista ',
    [`/configuracoes/nps/respostas`]: '/ Respostas ',
    [`/configuracoes/nps/respostas/${identificador}`]: '/ Editar ',
    [`/relatorios/dashboards`]: '/ Dashboards ',
    [`/relatorios/dashboards/menu/indicadores`]: '/ Indicadores de Impacto',
    [`/relatorios/dashboards/menu/indicadores-estrategicos`]: '/ Indicadores Estratégicos ',
    [`/relatorios/dashboards/menu/planos-de-cuidados-un`]: '/ Planos de Cuidados ',
    [`/relatorios/dashboards/atendimentos-mensais`]: '/ Atendimentos Mensais ',
    [`/relatorios/dashboards/confirmacoes-de-agendamentos`]: '/ Confirmações de Agendamentos ',
    [`/relatorios/dashboards/gestao-de-encaminhamentos`]: '/ Gestão de Encaminhamentos ',
    [`/relatorios/dashboards/gestao-da-agenda`]: '/ Gestão da Agenda ',
    [`/relatorios/dashboards/indicador-de-ida-ao-pronto-atendimento`]: '/ Indicador de Ida ao Pronto Atendimento',
    [`/relatorios/dashboards/indicador-de-internacao-por-fratura-de-femur`]: '/ Indicador de Internação por Fratura de Fêmur',
    [`/relatorios/dashboards/indicador-de-reinternacao`]: '/ Indicador de Reinternação',
    [`/relatorios/dashboards/indicador-de-internacao-clinica`]: '/ Indicador de Internação Clínica',
    [`/relatorios/dashboards/indicadores-contratuais-un`]: '/ Indicadores Contratuais UN',
    [`/relatorios/dashboards/indicadores-de-desfecho-clinico`]: '/ Indicadores de Desfecho Clínico',
    [`/relatorios/dashboards/indicadores-de-manutencao-do-cuidado`]: '/ Indicadores de Manutenção do Cuidado',
    [`/relatorios/dashboards/indicadores-demograficos`]: '/ Indicadores Demográficos',
    [`/relatorios/dashboards/indicadores-do-perfil-de-fragilidade`]: '/ Indicadores do Perfil de Fragilidade',
    [`/relatorios/dashboards/indicadores-operacionais`]: '/ Indicadores Operacionais',
    [`/relatorios/dashboards/navegacao-medica`]: '/ Navegação Médica ',
    [`/relatorios/dashboards/notificacoes-de-internacoes-e-idas-ao-pronto-atendimento`]: '/ Notificações de Internações e Idas ao Pronto Atendimento ',
    [`/relatorios/dashboards/net-promoter-score`]: '/ Net Promoter Score ',
    [`/relatorios/dashboards/pacientes-dos-programas`]: '/ Pacientes dos Programas ',
    [`/relatorios/dashboards/plano-de-cuidados-de-consultas-medicas`]: '/ Plano de Cuidados de Consultas Médicas',
    [`/relatorios/dashboards/plano-de-cuidados-de-consultas-medicas-un`]: '/ Plano de Cuidados de Consultas Médicas',
    [`/relatorios/dashboards/plano-de-cuidados-medico`]: '/ Plano de Cuidados Médico',
    [`/relatorios/dashboards/plano-de-cuidados-contratual-un`]: '/ Plano de Cuidados Contratual UN',
    [`/relatorios/dashboards/plano-de-cuidados-enfermagem`]: '/ Plano de Cuidados Enfermagem',
    [`/relatorios/dashboards/plano-de-cuidados`]: '/ Plano de Cuidados',
    [`/relatorios/dashboards/resolutividade-do-pronto-cuidar`]: '/ Resolutividade do Pronto Cuidar',
    [`/relatorios/dashboards/vacinacao`]: '/ Vacinação ',
    [`/relatorios/dashboards/volumetria`]: '/ Volumetria ',
    [`/relatorios/atendimentos`]: '/ Atendimentos ',
    [`/relatorios/idas-ao-pronto-atendimento`]: '/ Idas ao Pronto Atendimento ',
    [`/relatorios/internacoes`]: '/ Internações ',
    [`/relatorios/pacientes`]: '/ Pacientes ',
    [`/relatorios/pacientes-do-programa`]: '/ Pacientes do Programa ',
    [`/relatorios/passometro`]: '/ Passômetro ',
    [`/relatorios/reinternacoes`]: '/ Reinternações ',
    [`/relatorios/resumo-de-internacoes`]: '/ Resumo de Internações ',
    [`/relatorios/horas-dos-profissionais`]: '/ Horas dos Profissionais ',
    [`/agendamentos/agenda`]: '/ Agenda do Profissional ',
    [`/agendamentos/confirmar-agendamento`]: '/ Confirmar Agendamento ',
    [`/agendamentos/pesquisa-de-agendamentos`]: '/ Pesquisa de Agendamentos ',
    [`/agendamentos/informar-chegada-do-paciente`]: '/ Informar Chegada Do Paciente ',
    [`/agendamentos/novo-agendamento/passo-1`]: '/ Novo Agendamento (Wizard)',
    [`/agendamentos/novo-agendamento/passo-2`]: '/ Novo Agendamento (Wizard)',
    [`/agendamentos/novo-agendamento/passo-3`]: '/ Novo Agendamento (Wizard)',
    [`/cadastros/pacientes`]: '/ Pacientes ',
    [`/cadastros/pacientes/lista`]: '/ Lista ',
    [`/cadastros/pacientes/novo`]: '/ Novo ',
    [`/cadastros/pacientes/${identificador}`]: !prontuario && '/ Editar ',
    [`/cadastros/pacientes/${identificador}/convenios`]: '/ Convênios ',
    [`/cadastros/pacientes/${identificador}/convenios/novo`]: '/ Novo ',
    [`/cadastros/pacientes/${identificador}/convenios/${match.params.identificadorDoConvenio}`]: '/ Editar Convênio ',
    [`/cadastros/pacientes/${identificador}/programas`]: '/ Programas ',
    [`/cadastros/pacientes/${identificador}/programas/novo`]: '/ Novo ',
    [`/cadastros/pacientes/${identificador}/programas/${match.params.identificadorDoPrograma}`]: '/ Editar Programa ',
    [`/cadastros/pacientes/${identificador}/planos-de-cuidado`]: '/ Planos de Cuidado ',
    [`/cadastros/pacientes/${identificador}/planos-de-cuidado/novo`]: '/ Novo ',
    [`/cadastros/pacientes/${identificador}/planos-de-cuidado/${match.params.identificadorDoPlanoDeCuidado}`]: '/ Editar Plano de Cuidado ',
    [`/cadastros/pacientes/${identificador}/tags`]: '/ Tags ',
    [`/cadastros/pacientes/${identificador}/campos-externos`]: '/ Campos Externos ',
    [`/cadastros/pacientes/${identificador}/campos-externos/novo`]: '/ Novo ',
    [`/cadastros/pacientes/${identificador}/campos-externos/${match.params.identificadorDoCampoExterno}`]: '/ Editar Campo Externo ',
    [`/cadastros/pacientes/${identificador}/prontuario`]: '/ Prontuário ',
    [`/cadastros/pacientes/${identificador}/prontuario/${identificadorDoAtendimento}`]: '/ Atendimento ',
    [`/cadastros/medicamentos`]: '/ Medicamentos ',
    [`/cadastros/medicamentos/lista`]: '/ Lista ',
    [`/cadastros/medicamentos/${identificador}`]: '/ Editar ',
    [`/cadastros/medicamentos/${identificador}/concentracoes`]: '/ Concentrações ',
    [`/cadastros/medicamentos/novo`]: '/ Novo ',
    [`/cadastros/motivos-de-internacao`]: '/ Motivos de Internação ',
    [`/cadastros/motivos-de-internacao/lista`]: '/ Lista ',
    [`/cadastros/motivos-de-internacao/${identificador}`]: '/ Editar ',
    [`/cadastros/motivos-de-internacao/novo`]: '/ Novo ',
    [`/cadastros/motivos-de-ida-ao-pronto-atendimento`]: '/ Motivos de Ida Ao Pronto Atendimento ',
    [`/cadastros/motivos-de-ida-ao-pronto-atendimento/lista`]: '/ Lista ',
    [`/cadastros/motivos-de-ida-ao-pronto-atendimento/${identificador}`]: '/ Editar ',
    [`/cadastros/motivos-de-ida-ao-pronto-atendimento/novo`]: '/ Novo ',
    [`/cadastros/estabelecimentos-de-saude`]: '/ Estabelecimentos de Saúde ',
    [`/cadastros/estabelecimentos-de-saude/lista`]: '/ Lista ',
    [`/cadastros/estabelecimentos-de-saude/novo`]: '/ Novo ',
    [`/cadastros/estabelecimentos-de-saude/${identificador}`]: '/ Editar ',
    [`/cadastros/operadoras`]: '/ Operadoras ',
    [`/cadastros/operadoras/lista`]: '/ Lista ',
    [`/cadastros/operadoras/novo`]: '/ Novo ',
    [`/cadastros/operadoras/${identificador}`]: '/ Editar ',
    [`/cadastros/programas`]: '/ Programas ',
    [`/cadastros/programas/lista`]: '/ Lista ',
    [`/cadastros/programas/novo`]: '/ Novo ',
    [`/cadastros/programas/${identificador}`]: '/ Editar ',
    [`/cadastros/associacoes`]: '/ Associações ',
    [`/cadastros/associacoes/lista`]: '/ Lista ',
    [`/cadastros/associacoes/novo`]: '/ Novo ',
    [`/cadastros/associacoes/${identificador}`]: '/ Editar ',
    [`/cadastros/profissoes`]: '/ Profissões ',
    [`/cadastros/profissoes/lista`]: '/ Lista ',
    [`/cadastros/profissoes/novo`]: '/ Novo ',
    [`/cadastros/profissoes/${identificador}`]: '/ Editar ',
    [`/cadastros/profissoes/${identificador}/especialidades`]: '/ Especialidades ',
    [`/cadastros/profissionais-externos`]: '/ Profissionais Externos ',
    [`/cadastros/profissionais-externos/lista`]: '/ Lista ',
    [`/cadastros/profissionais-externos/novo`]: '/ Novo ',
    [`/cadastros/profissionais-externos/${identificador}`]: '/ Editar ',
    [`/cadastros/grupos-de-cuidado`]: '/ Grupos de Cuidado ',
    [`/cadastros/grupos-de-cuidado/lista`]: '/ Lista ',
    [`/cadastros/grupos-de-cuidado/novo`]: '/ Novo ',
    [`/cadastros/grupos-de-cuidado/${identificador}`]: '/ Editar ',
    [`/cadastros/planos-de-cuidado`]: '/ Planos de Cuidado ',
    [`/cadastros/planos-de-cuidado/lista`]: '/ Lista ',
    [`/cadastros/planos-de-cuidado/novo`]: '/ Novo ',
    [`/cadastros/planos-de-cuidado/${identificador}`]: '/ Editar ',
    [`/cadastros/orientacoes`]: '/ Orientações ',
    [`/cadastros/orientacoes/lista`]: '/ Lista ',
    [`/cadastros/orientacoes/novo`]: '/ Novo ',
    [`/cadastros/orientacoes/${identificador}`]: '/ Editar ',
    [`/cadastros/celulas`]: '/ Celulas ',
    [`/cadastros/celulas/lista`]: '/ Lista ',
    [`/cadastros/celulas/novo`]: '/ Novo ',
    [`/cadastros/celulas/${identificador}`]: '/ Editar ',
    [`/cadastros/fabricantes`]: '/ Fabricantes ',
    [`/cadastros/fabricantes/lista`]: '/ Lista ',
    [`/cadastros/fabricantes/novo`]: '/ Novo ',
    [`/cadastros/fabricantes/${identificador}`]: '/ Editar ',
    [`/encaminhamentos`]: '/ Encaminhamentos ',
    [`/encaminhamentos/lista`]: '/ Lista ',
    [`/novo-agendamento/passo-1`]: '/ Novo Agendamento ',
    [`/novo-agendamento/passo-2`]: '/ Novo Agendamento ',
    [`/novo-agendamento/passo-3`]: '/ Novo Agendamento ',
    [`/cadastros/cids`]: '/ CIDs ',
    [`/cadastros/cids/lista`]: '/ Lista ',
    [`/cadastros/cids/${identificador}`]: '/ Editar ',
    [`/tarefas`]: '/ Tarefas ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/minhas`]: '/ Minhas ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/minha-carteira`]: '/ Minha Carteira ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/criadas-por-mim`]: '/ Criadas por Mim ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/todas`]: '/ Todas ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/dashboard`]: '/ Dashboard ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/fluxo`]: '/ Fluxo ',
    [`/tarefas/${identificadorDoTipoDeTarefa}/tarefa/${identificador}`]: '/ Tarefa ',
  }

  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb'>
        {urls.map((url, index) => {
          if (!rotas[url]) return null

          return (
            <li key={url} className='breadcrumb-item active'>
              <Link
                to={url}
                className={(index + 1) === urls.length ? ' active' : ''}
              >
                {rotas[url]}
              </Link>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

const recuperarPartesDaUrl = url => {
  const urls = url.split('/').filter(x => x)
  let partesDaUrl = ['/']

  urls.forEach((_, index) => {
    const url = `/${urls.slice(0, index + 1).join('/')}`
    partesDaUrl.push(url)
  })

  return partesDaUrl
}