import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useProntuario } from '../../contexto'
import { AgrupamentoDeColunas, Coluna, LinhaExpandida, Tabela } from '../../../tabela'
import Spinner from '../../../spinner'
import * as permissoes from '../../../seguranca/permissoes'
import { REMOVER_TRIAGEM } from '../../../seguranca/permissoes'
import Permissao from '../../../seguranca/permissao'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import chroma from 'chroma-js'
import Triagem from '../../../triagem/com-redux'

import { LISTAR_RESULTADOS_DAS_TRIAGENS, LISTAR_TRIAGENS_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Triagens({ triagens, paciente }) {
  const {
    removerTriagem,
    listarTriagensDoPaciente,
    listarResultadosDasTriagens,
    resultadosDasTriagens = []
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const abrirFormulario = () => {
    setExibirFormulario(true)
  }

  const fecharFormulario = () => {
    setExibirFormulario(false)
    setSelecionado(null)
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const identificadorDoPaciente = paciente.identificador

  useEffect(() => {
    if (resultadosDasTriagens.length === 0) {
      listarResultadosDasTriagens()
    }
  }, [listarResultadosDasTriagens, resultadosDasTriagens])

  useEffect(() => {
    if (triagens === undefined) {
      listarTriagensDoPaciente(identificadorDoPaciente)
    }
  }, [listarTriagensDoPaciente, identificadorDoPaciente, triagens])

  const alterarTriagem = item => {
    setSelecionado({
      ...item,
      paciente: paciente
    })

    abrirFormulario()
  }

  const corDaUrgencia = codigo => {
    if (!codigo) return ''

    switch (codigo) {
      case 'vermelho':
        return '#BD3035'
      case 'amarelo':
        return '#F9C72D'
      case 'verde':
        return '#70C968'
      default:
        return ''
    }
  }

  const Resultado = ({ codigo }) => {
    const resultado = resultadosDasTriagens.find(x => x.codigo === codigo)

    if (!resultado) {
      return null
    }

    const cor = corDaUrgencia(resultado.codigo)
    const color = cor && chroma(cor)
    const corDaFonte = color && chroma.contrast(color, 'white') > 2 ? 'white' : 'black'

    return (
      <span
        className={`table-items__bcolor-card-sidebar card-tipo`}
        style={{background: cor, color: corDaFonte}}
      >
          {resultado.nome}
      </span>
    )
  }

  if (triagens === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção da Triagem'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionado}
          remover={identificadorDaTriagem => removerTriagem(identificadorDoPaciente, identificadorDaTriagem)}
          fechar={fecharExcluir}
        />
      </Modal>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        style={{
          content: {
            overflowY: 'scroll',
            maxHeight: '95%',
            width: '1100px',
            backgroundColor: '#F1F3F4',
          },
        }}
        contentLabel='Modal do formulário de triagens'
      >
        <Triagem
          paciente={paciente}
          triagem={selecionado}
          cancelar={fecharFormulario}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_TRIAGENS_DO_PACIENTE, LISTAR_RESULTADOS_DAS_TRIAGENS]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_TRIAGEM]}>
                    <button
                      className='table-items-expanded__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_TRIAGEM]}>
                    <div key='editar'>
                      <button
                        type='button'
                        onClick={() => alterarTriagem(item)}
                        className='table-items-expanded__bt --edit'
                        title='Editar'
                      >
                        <i className='icon icon-pencil'></i>
                      </button>
                    </div>
                  </Permissao>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={triagens}
              exibirBotaoAdicionar={true}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros de triagem.'
              classNameAdicionar='col-xs-6 col-lg-1'
              expandida={true}
            >
              <Coluna
                className='col-xs-3 col-lg-2'
                nome='Data e Hora'
                principal={true}
                exibirTitulo={true}
                renderizar={item => formatarDataEHoraParaFormatoLocal(item.horario)}
              />
              <Coluna
                className='col-lg-3'
                nome='Profissional Responsável'
                renderizar={item => item.profissional.nome}
              />
              <Coluna
                className='col-lg-1'
                nome='Resultado'
                renderizar={item => <Resultado codigo={item.resultado} />}
              />
              <Coluna
                className='col-lg-5'
                nome='Justificativa'
                campoDeDados='justificativa'
              />
              <LinhaExpandida className='col-xs-10 col-lg-11'>
                <AgrupamentoDeColunas>
                  <Coluna
                    icone='icon-date'
                    nome='Data e Hora'
                    renderizar={item => formatarDataEHoraParaFormatoLocal(item.horario)}
                  />
                  <Coluna
                    icone='icon-card'
                    nome='Profissional Responsável'
                    renderizar={item => item.profissional.nome}
                  />
                  <Coluna
                    icone='icon-card'
                    nome='Justificativa'
                    campoDeDados='justificativa'
                  />
                  <Coluna
                    icone='icon-card'
                    nome='Resultado'
                    campoDeDados='resultado'
                  />
                </AgrupamentoDeColunas>
              </LinhaExpandida>
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirTriagem = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_TRIAGEM]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a triagem?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirTriagem}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}
