import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Input } from '../../../formik/formulario'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { formatarDataParaFormatoLocal, formatarHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import { ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function RemoverMedicamento({
  alterarStatusDoMedicamentoDoPaciente,
  fechar,
  identificadorDoPaciente,
  medicamento: {
    identificador,
    medicamento,
    concentracao,
    outroMedicamentoEConcentracao,
    frequencia,
    dataEHora,
    usuario,
    observacoes
  }
}) {

  const podeRemover = usePossuiAsPermissoes([permissoes.REMOVER_MEDICAMENTO_DO_PACIENTE])

  const removerMedicamentoDoPaciente = async formulario => {
    const dados = {
      ...formulario,
      operacao: "remover"
    }

    const alterou = await alterarStatusDoMedicamentoDoPaciente(identificadorDoPaciente, identificador, dados)

    if (alterou) {
      fechar()
    }
  }  

  const retornarDadosDoProfissional = (usuario, profissao) => {
    let texto = " "
 
    if (usuario.tratamento) {
      texto += usuario.tratamento
      texto += " "
    }

    texto += usuario.nome

    if (profissao) {
      texto += " | "
      texto += profissao.nome
    }

    return texto
  }

  return (
    <Spinner operacoes={[ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          observacoes: ''
        }}
        acao={removerMedicamentoDoPaciente}
      >
        {({ values }) => (
          <fieldset>
            <h2 className='form-title'>Detalhes do Medicamento</h2>
            <div className='form-details'>
              <p><strong>Medicamento/Dosagem:</strong>{' '}{medicamento ? `${medicamento.nome} - ${concentracao.valor}` : outroMedicamentoEConcentracao}</p>
              <p><strong>Posologia:</strong>{' '}{frequencia}</p>
              <p><strong>Usuário:</strong>{retornarDadosDoProfissional(usuario, usuario.profissao)}</p>
              <p><strong>Data:</strong>{' '}{formatarDataParaFormatoLocal(dataEHora)}</p>
              <p><strong>Hora:</strong>{' '}{formatarHoraParaFormatoLocal(dataEHora)}</p>
              {observacoes && <p><strong>Observações:</strong>{' '}{observacoes}</p>}
            </div>
            <r-grid columns-md={6} columns-lg={12} class='mt-3'>
              <r-cell span={4} span-md={6} span-lg={12}>
                <Input
                  as='textarea'
                  tabIndex={1}
                  nome='observacoes'
                  titulo='Observações'
                />
              </r-cell>
            </r-grid>
            <div className='list-btn'>
              <button type='button' className='button --light minw-auto' onClick={() => fechar()}>Fechar</button>
              {podeRemover && <button type='submit' className='button --danger minw-auto'>Remover</button>}
            </div>
          </fieldset>
        )}
      </FormularioDoFormik>
    </Spinner>
  )
}