import React, { useEffect } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import Spinner from '../spinner'
import Breadcrumb from '../breadcrumb'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Formulario as FormularioDoFormik, Input, Toglle } from '../formik/formulario'

import { ALTERAR_CID, LISTAR_CIDS, RECUPERAR_CID_PELO_IDENTIFICADOR } from '../../acoes/tipos'

export default function Formulario(props) {
  const {
    match,
    history,
    cid,
    salvar,
    recuperarPeloIdentificador,
  } = props

  const { identificador } = match.params
  const podeEditar = usePossuiAsPermissoes([permissoes.ALTERAR_CID])

  useEffect(() => {
    if (identificador) {
      recuperarPeloIdentificador(identificador)
    }
  }, [identificador, recuperarPeloIdentificador])

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }

  const removerTag = (index, setFieldValue, tagsAdicionadas = []) => {
    const tag = tagsAdicionadas[index]
    setFieldValue('tags', tagsAdicionadas.filter(x => x !== tag))
  }

  const adicionarTag = (tag, tagsAdicionadas = [], setFieldValue, setFieldError) => {
    if (tag.text.length < 2) {
      setFieldError('tags', 'A tag deve ter no mínimo 2 caracteres.')
    } else {
      setFieldValue('tags', [...tagsAdicionadas, tag.text])
    }
  }

  return (
    <div className='container'>
      <div className='main-content-form'>
        <div className='header-page-form'>
          <Breadcrumb />
        </div>
        <Spinner operacoes={[RECUPERAR_CID_PELO_IDENTIFICADOR, ALTERAR_CID, LISTAR_CIDS]}>
          <FormularioDoFormik
            reinicializar={true}
            valoresIniciais={{
              identificador: cid.identificador,
              codigo: cid.codigo,
              nome: cid.nome,
              csap: cid.csap,
              habilitadoParaDiagnosticos: cid.habilitadoParaDiagnosticos,
              habilitadoParaInternacoes: cid.habilitadoParaInternacoes,
              habilitadoParaIdasAoProntoAtendimento: cid.habilitadoParaIdasAoProntoAtendimento,
              tags: cid.tags,
              observacoes: cid.observacoes || '',
            }}
            acao={salvar}
          >
            {({ setFieldValue, values, setFieldError, errors }) => (
              <>
                <fieldset>
                  <h2 className='form-title'>Alterar CID</h2>
                  <r-grid columns-md={6} columns-lg={12}>
                    <r-cell span={4} span-md={2} span-lg={4}>
                      <Input
                        nome='codigo'
                        tabIndex={1}
                        titulo='Código *'
                        disabled={true}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={4} span-lg={8}>
                      <Input
                        nome='nome'
                        tabIndex={2}
                        titulo='Nome *'
                        disabled={true}
                      />
                    </r-cell>
                    <r-cell span={4} span-md='row' span-lg='row'>
                      <div className='form-toggle-list'>
                        <Toglle
                          className='form-toggle-2'
                          nome='csap'
                          tabIndex={3}
                          titulo='CSAP'
                          disabled={true}
                        />
                        <div className='csap-info'>
                          <p className='form-label font-italic'>
                            De acordo com a{' '}
                            <a className='csap-link' href='https://bvsms.saude.gov.br/bvs/saudelegis/sas/2008/prt0221_17_04_2008.html' target='_blank' rel='noopener noreferrer'>
                              Portaria Nº 221, de 17 de Abril de 2008
                            </a>
                            , do Ministério da Saúde
                          </p>
                        </div>
                      </div>
                    </r-cell>
                    <r-cell span={4} span-md='row' span-lg='row'>
                      <div className='form-toggle-list'>
                        <Toglle
                          className='form-toggle-2'
                          nome='habilitadoParaDiagnosticos'
                          tabIndex={4}
                          titulo='Habilitado para Diagnósticos'
                          disabled={!podeEditar}
                        />
                        <Toglle
                          className='form-toggle-2'
                          nome='habilitadoParaInternacoes'
                          tabIndex={5}
                          titulo='Habilitado para Internações'
                          disabled={!podeEditar}
                        />
                        <Toglle
                          className='form-toggle-2'
                          nome='habilitadoParaIdasAoProntoAtendimento'
                          tabIndex={6}
                          titulo='Habilitado para Idas ao PA'
                          disabled={!podeEditar}
                        />
                      </div>
                    </r-cell>
                    <r-cell span={4} span-md='row' span-lg='row'>
                      <p className='form-label'>Tags</p>
                        <ReactTags
                          classNames={{
                            tagInput: 'tag tag-input',
                            tagInputField: 'tag tag-input'
                          }}
                          allowDeleteFromEmptyInput={false}
                          allowDragDrop={false}
                          tags={values.tags.map(x => ({ id: x, text: x }))}
                          delimiters={[KeyCodes.comma, KeyCodes.enter]}
                          handleDelete={index => removerTag(index, setFieldValue, values.tags)}
                          handleAddition={tag => adicionarTag(tag, values.tags, setFieldValue, setFieldError)}
                          placeholder={'Adicionar Tag'}
                          readOnly={!podeEditar}
                          tabIndex={7}
                        />
                      {errors.tags ? <label className='form-error'>{errors.tags}</label> : ''}
                    </r-cell>
                    <r-cell span={4} span-md='row' span-lg='row'>
                      <Input
                        as='textarea'
                        nome='observacoes'
                        tabIndex={8}
                        titulo='Observações'
                        disabled={!podeEditar}
                      />
                    </r-cell>
                  </r-grid>
                </fieldset>
                <div className='list-btn'>
                  <button onClick={history.goBack} type='button' className='button --light'>Voltar</button>
                  {podeEditar && <button type='submit' className='button --primary'>Salvar Alterações</button>}
                </div>
              </>
            )}
          </FormularioDoFormik>
        </Spinner>
      </div>
    </div>
  )
}