import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import Formulario from './formulario'
import Lista from './lista'

export default function Cids(props) {
  const {
    alterarCid,
    cid,
    filtros,
    history,
    listarCidsDoCadastro,
    match,
    ordenacao,
    paginaDeDados,
    recuperarCidPeloIdentificador
  } = props

  const salvar = async formulario => {
    const executou = await alterarCid(formulario)

    if (executou) {
      history.push(`/cadastros/cids`)
    }
  }

  return (
    <Switch>
      <RotaComAutenticacao
        exact
        path={`${match.path}/lista`}
        render={
          props => (
            <Permissao permissoes={[permissoes.LISTAR_CIDS]}>
              <Lista
                {...props}
                filtros={filtros}
                ordenacao={ordenacao}
                pesquisar={listarCidsDoCadastro}
                paginaDeDados={paginaDeDados}
              />
            </Permissao>
          )
        }
      />
      <RotaComAutenticacao
        exact
        path={`${match.path}/:identificador`}
        render={
          props => (
            <Permissao permissoes={[permissoes.ALTERAR_CID]}>
              <Formulario
                {...props}
                recuperarPeloIdentificador={recuperarCidPeloIdentificador}
                salvar={salvar}
                cid={cid}
              />
            </Permissao>
          )
        }
      />
      <Redirect exact from={match.path} to={`${match.path}/lista`} />
    </Switch>
  )
}