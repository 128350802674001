import React from 'react'

export const Secoes = ({ children, abaSelecionada, questionario, aoSelecionarResposta }) => {
  return (
    <>
      {React.Children.map(children, (child, index) => {
          if (index === abaSelecionada) {
            return React.cloneElement(child, {
              key: child.props.chave,
              aoSelecionarResposta: aoSelecionarResposta,
              secao: questionario[child.props.chave],
            })
          }

          return null
        },
      )}
    </>
  )
}

export const Secao = ({ children, chave, secao, aoSelecionarResposta }) => {
  const novoChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      secao: chave,
      key: `${chave}_${child.props.chave}_${child.props.key}`,
      respostaPadrao: secao[child.props.chave],
      aoSelecionarResposta: aoSelecionarResposta,
    })
  })
  return <div className='secao'>{novoChildren}</div>
}
