import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Select, Input } from '../../../formik/formulario'
import * as Yup from 'yup'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { ADICIONAR_DIAGNOSTICO_DO_PACIENTE, LISTAR_CIDS } from '../../../../acoes/tipos'
import { filtroPadrao } from '../../../../bibliotecas/texto'

export default function AdicionarDiagnostico({ adicionarDiagnosticoDoPaciente, fechar, cids, identificadorDoPaciente }) {
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_DIAGNOSTICO_DO_PACIENTE])

  const adicionarDiagnostico = async formulario => {
    const dados = {
      diagnostico: formulario.cid,
      observacoes: formulario.observacoes
    }

    const adicionou = await adicionarDiagnosticoDoPaciente(identificadorDoPaciente, dados)

    if (adicionou) {
      fechar()
    }
  }

  const renderizarItens = ({ value, label, csap, tags }) => {
    return (
      <div key={value}>
        <div>
          <span>{label}</span>
        </div>
        {csap &&
          <div className='segunda-linha-auto-complete-pacientes tags-diagnostico'>
            <div>
              <span>Condição Sensível à Atenção Primária</span>
            </div>
          </div>
        }
        {tags && tags.trim().length > 0 &&
          <div className='segunda-linha-auto-complete-pacientes tags-diagnostico'>
            <div>
              <span>Tags: {tags}</span>
            </div>
          </div>
        }
      </div>
    )
  }

  const optionsCid = cids.map(x => ({
    value: x.identificador,
    label: `${x.codigo} - ${x.nome}`,
    csap: x.csap,
    tags: x.tags.join(', '),
    baseDePesquisa:`${x.tags.join(' ')} ${x.codigo} - ${x.nome}`.trim()
  }))

  return (
    <Spinner operacoes={[ADICIONAR_DIAGNOSTICO_DO_PACIENTE, LISTAR_CIDS]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          cid: '',          
          observacoes: ''
        }}
        esquemaDeValidacoes={Yup.object().shape({
          cid: Yup.string().required('Obrigatório.'),          
        })}
        acao={adicionarDiagnostico}
      >
        <fieldset>
          <h2 className='form-title'>Adicionar Diagnóstico</h2>
          <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Select
                nome='cid'
                titulo='CID *'
                tabIndex={1}
                itens={cids.map(x => ({ codigo: x.identificador, nome: `${x.codigo} - ${x.nome}` }))}
                campoCodigo='codigo'
                campoDescricao='nome'
                formatOptionLabel={renderizarItens}
                options={optionsCid}
                noOptionsMessage='Digite 3 caracteres para pesquisar'
                filterOption={(option, inputValue) => {
                  if (inputValue.length >= 3) {
                    return filtroPadrao(inputValue, option.data.baseDePesquisa)
                  }
                }}

              />
            </r-cell>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                as='textarea'
                tabIndex={2}
                nome='observacoes'
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
            <button type='button' className='button --light' onClick={() => fechar()}>Cancelar</button>
            {podeAdicionar && <button type='submit' className='button --primary'>Adicionar</button>}
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}