import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../breadcrumb'
import CampoDePesquisa from '../campo-de-pesquisa'
import Spinner from '../spinner'
import { Coluna, Tabela } from '../tabela'
import Permissao from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'

import { LISTAR_CIDS_DO_CADASTRO } from '../../acoes/tipos'
import DicaDePesquisa from '../dica-de-pesquisa';

export default function Lista(props) {
  const {
    paginaDeDados,
    pesquisar
  } = props

  const [ordenacao, setOrdenacao] = useState(props.ordenacao)
  const [pagina, setPagina] = useState(paginaDeDados.pagina)
  const [filtros, setFiltros] = useState(props.filtros)

  useEffect(() => {
    (async () => await pesquisar(filtros, pagina, ordenacao, 10))()
  }, [filtros, pesquisar, pagina, ordenacao])

  const listar = (numeroDaPagina, ordenacao) => {
    setPagina(numeroDaPagina)
    setOrdenacao(ordenacao)
  }

  const alterarFiltros = filtros => {
    if (filtros) {
      setFiltros(filtros)
      setPagina(1)
    }
  }

  const alterarTermo = termo => alterarFiltros({ ...filtros, termo })

  const alterarFiltrosDaTabela = filtro => {
    alterarFiltros({
      ...filtros,
      [filtro.nome]: filtro.valor,
    })
  }

  const renderizarHabilitacoes= item => {
    const diagnosticos = item.habilitadoParaDiagnosticos && 'Diagnósticos'
    const internacoes = item.habilitadoParaInternacoes && 'Internações'
    const idasAoPa = item.habilitadoParaIdasAoProntoAtendimento && 'Idas ao PA'

    return [diagnosticos, internacoes, idasAoPa].filter(Boolean).join(', ')
  }

  return (
    <div className='page-configuracoes-agendamento'>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form mb-2'>
            <r-grid columns-md={6} columns-lg={12} class='w-100 align-items-start'>
              <r-cell span={4} span-md={3} span-lg={8}>
                <Breadcrumb/>
              </r-cell>
              <r-cell span={4} span-md={3} span-lg={4}>
                <DicaDePesquisa
                  className='form-search'
                  dica='A pesquisa é realizada por parte do nome, código ou tag.'
                  exemplo='A0 col'
                >
                  <CampoDePesquisa
                    pesquisar={alterarTermo}
                    valor={filtros ? filtros.termo : ''}
                  />
                </DicaDePesquisa>
              </r-cell>
            </r-grid>
          </div>
          <Spinner operacoes={[LISTAR_CIDS_DO_CADASTRO]}>
            <Tabela
              acoes={[
                item => (
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_CID]}>
                    <Link
                      to={`/cadastros/cids/${item.identificador}`}
                      className='table-items__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </Link>
                  </Permissao>
                )
              ]}
              alterarFiltros={alterarFiltrosDaTabela}
              chave='identificador'
              className='mt-1 mb-4'
              filtros={filtros}
              listar={listar}
              ordenacao={ordenacao}
              paginaDeDados={paginaDeDados}
            >
              <Coluna
                campoDeDados='codigo'
                className='col-xs-8 col-md-10 col-lg-1'
                exibirOrdenacao={true}
                principal={true}
                nome='Código'
              />
              <Coluna
                campoDeDados='nome'
                className='col-lg-4'
                exibirOrdenacao={true}
                nome='Nome'
              />
              <Coluna
                className='col-lg-2'
                nome='Tags'
                renderizar={item => item.tags.join(', ')}
              />
              <Coluna
                campoDeDados='csap'
                className='col-lg-1'
                filtros={['Sim', 'Não']}
                nome='csap'
                renderizar={item => item.csap ? 'Sim' : 'Não'}
              />
              <Coluna
                campoDeDados='habilitadoPara'
                className='col-lg-3'
                filtros={['Diagnósticos', 'Internações', 'Idas ao PA']}
                nome='Habilitado para'
                renderizar={item => renderizarHabilitacoes(item)}
              />
            </Tabela>
          </Spinner>
        </div>
      </div>
    </div>
  )
}