import React from 'react'
import { MultiplaEscolha, MultiplaSelecao } from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'

import {
  LISTAR_VACINAS_EM_DIA,
  LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  LISTAR_ESTADOS_DE_DEAMBULACAO,
  LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  LISTAR_CONDICOES_DOS_PACIENTES,
  LISTAR_COMORBIDADES_REUMATOLOGICAS,
  LISTAR_COMORBIDADES_RENAIS,
  LISTAR_COMORBIDADES_PULMONARES,
  LISTAR_COMORBIDADES_PSIQUIATRICAS,
  LISTAR_COMORBIDADES_OSTEOMUSCULARES,
  LISTAR_COMORBIDADES_ONCOLOGICAS,
  LISTAR_COMORBIDADES_NEUROLOGICAS,
  LISTAR_COMORBIDADES_GASTROINTESTINAIS,
  LISTAR_COMORBIDADES_ENDOCRINAS,
  LISTAR_COMORBIDADES_CARDIOVASCULARES,
  LISTAR_COGNICOES_DOS_PACIENTES,
  LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
  LISTAR_ESCALAS_DE_FRAGILIDADE
} from '../../../../../acoes/tipos'

const renderizarTitulo = valor => valor.nome
const renderizarDescricao = valor => valor.descricao

export default function Resumo({ valores, fechar, ...rest }) {
    return (
    <Spinner operacoes={[
      LISTAR_VACINAS_EM_DIA,
      LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
      LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
      LISTAR_ESTADOS_DE_DEAMBULACAO,
      LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
      LISTAR_CONDICOES_DOS_PACIENTES,
      LISTAR_COMORBIDADES_REUMATOLOGICAS,
      LISTAR_COMORBIDADES_RENAIS,
      LISTAR_COMORBIDADES_PULMONARES,
      LISTAR_COMORBIDADES_PSIQUIATRICAS,
      LISTAR_COMORBIDADES_OSTEOMUSCULARES,
      LISTAR_COMORBIDADES_ONCOLOGICAS,
      LISTAR_COMORBIDADES_NEUROLOGICAS,
      LISTAR_COMORBIDADES_GASTROINTESTINAIS,
      LISTAR_COMORBIDADES_ENDOCRINAS,
      LISTAR_COMORBIDADES_CARDIOVASCULARES,
      LISTAR_COGNICOES_DOS_PACIENTES,
      LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
      LISTAR_ESCALAS_DE_FRAGILIDADE
    ]}>
      <r-grid columns-md={6} columns-lg={12} class="form form-group">
        <r-cell span={4} span-md={6} span-lg={12}>
          <fieldset>
            <h2 className="form-title">Resumo do Questionário do Idoso</h2>
            <Paciente paciente={valores.paciente}/>
            <Data data={valores.horario}/>
            <br/>
            <r-grid columns-md={6} columns-lg={12} class="componente-fisioterapia">
              <Cabecalho/>
              <EscalaDeFragilidade escalaDeFragilidade={valores.escalaDeFragilidade.resposta} escalasDeFragilidade={rest.escalasDeFragilidade}/>
              <PacienteMoraComQuem moraComQuem={valores.moraComQuem.resposta} comQuemOsPacientesMoram={rest.comQuemOsPacientesMoram}/>
              <MedicamentosEmUso medicamentosEmUso={valores.medicamentosEmUso.resposta} usosDeMedicamentosPelosPacientes={rest.usosDeMedicamentosPelosPacientes}/>
              <VacinasEmDia vacinasEmDia={valores.vacinasEmDia.respostas} vacinasEmDiaDosPacientes={rest.vacinasEmDia}/>
              <Cognicao cognicao={valores.cognicao.resposta} cognicoesDosPacientes={rest.cognicoesDosPacientes}/>
              <Condicoes condicoes={valores.condicoes.respostas} condicoesDosPacientes={rest.condicoesDosPacientes}/>
              <Deambulacao deambulacao={valores.deambulacao.resposta} estadosDeDeambulacao={rest.estadosDeDeambulacao}/>
              <QuedasNosUltimosDozeMeses quedasNosUltimosDozeMeses={valores.quedasNosUltimosDozeMeses.resposta} quedasNosUltimosDozeMesesDosPacientes={rest.quedasNosUltimosDozeMeses}/>
              <DispositivosEmUso dispositivosEmUso={valores.dispositivosEmUso.respostas} dispositivosEmUsoDosPacientes={rest.dispositivosEmUsoDosPacientes}/>
              <EstadoTerminal estadoTerminal={valores.estadoTerminal.resposta}/>
              <NecessidadeDeControleDeSintomas necessidadeDeControleDeSintomas={valores.necessidadeDeControleDeSintomas.resposta}/>
              <PossuiComorbidades/>
              <ComorbidadesCardiovasculares comorbidadesCardiovasculares={valores.comorbidadesCardiovasculares.respostas} comorbidadesCardiovascularesDosPacientes={rest.comorbidadesCardiovasculares}/>
              <ComorbidadesEndocrinas comorbidadesEndocrinas={valores.comorbidadesEndocrinas.respostas} comorbidadesEndocrinasDosPacientes={rest.comorbidadesEndocrinas}/>
              <ComorbidadesGastrointestinais comorbidadesGastrointestinais={valores.comorbidadesGastrointestinais.respostas} comorbidadesGastrointestinaisDosPacientes={rest.comorbidadesGastrointestinais}/>
              <ComorbidadesNeurologicas comorbidadesNeurologicas={valores.comorbidadesNeurologicas.respostas} comorbidadesNeurologicasDosPacientes={rest.comorbidadesNeurologicas}/>
              <ComorbidadesOncologicas comorbidadesOncologicas={valores.comorbidadesOncologicas.respostas} comorbidadesOncologicasDosPacientes={rest.comorbidadesOncologicas}/>
              <ComorbidadesOsteomusculares comorbidadesOsteomusculares={valores.comorbidadesOsteomusculares.respostas} comorbidadesOsteomuscularesDosPacientes={rest.comorbidadesOsteomusculares}/>
              <ComorbidadesPsiquiatricas comorbidadesPsiquiatricas={valores.comorbidadesPsiquiatricas.respostas} comorbidadesPsiquiatricasDosPacientes={rest.comorbidadesPsiquiatricas}/>
              <ComorbidadesPulmonares comorbidadesPulmonares={valores.comorbidadesPulmonares.respostas} comorbidadesPulmonaresDosPacientes={rest.comorbidadesPulmonares}/>
              <ComorbidadesRenais comorbidadesRenais={valores.comorbidadesRenais.respostas} comorbidadesRenaisDosPacientes={rest.comorbidadesRenais}/>
              <ComorbidadesReumatologicas comorbidadesReumatologicas={valores.comorbidadesReumatologicas.respostas} comorbidadesReumatologicasDosPacientes={rest.comorbidadesReumatologicas}/>
            </r-grid>
              <Observacoes observacoes={valores.observacoes}/>
            <div className="list-btn">
              <button type="button" className="button --light" onClick={() => fechar()}>Fechar</button>
            </div>
          </fieldset>
        </r-cell>
      </r-grid>
    </Spinner>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className="componente-zarit paciente-leitura mb-1"><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className="componente-zarit paciente-leitura"><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}
  </div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="cabecalho">
      <div>
        <strong>QUESTIONÁRIO DO IDOSO</strong>
      </div>
    </r-cell>
  )
}

function EscalaDeFragilidade({ escalaDeFragilidade, escalasDeFragilidade }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="escalaDeFragilidade"
        opcoes={escalasDeFragilidade}
        pergunta="Qual é o Clinical Frailty Scale (CFS) do paciente?"
        renderizarTitulo={renderizarTitulo}
        renderizarDescricao={renderizarDescricao}
        leitura={true}
        valor={escalaDeFragilidade}
      />
    </r-cell>
  )
}

function PacienteMoraComQuem({ comQuemOsPacientesMoram, moraComQuem }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="moraComQuem"
        opcoes={comQuemOsPacientesMoram}
        pergunta="Paciente mora com quem?"
        renderizarTitulo={renderizarTitulo}
        valor={moraComQuem}
        leitura={true}
      />
    </r-cell>
  )
}

function MedicamentosEmUso({ medicamentosEmUso, usosDeMedicamentosPelosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="medicamentosEmUso"
        opcoes={usosDeMedicamentosPelosPacientes}
        pergunta="Quantidade de medicamentos utilizados?"
        renderizarTitulo={renderizarTitulo}
        valor={medicamentosEmUso}
        leitura={true}
      />
    </r-cell>
  )
}

function VacinasEmDia({ vacinasEmDia, vacinasEmDiaDosPacientes }) {
    return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="vacinasEmDia"
        opcoes={vacinasEmDiaDosPacientes}
        pergunta="Marque as vacinas do paciente que estão em dia:"
        renderizarTitulo={renderizarTitulo}
        valor={vacinasEmDia}
        leitura={true}
      />
    </r-cell>
  )
}

function Cognicao({ cognicao, cognicoesDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="cognicao"
        opcoes={cognicoesDosPacientes}
        pergunta="Cognição?"
        renderizarTitulo={renderizarTitulo}
        valor={cognicao}
        leitura={true}
      />
    </r-cell>
  )
}

function Condicoes({ condicoes, condicoesDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="condicoes"
        opcoes={condicoesDosPacientes}
        pergunta="Paciente apresenta alguma dessas condições?"
        renderizarTitulo={renderizarTitulo}
        valor={condicoes}
        leitura={true}
      />
    </r-cell>
  )
}

function Deambulacao({ deambulacao, estadosDeDeambulacao }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="deambulacao"
        opcoes={estadosDeDeambulacao}
        pergunta="Sobre deambulação (indicar a situação predominante)"
        renderizarTitulo={renderizarTitulo}
        valor={deambulacao}
        leitura={true}
      />
    </r-cell>
  )
}

function QuedasNosUltimosDozeMeses({ quedasNosUltimosDozeMeses, quedasNosUltimosDozeMesesDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="quedasNosUltimosDozeMeses"
        opcoes={quedasNosUltimosDozeMesesDosPacientes}
        pergunta="Queda nos últimos 12 meses:"
        renderizarTitulo={renderizarTitulo}
        valor={quedasNosUltimosDozeMeses}
        leitura={true}
      />
    </r-cell>
  )
}

function DispositivosEmUso({ dispositivosEmUso, dispositivosEmUsoDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="dispositivosEmUso"
        opcoes={dispositivosEmUsoDosPacientes}
        pergunta="Paciente faz uso de algum dispositivo?"
        renderizarTitulo={renderizarTitulo}
        valor={dispositivosEmUso}
        leitura={true}
      />
    </r-cell>
  )
}

function EstadoTerminal({ estadoTerminal }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="estadoTerminal"
        opcoes={[{ codigo: true, nome: 'Sim' }, { codigo: false, nome: 'Não' }]}
        pergunta="Paciente está em estado terminal (Sobrevida estimada menor que 12 meses)?"
        informacaoAdicional="Sugestão de ferramenta: SPICT-BR (Supportive and Palliative Care Indicators Tool) "
        renderizarTitulo={renderizarTitulo}
        valor={estadoTerminal}
        leitura={true}
      />
    </r-cell>
  )
}

function NecessidadeDeControleDeSintomas({ necessidadeDeControleDeSintomas }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaEscolha
        className="pergunta"
        nome="necessidadeDeControleDeSintomas"
        opcoes={[{ codigo: true, nome: 'Sim' }, { codigo: false, nome: 'Não' }]}
        pergunta="Tem necessidade de controle de sintomas no momento?"
        renderizarTitulo={renderizarTitulo}
        valor={necessidadeDeControleDeSintomas}
        leitura={true}
      />
    </r-cell>
  )
}

function PossuiComorbidades() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <p>Assinale a(s) comorbidade(s) do paciente:</p>
    </r-cell>
  )
}

function ComorbidadesCardiovasculares({ comorbidadesCardiovasculares, comorbidadesCardiovascularesDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesCardiovasculares"
        opcoes={comorbidadesCardiovascularesDosPacientes}
        pergunta="Cardiovascular"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesCardiovasculares}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesPulmonares({ comorbidadesPulmonares, comorbidadesPulmonaresDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesPulmonares"
        opcoes={comorbidadesPulmonaresDosPacientes}
        pergunta="Pulmonar"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesPulmonares}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesNeurologicas({ comorbidadesNeurologicas, comorbidadesNeurologicasDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesNeurologicas"
        opcoes={comorbidadesNeurologicasDosPacientes}
        pergunta="Neurológica"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesNeurologicas}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesPsiquiatricas({ comorbidadesPsiquiatricas, comorbidadesPsiquiatricasDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesPsiquiatricas"
        opcoes={comorbidadesPsiquiatricasDosPacientes}
        pergunta="Psiquiátrica"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesPsiquiatricas}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesRenais({ comorbidadesRenais, comorbidadesRenaisDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesRenais"
        opcoes={comorbidadesRenaisDosPacientes}
        pergunta="Renal"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesRenais}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesEndocrinas({ comorbidadesEndocrinas, comorbidadesEndocrinasDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesEndocrinas"
        opcoes={comorbidadesEndocrinasDosPacientes}
        pergunta="Endócrina"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesEndocrinas}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesReumatologicas({ comorbidadesReumatologicas, comorbidadesReumatologicasDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesReumatologicas"
        opcoes={comorbidadesReumatologicasDosPacientes}
        pergunta="Reumatológica"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesReumatologicas}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesGastrointestinais({ comorbidadesGastrointestinais, comorbidadesGastrointestinaisDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesGastrointestinais"
        opcoes={comorbidadesGastrointestinaisDosPacientes}
        pergunta="Gastrointestinal"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesGastrointestinais}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesOncologicas({ comorbidadesOncologicas, comorbidadesOncologicasDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesOncologicas"
        opcoes={comorbidadesOncologicasDosPacientes}
        pergunta="Oncológica"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesOncologicas}
        leitura={true}
      />
    </r-cell>
  )
}

function ComorbidadesOsteomusculares({ comorbidadesOsteomusculares, comorbidadesOsteomuscularesDosPacientes }) {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class="agrupamento">
      <MultiplaSelecao
        className="pergunta"
        nome="comorbidadesOsteomusculares"
        opcoes={comorbidadesOsteomuscularesDosPacientes}
        pergunta="Osteomuscular"
        renderizarTitulo={renderizarTitulo}
        valor={comorbidadesOsteomusculares}
        leitura={true}
      />
    </r-cell>
  )
}

function Observacoes({ observacoes }) {
    return (
        <r-grid columns-md={6} columns-lg={12}>
            <r-cell span={4} span-md={6} span-lg={12}>
                <div className='componente-zarit paciente-leitura my-1'><strong>Observações:</strong> <pre>{observacoes}</pre> </div>
            </r-cell>
        </r-grid>
    )
}