import { connect } from 'react-redux'
import acoes from '../../acoes'
import Atendimento from './index'

const recuperarProntuario = (state, identificador) => {
  const prontuario = state.prontuario.pacientes[identificador]

  if (!prontuario) return prontuario

  const imagens = state.imagens

  return {
    ...prontuario,
    cabecalho: {
      ...prontuario.cabecalho,
      identificacao: {
        ...prontuario.cabecalho.identificacao,
        foto: prontuario.cabecalho.identificacao.identificadorDaFoto ? imagens[prontuario.cabecalho.identificacao.identificadorDaFoto] : null,
        tagsDePacientes: state.pacientes.tagsDePacientes,
      }
    },
    tiposDeAtendimento: state.prontuario.tiposDeAtendimento,
    teleconsulta: state.prontuario.teleconsulta
  }
}

const recuperarFotosDaSugestao = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.foto).forEach(item => {
    fotos[item.foto] = imagens[item.foto]
  })

  return fotos
}

const mapStateToProps = (state, ownProps) => {
  const identificador = ownProps.match.params ? ownProps.match.params.identificador : ''

  return {
    prontuario: recuperarProntuario(state, identificador),
    usuario: state.login ? state.login.logado : null,
    orientacoesDePagina: state.orientacoesDePagina,
    tamanhosDePaginas: state.tamanhosDePaginas,
    cids: state.cids.itens,
    medicamentos: state.medicamentosDoProntuario,
    sugestoes: state.sugestoes,
    fotosDaSugestao: recuperarFotosDaSugestao(state.sugestoes.profissionaisDeSaude, state.imagens),
    situacoesAssociadasADorNoPeito: state.triagem.situacoesAssociadasADorNoPeito,
  }
}

export default connect(mapStateToProps, acoes)(Atendimento)