import React from 'react'
import Spinner from '../../../spinner'
import { Formulario as FormularioDoFormik, Input } from '../../../formik/formulario'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { formatarDataParaFormatoLocal, formatarHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import { ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE } from '../../../../acoes/tipos'

export default function RemoverDiagnostico({
  alterarStatusDoDiagnosticoDoPaciente,
  fechar,
  identificadorDoPaciente,
  diagnostico: {
    identificador,
    diagnostico,
    dataEHora,
    usuario,
    observacoes
  }
}) {
  const podeRemover = usePossuiAsPermissoes([permissoes.REMOVER_DIAGNOSTICO_CONFIRMADO_DO_PACIENTE, permissoes.REMOVER_DIAGNOSTICO_DO_PACIENTE])

  const removerDiagnostico = async formulario => {
    const dados = {
      ...formulario,
      status: 'excluido',
    }

    const removeu = await alterarStatusDoDiagnosticoDoPaciente(identificadorDoPaciente, identificador, dados)

    if (removeu) {
      fechar()
    }
  }

  const retornarDadosDoProfissional = (usuario, profissao) => {
    let texto = " "
 
    if (usuario.tratamento) {
      texto += usuario.tratamento
      texto += " "
    }

    texto += usuario.nome

    if (profissao) {
      texto += " | "
      texto += profissao.nome
    }

    return texto
  }
   
  return (
    <Spinner operacoes={[ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE]}>
      <FormularioDoFormik
        reinicializar={true}
        valoresIniciais={{
          observacoes: ''
        }}
        acao={removerDiagnostico}
      >
        <fieldset>
          <h2 className='form-title'>Detalhes do Diagnóstico</h2>
          <div className='form-details'>
            <p><strong>Diagnóstico:</strong>{' '}{diagnostico.codigo} - {diagnostico.nome}</p>
            <p><strong>Usuário:</strong>{retornarDadosDoProfissional(usuario, usuario.profissao)}</p>
            <p><strong>Data:</strong>{' '}{formatarDataParaFormatoLocal(dataEHora)}</p>
            <p><strong>Hora:</strong>{' '}{formatarHoraParaFormatoLocal(dataEHora)}</p>
            {observacoes && <pre><strong>Observações:</strong>{' '}{observacoes}</pre>}
          </div>
          <r-grid columns-md={6} columns-lg={12} class='mt-3'>
            <r-cell span={4} span-md={6} span-lg={12}>
              <Input
                as='textarea'
                tabIndex={1}
                nome='observacoes'
                titulo='Observações'
              />
            </r-cell>
          </r-grid>
          <div className='list-btn'>
             <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
             {podeRemover && <button type='submit' className='button --danger'>Remover</button>}
          </div>
        </fieldset>
      </FormularioDoFormik>
    </Spinner>
  )
}