import api from '../../api-major'

const adicionarDiagnosticoDoPaciente = (paciente, diagnostico) => api.post(`/pacientes/${paciente}/diagnosticos`, diagnostico)
const alterarStatusDoDiagnosticoDoPaciente = (paciente, identificador, dados) => api.put(`/pacientes/${paciente}/diagnosticos/${identificador}`, dados)
const alterarPosicaoDosDiagnosticosDoPaciente = (paciente, identificador, posicao) => api.put(`/pacientes/${paciente}/diagnosticos/${identificador}/posicao`, posicao)
const listarDiagnosticosDoPaciente = (paciente) => api.get(`/pacientes/${paciente}/diagnosticos`)

export default {
  adicionarDiagnosticoDoPaciente,
  alterarStatusDoDiagnosticoDoPaciente,
  alterarPosicaoDosDiagnosticosDoPaciente,
  listarDiagnosticosDoPaciente
}
