import moment from 'moment'
import {
  ERRO_AO_ADICIONAR_AGENDAMENTO,
  ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_ASSOCIACAO,
  ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_ESPECIALIDADE,
  ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_ADICIONAR_FABRICANTE,
  ERRO_AO_ADICIONAR_FERIADO,
  ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA,
  ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_FOTO_DO_USUARIO,
  ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INFORMACOES,
  ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ERRO_AO_ADICIONAR_MEDICAMENTO,
  ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_MINI_MENTAL,
  ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO,
  ERRO_AO_ADICIONAR_OPERADORA,
  ERRO_AO_ADICIONAR_ORIENTACAO,
  ERRO_AO_ADICIONAR_PACIENTE,
  ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO,
  ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO,
  ERRO_AO_ADICIONAR_PHQ9,
  ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO,
  ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA,
  ERRO_AO_ADICIONAR_PROFISSAO,
  ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO,
  ERRO_AO_ADICIONAR_PROGRAMA,
  ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE,
  ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_SALA_NA_UNIDADE,
  ERRO_AO_ADICIONAR_SETOR,
  ERRO_AO_ADICIONAR_TAG_NO_PACIENTE,
  ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO,
  ERRO_AO_ADICIONAR_TEMPLATE,
  ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO,
  ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO,
  ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO,
  ERRO_AO_ADICIONAR_USUARIO,
  ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE,
  ERRO_AO_ALTERAR_ASSOCIACAO,
  ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE,
  ERRO_AO_ALTERAR_CID,
  ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO,
  ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE,
  ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ERRO_AO_ALTERAR_ESPECIALIDADE,
  ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_ALTERAR_FABRICANTE,
  ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE,
  ERRO_AO_ALTERAR_MEDICAMENTO,
  ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_MINI_MENTAL,
  ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO,
  ERRO_AO_ALTERAR_OPERADORA,
  ERRO_AO_ALTERAR_ORIENTACAO,
  ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO,
  ERRO_AO_ALTERAR_PHQ9,
  ERRO_AO_ALTERAR_PLANO_DE_CUIDADO,
  ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE,
  ERRO_AO_ALTERAR_PROFISSAO,
  ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO,
  ERRO_AO_ALTERAR_PROGRAMA,
  ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE,
  ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE,
  ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_ALTERAR_SALA_DA_UNIDADE,
  ERRO_AO_ALTERAR_SENHA_DO_USUARIO,
  ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_ALTERAR_SETOR,
  ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA,
  ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE,
  ERRO_AO_ALTERAR_STATUS_DO_USUARIO,
  ERRO_AO_ALTERAR_VACINA_DO_PACIENTE,
  ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED,
  ERRO_AO_CANCELAR_AGENDAMENTO,
  ERRO_AO_CANCELAR_ATENDIMENTO,
  ERRO_AO_CONFIRMAR_AGENDAMENTO,
  ERRO_AO_DEFINIR_DATA_DA_TAREFA,
  ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA,
  ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA,
  ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA,
  ERRO_AO_DEFINIR_PRAZO_DA_TAREFA,
  ERRO_AO_DEFINIR_TEXTO_DA_TAREFA,
  ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA,
  ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS,
  ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_EXCLUIR_AGENDAMENTO,
  ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_FERIADO,
  ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_EXCLUIR_TEMPLATE,
  ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS,
  ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES,
  ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO,
  ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ERRO_AO_FINALIZAR_ATENDIMENTO,
  ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO,
  ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO,
  ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS,
  ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_IMPORTAR_PACIENTES,
  ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO,
  ERRO_AO_INICIAR_ATENDIMENTO,
  ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS,
  ERRO_AO_LISTAR_AGENDAMENTOS,
  ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO,
  ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA,
  ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_ANTIBIOTICOS,
  ERRO_AO_LISTAR_ASSOCIACOES,
  ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO,
  ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS,
  ERRO_AO_LISTAR_ATENDIMENTOS_INICIADOS,
  ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT,
  ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE,
  ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS,
  ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE,
  ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS,
  ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO,
  ERRO_AO_LISTAR_CIDS,
  ERRO_AO_LISTAR_CIDS_DO_CADASTRO,
  ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE,
  ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO,
  ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE,
  ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE,
  ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE,
  ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_DURACOES_DAS_DORES,
  ERRO_AO_LISTAR_EMPRESAS_HABILITADAS,
  ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN,
  ERRO_AO_LISTAR_ENCAMINHAMENTOS,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE,
  ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO,
  ERRO_AO_LISTAR_ESTADOS_CIVIS,
  ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL,
  ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS,
  ERRO_AO_LISTAR_FABRICANTES,
  ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO,
  ERRO_AO_LISTAR_FERIADOS,
  ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL,
  ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO,
  ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT,
  ERRO_AO_LISTAR_GRAUS_DE_RELACOES,
  ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE,
  ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_HORARIOS_LIVRES,
  ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES,
  ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE,
  ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO,
  ERRO_AO_LISTAR_LOCAIS_DAS_DORES,
  ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS,
  ERRO_AO_LISTAR_MEDICAMENTOS,
  ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE,
  ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO,
  ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_MICRORGANISMOS,
  ERRO_AO_LISTAR_MINI_MENTAIS,
  ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA,
  ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS,
  ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS,
  ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO,
  ERRO_AO_LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE,
  ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO,
  ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO,
  ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO,
  ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS,
  ERRO_AO_LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO,
  ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA,
  ERRO_AO_LISTAR_OPERADORAS,
  ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO,
  ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE,
  ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_ORIENTACOES,
  ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS,
  ERRO_AO_LISTAR_PACIENTES,
  ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_PACIENTES_DO_NPS,
  ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS,
  ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS,
  ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO,
  ERRO_AO_LISTAR_PERFIS,
  ERRO_AO_LISTAR_PERFIS_DO_USUARIO,
  ERRO_AO_LISTAR_PHQS9,
  ERRO_AO_LISTAR_PLANOS_DE_CUIDADO,
  ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO,
  ERRO_AO_LISTAR_POSOLOGIAS,
  ERRO_AO_LISTAR_PREVISOES_DE_RETORNO,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO,
  ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS,
  ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS,
  ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS,
  ERRO_AO_LISTAR_PROFISSOES,
  ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO,
  ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS,
  ERRO_AO_LISTAR_PROGRAMAS,
  ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES,
  ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES,
  ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE,
  ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE,
  ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16,
  ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9,
  ERRO_AO_LISTAR_RESULTADOS_ANTI_HBC,
  ERRO_AO_LISTAR_RESULTADOS_ANTI_HCV,
  ERRO_AO_LISTAR_RESULTADOS_ANTI_HIV,
  ERRO_AO_LISTAR_RESULTADOS_HBSAG,
  ERRO_AO_LISTAR_SALAS_DA_UNIDADE,
  ERRO_AO_LISTAR_SETORES,
  ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS,
  ERRO_AO_LISTAR_SEXOS,
  ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO,
  ERRO_AO_LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_LISTAR_STATUS_DE_INTERNACOES,
  ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS,
  ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE,
  ERRO_AO_LISTAR_TAGS_DE_PACIENTES,
  ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS,
  ERRO_AO_LISTAR_TEMPLATES,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
  ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS,
  ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER,
  ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA,
  ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO,
  ERRO_AO_LISTAR_TIPOS_DE_EXAME,
  ERRO_AO_LISTAR_TIPOS_DE_INTERNACAO,
  ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA,
  ERRO_AO_LISTAR_TIPOS_DE_TAREFA,
  ERRO_AO_LISTAR_TIPOS_DE_UNIDADE,
  ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO,
  ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO,
  ERRO_AO_LISTAR_TRATAMENTOS,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA,
  ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO,
  ERRO_AO_LISTAR_UNIDADES_DE_CALCIO_IONICO,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA,
  ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO,
  ERRO_AO_LISTAR_UNIDADES_FEDERATIVAS,
  ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS,
  ERRO_AO_LISTAR_USUARIOS,
  ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS,
  ERRO_AO_LISTAR_VACINAS,
  ERRO_AO_LISTAR_VACINAS_DO_PACIENTE,
  ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA,
  ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL,
  ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ATENDIMENTO,
  ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO,
  ERRO_AO_RECUPERAR_AVALIACAO,
  ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA,
  ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO,
  ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO,
  ERRO_AO_RECUPERAR_CID_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO,
  ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO,
  ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL,
  ERRO_AO_RECUPERAR_EMPRESA,
  ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA,
  ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO,
  ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED,
  ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE,
  ERRO_AO_RECUPERAR_LOGIN,
  ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE,
  ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM,
  ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR,
  ERRO_AO_RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO,
  ERRO_AO_REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA,
  ERRO_AO_REMOVER_ASSOCIACAO,
  ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE,
  ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE,
  ERRO_AO_REMOVER_CASP_16_DO_PACIENTE,
  ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO,
  ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE,
  ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE,
  ERRO_AO_REMOVER_ESPECIALIDADE,
  ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE,
  ERRO_AO_REMOVER_FABRICANTE,
  ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL,
  ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE,
  ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE,
  ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE,
  ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL,
  ERRO_AO_REMOVER_MEDICAMENTO,
  ERRO_AO_REMOVER_MINI_MENTAL,
  ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO,
  ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO,
  ERRO_AO_REMOVER_OPERADORA,
  ERRO_AO_REMOVER_ORIENTACAO,
  ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA,
  ERRO_AO_REMOVER_PEDIDO_DE_CONTATO,
  ERRO_AO_REMOVER_PERFIL_DO_USUARIO,
  ERRO_AO_REMOVER_PHQ9,
  ERRO_AO_REMOVER_PLANO_DE_CUIDADO,
  ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE,
  ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED,
  ERRO_AO_REMOVER_PROFISSAO,
  ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO,
  ERRO_AO_REMOVER_PROGRAMA,
  ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE,
  ERRO_AO_REMOVER_QUEDA_DO_PACIENTE,
  ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE,
  ERRO_AO_REMOVER_SALA_DA_UNIDADE,
  ERRO_AO_REMOVER_SETOR,
  ERRO_AO_REMOVER_TAG_DO_PACIENTE,
  ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO,
  ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO,
  ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA,
  ERRO_AO_REMOVER_UNIDADE_DO_USUARIO,
  ERRO_AO_REMOVER_VACINA_DO_PACIENTE,
  ERRO_AO_RESPONDER_AVALIACAO,
  ERRO_AO_SALVAR_AGENDAMENTO,
  ERRO_AO_SALVAR_EMPRESA,
  ERRO_AO_SALVAR_FERIADO,
  ERRO_AO_SALVAR_PACIENTE,
  ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO,
  ERRO_AO_SALVAR_TEMPLATE,
  ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO,
  ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA,
  ERRO_AO_SALVAR_USUARIO,
  ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  NAO_AUTENTICOU_USUARIO
} from '../acoes/tipos'

const estadoInicial = {}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ERRO_AO_LISTAR_UNIDADES_FEDERATIVAS:
    case ERRO_AO_LISTAR_SEXOS:
    case ERRO_AO_LISTAR_TIPOS_DE_REPETICAO_DE_SERIE_DA_AGENDA:
    case ERRO_AO_LISTAR_TRATAMENTOS:
    case ERRO_AO_LISTAR_PROFISSOES:
    case ERRO_AO_ADICIONAR_FOTO_DO_USUARIO:
    case ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_POR_IDENTIFICADOR:
    case ERRO_AO_ADICIONAR_USUARIO:
    case ERRO_AO_RECUPERAR_USUARIO_POR_IDENTIFICADOR:
    case ERRO_AO_SALVAR_USUARIO:
    case ERRO_AO_LISTAR_USUARIOS:
    case ERRO_AO_LISTAR_UNIDADES_DO_USUARIO:
    case ERRO_AO_ADICIONAR_UNIDADE_DO_USUARIO:
    case ERRO_AO_REMOVER_UNIDADE_DO_USUARIO:
    case ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA_DO_USUARIO:
    case ERRO_AO_LISTAR_PERFIS_DO_USUARIO:
    case ERRO_AO_REMOVER_PERFIL_DO_USUARIO:
    case ERRO_AO_ADICIONAR_PERFIL_DO_USUARIO:
    case ERRO_AO_LISTAR_PERFIS:
    case ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_PROFISSIONAL_DAS_CONFIGURACOES_DA_AGENDA:
    case ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DAS_CONFIGURACOES_DA_AGENDA:
    case ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DA_AGENDA:
    case ERRO_AO_ADICIONAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_LISTAR_INSTRUCOES_DE_AGENDAMENTOS_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_DO_HORARIO_DA_AGENDA:
    case ERRO_AO_LISTAR_BLOQUEIOS_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ADICIONAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_RECUPERAR_LOGIN:
    case ERRO_AO_LISTAR_EMPRESAS_HABILITADAS_PARA_O_LOGIN:
    case NAO_AUTENTICOU_USUARIO:
    case ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_DO_USUARIO_LOGADO:
    case ERRO_AO_RECUPERAR_FOTO_DO_USUARIO_LOGADO_POR_IDENTIFICADOR:
    case ERRO_AO_LISTAR_UNIDADES_DO_USUARIO_LOGADO:
    case ERRO_AO_ALTERAR_SENHA_DO_USUARIO:
    case ERRO_AO_ADICIONAR_UNIDADE_DA_EMPRESA:
    case ERRO_AO_SALVAR_UNIDADE_DA_EMPRESA:
    case ERRO_AO_LISTAR_UNIDADES_DA_EMPRESA:
    case ERRO_AO_REMOVER_UNIDADE_DA_EMPRESA:
    case ERRO_AO_ATIVAR_UNIDADE_DA_EMPRESA:
    case ERRO_AO_DESATIVAR_UNIDADE_DA_EMPRESA:
    case ERRO_AO_SALVAR_EMPRESA:
    case ERRO_AO_RECUPERAR_EMPRESA:
    case ERRO_AO_ADICIONAR_FOTO_DA_EMPRESA:
    case ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_LOGADA:
    case ERRO_AO_RECUPERAR_FOTO_DA_EMPRESA_POR_IDENTIFICADOR_PUBLICO:
    case ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO:
    case ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO:
    case ERRO_AO_RECUPERAR_MOTIVO_DE_INTERNACAO_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_MOTIVO_DE_INTERNACAO:
    case ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO:
    case ERRO_AO_LISTAR_MOTIVOS_DE_INTERNACAO_DO_CADASTRO:
    case ERRO_AO_ADICIONAR_MOTIVO_DE_INTERNACAO:
    case ERRO_AO_REMOVER_MOTIVO_DE_INTERNACAO:
    case ERRO_AO_RECUPERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO:
    case ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO:
    case ERRO_AO_ADICIONAR_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO:
    case ERRO_AO_REMOVER_MOTIVO_DE_IDA_AO_PRONTO_ATENDIMENTO:
    case ERRO_AO_RECUPERAR_ESTABELECIMENTO_DE_SAUDE_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_ESTABELECIMENTO_DE_SAUDE:
    case ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE:
    case ERRO_AO_LISTAR_ESTABELECIMENTOS_DE_SAUDE_DO_CADASTRO:
    case ERRO_AO_ADICIONAR_ESTABELECIMENTO_DE_SAUDE:
    case ERRO_AO_REMOVER_ESTABELECIMENTO_DE_SAUDE:
    case ERRO_AO_RECUPERAR_OPERADORA_PELO_IDENTIFICADOR:
    case ERRO_AO_LISTAR_OPERADORAS_DO_CADASTRO:
    case ERRO_AO_ALTERAR_OPERADORA:
    case ERRO_AO_LISTAR_OPERADORAS:
    case ERRO_AO_ADICIONAR_OPERADORA:
    case ERRO_AO_REMOVER_OPERADORA:
    case ERRO_AO_LISTAR_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ADICIONAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ALTERAR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_EXCLUIR_HORARIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ALTERAR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_EXCLUIR_SERIE_DE_HORARIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_IMPORTAR_PACIENTES:
    case ERRO_AO_LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_PROFISSOES_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_HORARIOS_LIVRES_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_ADICIONAR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_EXCLUIR_AGENDAMENTO_DO_WIZARD_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_RECORRENCIAS_DO_AGENDAMENTO:
    case ERRO_AO_ADICIONAR_TIPO_DE_AGENDAMENTO:
    case ERRO_AO_RECUPERAR_TIPO_DE_AGENDAMENTO_POR_IDENTIFICADOR:
    case ERRO_AO_SALVAR_TIPO_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO:
    case ERRO_AO_REMOVER_TIPO_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_IDAS_AO_PRONTO_ATENDIMENTO:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_INTERNACOES:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_REINTERNACOES:
    case ERRO_AO_RECUPERAR_PROGRAMA_PELO_IDENTIFICADOR:
    case ERRO_AO_ADICIONAR_PROGRAMA:
    case ERRO_AO_ALTERAR_PROGRAMA:
    case ERRO_AO_LISTAR_PROGRAMAS:
    case ERRO_AO_REMOVER_PROGRAMA:
    case ERRO_AO_ADICIONAR_PACIENTE:
    case ERRO_AO_ADICIONAR_PROGRAMA_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_TAG_NO_PACIENTE:
    case ERRO_AO_ALTERAR_PROGRAMA_DO_PACIENTE:
    case ERRO_AO_REMOVER_PROGRAMA_DO_PACIENTE:
    case ERRO_AO_RECUPERAR_PROGRAMA_DO_PACIENTE:
    case ERRO_AO_LISTAR_PACIENTES:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_PACIENTE:
    case ERRO_AO_RECUPERAR_PACIENTE_POR_IDENTIFICADOR:
    case ERRO_AO_REMOVER_TAG_DO_PACIENTE:
    case ERRO_AO_SALVAR_PACIENTE:
    case ERRO_AO_LISTAR_ENFERMEIROS_DO_PACIENTE:
    case ERRO_AO_LISTAR_MEDICOS_DO_PACIENTE:
    case ERRO_AO_LISTAR_CONSULTORES_DO_PACIENTE:
    case ERRO_AO_LISTAR_PSICOLOGOS_DO_PACIENTE:
    case ERRO_AO_LISTAR_TAGS_DE_PACIENTES:
    case ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PROGRAMAS:
    case ERRO_AO_LISTAR_OPERADORAS_DO_PACIENTE:
    case ERRO_AO_LISTAR_GRAUS_DE_RELACOES:
    case ERRO_AO_RECUPERAR_FOTO_DO_PACIENTE_POR_IDENTIFICADOR:
    case ERRO_AO_ADICIONAR_FOTO_DO_PACIENTE:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_ATENDIMENTOS:
    case ERRO_AO_FAZER_DOWNLOAD_DE_ATENDIMENTOS:
    case ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES:
    case ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES:
    case ERRO_AO_LISTAR_OPERADORAS_DO_RELATORIO_DE_PACIENTES:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES:
    case ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES:
    case ERRO_AO_LISTAR_ENFERMEIROS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA:
    case ERRO_AO_FAZER_DOWNLOAD_DE_PACIENTES_DO_PROGRAMA:
    case ERRO_AO_LISTAR_MEDICOS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA:
    case ERRO_AO_LISTAR_PROGRAMAS_DO_RELATORIO_RESUMO_DE_INTERNACOES:
    case ERRO_AO_GERAR_GRAFICO_DE_INTERNACOES_POR_FRATURA_DE_FEMUR_META_VERSUS_REALIZADO:
    case ERRO_AO_GERAR_GRAFICO_DE_REINTERNACOES_META_VERSUS_REALIZADO:
    case ERRO_AO_RECUPERAR_FERIADO_POR_IDENTIFICADOR:
    case ERRO_AO_SALVAR_FERIADO:
    case ERRO_AO_LISTAR_FERIADOS:
    case ERRO_AO_ADICIONAR_FERIADO:
    case ERRO_AO_EXCLUIR_FERIADO:
    case ERRO_AO_ADICIONAR_CONCENTRACAO_DO_MEDICAMENTO:
    case ERRO_AO_LISTAR_CONCENTRACOES_DO_MEDICAMENTO:
    case ERRO_AO_ALTERAR_CONCENTRACAO_DO_MEDICAMENTO:
    case ERRO_AO_REMOVER_CONCENTRACAO_DO_MEDICAMENTO:
    case ERRO_AO_REMOVER_MEDICAMENTO:
    case ERRO_AO_ALTERAR_MEDICAMENTO:
    case ERRO_AO_RECUPERAR_MEDICAMENTO_POR_IDENTIFICADOR:
    case ERRO_AO_LISTAR_MEDICAMENTOS:
    case ERRO_AO_ADICIONAR_MEDICAMENTO:
    case ERRO_AO_ATUALIZAR_LISTA_DOS_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO:
    case ERRO_AO_LISTAR_AGENDAMENTOS_AGUARDANDO_ATENDIMENTO:
    case ERRO_AO_LISTAR_AGENDAMENTOS_PARA_CONFIRMACAO_DE_AGENDAMENTO:
    case ERRO_AO_ADICIONAR_AGENDAMENTO:
    case ERRO_AO_CANCELAR_AGENDAMENTO:
    case ERRO_AO_CONFIRMAR_AGENDAMENTO:
    case ERRO_AO_EXCLUIR_AGENDAMENTO:
    case ERRO_AO_LISTAR_HORARIOS_LIVRES:
    case ERRO_AO_LISTAR_PACIENTES_DO_AGENDAMENTO:
    case ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO:
    case ERRO_AO_RECUPERAR_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_SALVAR_AGENDAMENTO:
    case ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DA_AGENDA:
    case ERRO_AO_RECUPERAR_AGENDA_SEMANAL_DO_PROFISSIONAL:
    case ERRO_AO_ALTERAR_BLOQUEIO_NA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_ALTERAR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_ALTERAR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_ALTERAR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_EXCLUIR_BLOQUEIO_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_EXCLUIR_SERIE_DE_BLOQUEIOS_DA_AGENDA_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_EXCLUIR_SERIE_DE_INSTRUCOES_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_EXCLUIR_INSTRUCAO_DE_AGENDAMENTO_DO_PROFISSIONAL_DE_SAUDE_SELECIONADO_DO_AGENDAMENTO:
    case ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DOS_AGENDAMENTOS:
    case ERRO_AO_LISTAR_AGENDAMENTOS:
    case ERRO_AO_LISTAR_STATUS_DOS_AGENDAMENTOS:
    case ERRO_AO_LISTAR_ASSOCIACOES_DO_CADASTRO:
    case ERRO_AO_LISTAR_ASSOCIACOES:
    case ERRO_AO_RECUPERAR_ASSOCIACAO_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_ASSOCIACAO:
    case ERRO_AO_ADICIONAR_ASSOCIACAO:
    case ERRO_AO_REMOVER_ASSOCIACAO:
    case ERRO_AO_ADICIONAR_ESPECIALIDADE:
    case ERRO_AO_ALTERAR_ESPECIALIDADE:
    case ERRO_AO_REMOVER_ESPECIALIDADE:
    case ERRO_AO_REMOVER_PROFISSAO:
    case ERRO_AO_ALTERAR_PROFISSAO:
    case ERRO_AO_ADICIONAR_PROFISSAO:
    case ERRO_AO_LISTAR_PROFISSOES_DO_CADASTRO:
    case ERRO_AO_LISTAR_AGENDAMENTOS_DO_DIA:
    case ERRO_AO_LISTAR_MOTIVOS_PARA_NAO_TER_SIDO_ATENDIDO:
    case ERRO_AO_LISTAR_VACINAS:
    case ERRO_AO_LISTAR_LOCAIS_DE_QUEDAS:
    case ERRO_AO_LISTAR_TIPOS_DE_INTERNACAO:
    case ERRO_AO_LISTAR_STATUS_DE_INTERNACOES:
    case ERRO_AO_LISTAR_STATUS_DE_IDA_AO_PRONTO_ATENDIMENTO:
    case ERRO_AO_LISTAR_MOTIVOS_DE_IDA_AO_PRONTO_ATENDIMENTO_DO_CADASTRO:
    case ERRO_AO_LISTAR_ANTIBIOTICOS:
    case ERRO_AO_LISTAR_MICRORGANISMOS:
    case ERRO_AO_RECUPERAR_CABECALHO_DO_PRONTUARIO:
    case ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS:
    case ERRO_AO_LISTAR_HISTORICO_DE_CAMPOS_DO_PRONTUARIO_DO_PACIENTE:
    case ERRO_AO_LISTAR_EMPRESAS_HABILITADAS:
    case ERRO_AO_ALTERAR_STATUS_DO_USUARIO:
    case ERRO_AO_LISTAR_MEDICAMENTOS_DO_PRONTUARIO:
    case ERRO_AO_LISTAR_POSOLOGIAS:
    case ERRO_AO_LISTAR_TAMANHOS_DE_PAGINAS:
    case ERRO_AO_FAZER_DOWNLOAD_DO_SUMARIO_DE_ALTA_DA_INTERNACAO:
    case ERRO_AO_LISTAR_NIVEIS_DE_SAUDE_RELATIVA:
    case ERRO_AO_LISTAR_PROFISSIONAIS_EXTERNOS:
    case ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE:
    case ERRO_AO_RECUPERAR_PROFISSIONAL_EXTERNO_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_PROFISSIONAL_EXTERNO:
    case ERRO_AO_ADICIONAR_PROFISSIONAL_EXTERNO:
    case ERRO_AO_REMOVER_PROFISSIONAL_EXTERNO:
    case ERRO_AO_LISTAR_ENCAMINHAMENTOS:
    case ERRO_AO_LISTAR_STATUS_DE_ENCAMINHAMENTO:
    case ERRO_AO_LISTAR_TIPOS_DE_ENCAMINHAMENTO:
    case ERRO_AO_RECUPERAR_VERSAO_DO_TIPO_DE_ATENDIMENTO:
    case ERRO_AO_RECUPERAR_TIPO_DE_ATENDIMENTO_POR_IDENTIFICADOR:
    case ERRO_AO_INICIAR_ATENDIMENTO:
    case ERRO_AO_RECUPERAR_ATENDIMENTO:
    case ERRO_AO_RECUPERAR_ATENDIMENTO_INICIADO:
    case ERRO_AO_CANCELAR_ATENDIMENTO:
    case ERRO_AO_FINALIZAR_ATENDIMENTO:
    case ERRO_AO_SALVAR_SECAO_DO_ATENDIMENTO:
    case ERRO_AO_FAZER_DOWNLOAD_DO_EXAME_DO_ATENDIMENTO:
    case ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_ZARIT:
    case ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_NA_ESCALA_ZARIT:
    case ERRO_AO_LISTAR_ORIENTACOES_DE_PAGINAS:
    case ERRO_AO_ADICIONAR_INFORMACOES:
    case ERRO_AO_LISTAR_RESULTADOS_HBSAG:
    case ERRO_AO_LISTAR_UNIDADES_DE_CALCIO_IONICO:
    case ERRO_AO_LISTAR_RESULTADOS_ANTI_HCV:
    case ERRO_AO_LISTAR_RESULTADOS_ANTI_HIV:
    case ERRO_AO_LISTAR_RESULTADOS_ANTI_HBC:
    case ERRO_AO_LISTAR_ATENDIMENTOS_INICIADOS:
    case ERRO_AO_LISTAR_ATENDIMENTOS_NAO_ASSINADOS:
    case ERRO_AO_LISTAR_RESPOSTAS_DO_CASP16:
    case ERRO_AO_ADICIONAR_TAG_NO_PACIENTE_DO_PRONTUARIO:
    case ERRO_AO_REMOVER_TAG_DO_PACIENTE_DO_PRONTUARIO:
    case ERRO_AO_LISTAR_PLANOS_DE_CUIDADO:
    case ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO:
    case ERRO_AO_ALTERAR_PLANO_DE_CUIDADO:
    case ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_PELO_IDENTIFICADOR:
    case ERRO_AO_REMOVER_PLANO_DE_CUIDADO:
    case ERRO_AO_LISTAR_PLANOS_DE_CUIDADO_DO_PACIENTE:
    case ERRO_AO_RECUPERAR_PLANO_DE_CUIDADO_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_PLANO_DE_CUIDADO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_PLANO_DE_CUIDADO_DO_PACIENTE:
    case ERRO_AO_REMOVER_PLANO_DE_CUIDADO_DO_PACIENTE:
    case ERRO_AO_LISTAR_MOTIVOS_DE_SAIDA_DOS_PLANOS_DE_CUIDADO:
    case ERRO_AO_LISTAR_ESTADOS_CIVIS:
    case ERRO_AO_LISTAR_ORIENTACOES:
    case ERRO_AO_ADICIONAR_ORIENTACAO:
    case ERRO_AO_ALTERAR_ORIENTACAO:
    case ERRO_AO_RECUPERAR_ORIENTACAO_PELO_IDENTIFICADOR:
    case ERRO_AO_REMOVER_ORIENTACAO:
    case ERRO_AO_LISTAR_TIPOS_DE_EXAME:
    case ERRO_AO_LISTAR_TIPOS_DE_AGENDAMENTO_DA_PESQUISA:
    case ERRO_AO_LISTAR_PROFISSIONAIS_DE_SAUDE_DE_SUGESTAO:
    case ERRO_AO_LISTAR_SUGESTOES_DE_PACIENTE:
    case ERRO_AO_LISTAR_TOKENS_DE_SUGESTAO:
    case ERRO_AO_LISTAR_TOKENS_DE_EXEMPLO:
    case ERRO_AO_LISTAR_COMPONENTES_COM_TEMPLATE:
    case ERRO_AO_ADICIONAR_TEMPLATE:
    case ERRO_AO_RECUPERAR_TEMPLATE_POR_IDENTIFICADOR:
    case ERRO_AO_SALVAR_TEMPLATE:
    case ERRO_AO_LISTAR_TEMPLATES:
    case ERRO_AO_EXCLUIR_TEMPLATE:
    case ERRO_AO_ADICIONAR_TEMPLATE_DO_PRONTUARIO:
    case ERRO_AO_LISTAR_CATEGORIAS_DO_TAREFAS:
    case ERRO_AO_LISTAR_TIPOS_DE_TAREFA:
    case ERRO_AO_LISTAR_PACIENTES_DO_TAREFAS:
    case ERRO_AO_LISTAR_URGENCIAS_DO_TAREFAS:
    case ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_TAREFAS:
    case ERRO_AO_LISTAR_PROFISSIONAIS_RESPONSAVEIS_DO_TAREFAS:
    case ERRO_AO_REGISTRAR_FIRESTORE_DE_ATIVIDADES_DA_TAREFA:
    case ERRO_AO_LISTAR_PACIENTES_DOS_FILTROS_DO_TAREFAS:
    case ERRO_AO_LISTAR_VACINAS_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_VACINA_DO_PACIENTE:
    case ERRO_AO_ALTERAR_VACINA_DO_PACIENTE:
    case ERRO_AO_REMOVER_VACINA_DO_PACIENTE:
    case ERRO_AO_LISTAR_QUEDAS_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_QUEDA_DO_PACIENTE:
    case ERRO_AO_ALTERAR_QUEDA_DO_PACIENTE:
    case ERRO_AO_REMOVER_QUEDA_DO_PACIENTE:
    case ERRO_AO_LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE:
    case ERRO_AO_REMOVER_IDA_AO_PRONTO_ATENDIMENTO_DO_PACIENTE:
    case ERRO_AO_LISTAR_INTERNACOES_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_INTERNACAO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_INTERNACAO_DO_PACIENTE:
    case ERRO_AO_REMOVER_INTERNACAO_DO_PACIENTE:
    case ERRO_AO_LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE:
    case ERRO_AO_REMOVER_INFECCAO_DO_TRATO_URINARIO_DO_PACIENTE:
    case ERRO_AO_LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE:
    case ERRO_AO_ALTERAR_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE:
    case ERRO_AO_REMOVER_AVALIACAO_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE:
    case ERRO_AO_LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE:
    case ERRO_AO_ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE:
    case ERRO_AO_REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE:
    case ERRO_AO_LISTAR_CASPS_16_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_CASP_16_DO_PACIENTE:
    case ERRO_AO_ALTERAR_CASP_16_DO_PACIENTE:
    case ERRO_AO_REMOVER_CASP_16_DO_PACIENTE:
    case ERRO_AO_LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_AVALIACAO_DA_SOLIDAO_DO_PACIENTE:
    case ERRO_AO_REMOVER_AVALIACAO_DA_SOLIDAO_DO_PACIENTE:
    case ERRO_AO_LISTAR_FREQUENCIAS_NA_ESCALA_DA_SOLIDAO:
    case ERRO_AO_RECUPERAR_AVALIACAO:
    case ERRO_AO_RESPONDER_AVALIACAO:
    case ERRO_AO_AUTENTICAR_MEDICO_NA_MEMED:
    case ERRO_AO_RECUPERAR_PRESCRICAO_PDF_NA_MEMED:
    case ERRO_AO_RECUPERAR_HISTORICO_PRESCRICAO_NA_MEMED:
    case ERRO_AO_REMOVER_PRESCRICAO_NA_MEMED:
    case ERRO_AO_LER_CERTIFICADOS_DIGITAIS_FISICOS:
    case ERRO_AO_INICIAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO:
    case ERRO_AO_RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO:
    case ERRO_AO_RECUPERAR_URL_AUTENTICACAO_DO_CERTIFICADO_EM_NUVEM:
    case ERRO_AO_FINALIZAR_ASSINATURA_DIGITAL_DO_ATENDIMENTO_COM_CERTIFICADO_FISICO:
    case ERRO_AO_ASSINAR_ATENDIMENTO_COM_CERTIFICADO_EM_NUVEM:
    case ERRO_AO_INICIAR_SESSAO_COM_CERTIFICADO_EM_NUVEM:
    case ERRO_AO_RECUPERAR_SESSAO_COM_CERTIFICADO_EM_NUVEM:
    case ERRO_AO_LISTAR_PROFISSOES_PARA_O_NPS:
    case ERRO_AO_HABILITAR_PROFISSOES_PARA_O_NPS:
    case ERRO_AO_DESABILITAR_PROFISSAO_PARA_O_NPS:
    case ERRO_AO_LISTAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS:
    case ERRO_AO_HABILITAR_TIPOS_DE_ATENDIMENTO_PARA_O_NPS:
    case ERRO_AO_DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_NPS:
    case ERRO_AO_LISTAR_AVALIACOES_RESPONDIDAS:
    case ERRO_AO_ALTERAR_STATUS_DE_UMA_RESPOSTA:
    case ERRO_AO_RECUPERAR_AVALIACAO_RESPONDIDA:
    case ERRO_AO_LISTAR_PACIENTES_DO_NPS:
    case ERRO_AO_LISTAR_PROFISSIONAIS_DO_NPS:
    case ERRO_AO_ADICIONAR_CONSULTA_EXTERNA_DO_PACIENTE:
    case ERRO_AO_ALTERAR_CONSULTA_EXTERNA_DO_PACIENTE:
    case ERRO_AO_LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE:
    case ERRO_AO_REMOVER_CONSULTA_EXTERNA_DO_PACIENTE:
    case ERRO_AO_ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA:
    case ERRO_AO_LISTAR_TIPOS_DE_CONSULTA_EXTERNA:
    case ERRO_AO_MARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA:
    case ERRO_AO_DESMARCAR_ITEM_NA_LISTA_DE_OPCOES_MULTIPLAS_DA_TAREFA:
    case ERRO_AO_DEFINIR_TEXTO_DA_TAREFA:
    case ERRO_AO_DEFINIR_TEXTO_LONGO_DA_TAREFA:
    case ERRO_AO_ADICIONAR_PRE_CADASTRO_DO_PACIENTE_DA_TAREFA:
    case ERRO_AO_DEFINIR_MOTIVO_DO_CANCELAMENTO_DA_TAREFA:
    case ERRO_AO_DEFINIR_NUMERO_INTEIRO_DA_TAREFA:
    case ERRO_AO_RECUPERAR_LISTA_DE_ESPERA_DO_PROFISSIONAL_DE_SAUDE:
    case ERRO_AO_ADICIONAR_PACIENTE_NA_LISTA_DE_ESPERA:
    case ERRO_AO_ALTERAR_PACIENTE_NA_LISTA_DE_ESPERA:
    case ERRO_AO_REMOVER_PACIENTE_NA_LISTA_DE_ESPERA:
    case ERRO_AO_DEFINIR_MOMENTO_DA_TAREFA:
    case ERRO_AO_LISTAR_MOTIVOS_DE_BLOQUEIO_DO_LOGIN:
    case ERRO_AO_LISTAR_USUARIOS_BLOQUEADOS:
    case ERRO_AO_ADICIONAR_CONVENIO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_CONVENIO_DO_PACIENTE:
    case ERRO_AO_REMOVER_CONVENIO_DO_PACIENTE:
    case ERRO_AO_RECUPERAR_CONVENIO_DO_PACIENTE:
    case ERRO_AO_LISTAR_CONVENIOS_DO_PACIENTE:
    case ERRO_AO_LISTAR_MOTIVOS_DE_CANCELAMENTO_DO_CONVENIO:
    case ERRO_AO_DEFINIR_DATA_DA_TAREFA:
    case ERRO_AO_DEFINIR_PRAZO_DA_TAREFA:
    case ERRO_AO_ALTERAR_CONFIGURACAO_DO_USUARIO:
    case ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO_LOGADO:
    case ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO_LOGADO:
    case ERRO_AO_RECUPERAR_CONFIGURACOES_DO_USUARIO:
    case ERRO_AO_RECUPERAR_CONFIGURACOES_VIGENTES_DO_USUARIO:
    case ERRO_AO_LISTAR_DURACOES_DAS_DORES:
    case ERRO_AO_LISTAR_INTENSIDADES_DAS_DORES:
    case ERRO_AO_LISTAR_LOCAIS_DAS_DORES:
    case ERRO_AO_LISTAR_TIPOS_DE_ATIVIDADE_FISICA_DE_LAZER:
    case ERRO_AO_LISTAR_TIPOS_DE_UNIDADE:
    case ERRO_AO_ADICIONAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE:
    case ERRO_AO_REMOVER_REGISTRO_DOCUMENTAL_DO_PSICOLOGO_DO_PACIENTE:
    case ERRO_AO_LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE:
    case ERRO_AO_LISTAR_MODALIDADES_DOS_TIPOS_DE_AGENDAMENTO:
    case ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES:
    case ERRO_AO_LISTAR_CELULAS_DO_RELATORIO_DE_PACIENTES_DO_PROGRAMA:
    case ERRO_AO_LISTAR_PREVISOES_DE_RETORNO:
    case ERRO_AO_RECUPERAR_FABRICANTE_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_FABRICANTE:
    case ERRO_AO_LISTAR_FABRICANTES:
    case ERRO_AO_LISTAR_FABRICANTES_DO_CADASTRO:
    case ERRO_AO_ADICIONAR_FABRICANTE:
    case ERRO_AO_REMOVER_FABRICANTE:
    case ERRO_AO_LISTAR_LOCAIS_DA_APLICACAO:
    case ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_ATUAL:
    case ERRO_AO_LISTAR_ESTADOS_DA_MEMORIA_COMPARADO_HA_UM_ANO_ATRAS:
    case ERRO_AO_RECUPERAR_DESENHO_DO_MINI_MENTAL:
    case ERRO_AO_LISTAR_MINI_MENTAIS:
    case ERRO_AO_ADICIONAR_MINI_MENTAL:
    case ERRO_AO_ALTERAR_MINI_MENTAL:
    case ERRO_AO_REMOVER_MINI_MENTAL:
    case ERRO_AO_LISTAR_PROXIMOS_AGENDAMENTOS_MEDICOS_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_INTERPRETACAO_MEDICA_DO_MINI_MENTAL:
    case ERRO_AO_REMOVER_INTERPRETACAO_MEDICA_DO_MINI_MENTAL:
    case ERRO_AO_LISTAR_RESPOSTAS_DO_PHQ9:
    case ERRO_AO_LISTAR_PHQS9:
    case ERRO_AO_ADICIONAR_PHQ9:
    case ERRO_AO_ALTERAR_PHQ9:
    case ERRO_AO_REMOVER_PHQ9:
    case ERRO_AO_LISTAR_SALAS_DA_UNIDADE:
    case ERRO_AO_ADICIONAR_SALA_NA_UNIDADE:
    case ERRO_AO_ALTERAR_SALA_DA_UNIDADE:
    case ERRO_AO_REMOVER_SALA_DA_UNIDADE:
    case ERRO_AO_RECUPERAR_SALA_DA_UNIDADE_PELO_IDENTIFICADOR:
    case ERRO_AO_ADICIONAR_MEDICAMENTO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_MEDICAMENTO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_STATUS_DO_MEDICAMENTO_DO_PACIENTE:
    case ERRO_AO_LISTAR_MEDICAMENTOS_DO_PACIENTE:
    case ERRO_AO_ADICIONAR_SETOR:
    case ERRO_AO_ALTERAR_SETOR:
    case ERRO_AO_REMOVER_SETOR:
    case ERRO_AO_LISTAR_SETORES:
    case ERRO_AO_LISTAR_SETORES_RESPONSAVEIS_DO_TAREFAS:
    case ERRO_AO_ALTERAR_POSICAO_DOS_MEDICAMENTOS_DO_PACIENTE: 
    case ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE:
    case ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE:
    case ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE: 
    case ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE: 
    case ERRO_AO_ADICIONAR_FERIADO_TRABALHADO_DO_PROFISSIONAL:
    case ERRO_AO_REMOVER_FERIADO_TRABALHADO_DO_PROFISSIONAL:
    case ERRO_AO_LISTAR_FERIADOS_TRABALHADOS_DO_PROFISSIONAL:
    case ERRO_AO_ADICIONAR_PEDIDO_DE_CONTATO:
    case ERRO_AO_ALTERAR_PEDIDO_DE_CONTATO:
    case ERRO_AO_REMOVER_PEDIDO_DE_CONTATO:
    case ERRO_AO_LISTAR_PEDIDOS_DE_CONTATO:
    case ERRO_AO_LISTAR_CIDADES_PARA_O_PEDIDO_DE_CONTATO:
    case ERRO_AO_LISTAR_PLANOS_DE_SAUDE_PARA_O_PEDIDO_DE_CONTATO:
    case ERRO_AO_LISTAR_MOTIVOS_DE_INATIVACAO_DO_PACIENTE:
    case ERRO_AO_LISTAR_CIDS:
    case ERRO_AO_LISTAR_CIDS_DO_CADASTRO:
    case ERRO_AO_RECUPERAR_CID_PELO_IDENTIFICADOR:
    case ERRO_AO_ALTERAR_CID:{
      console.log(action.erro)

      return {
        ...state,
        [action.type]: {
          detalhes: action.erro.stack,
          dataEHora: moment().format(moment.DATETIME_LOCAL_MS),
          mensagem: action.erro.message,
          parametros: action.parametros,
        }
      }
    }

    default: {
      return state
    }
  }
}