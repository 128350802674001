import api from '../../../api-major'

const adicionarQuestionarioDoIdosoDoPaciente = (identificador, questionario) => api.post(`/pacientes/${identificador}/questionarios-dos-idosos`, questionario)
const alterarQuestionarioDoIdosoDoPaciente = (identificador, questionario) => api.put(`/pacientes/${identificador}/questionarios-dos-idosos/${questionario.identificador}`, questionario)
const removerQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => api.delete(`/pacientes/${identificador}/questionarios-dos-idosos/${identificadorDoQuestionarioDoIdoso}`)
const listarQuestionariosDoIdosoDoPaciente = identificador => api.get(`/pacientes/${identificador}/questionarios-dos-idosos?ordem=-horario`)
const listarQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => api.get(`/pacientes/${identificador}/questionarios-dos-idosos/${identificadorDoQuestionarioDoIdoso}`)
const registrarEnvioDoQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => api.post(`/pacientes/${identificador}/questionarios-dos-idosos/${identificadorDoQuestionarioDoIdoso}/envio`)
const removerRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => api.delete(`/pacientes/${identificador}/questionarios-dos-idosos/${identificadorDoQuestionarioDoIdoso}/envio`)
const recuperarSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente = identificador => api.get(`/pacientes/${identificador}/questionarios-dos-idosos/sugestoes-de-respostas`)

const listarEscalasDeFragilidade = () => api.get('/questionarios-dos-idosos/escalas-de-fragilidades?ordem=gravidade')
const listarComQuemOsPacientesMoram = () => api.get('/questionarios-dos-idosos/com-quem-os-pacientes-moram?ordem=ordem')
const listarCognicoesDosPacientes = () => api.get('/questionarios-dos-idosos/cognicoes-dos-pacientes?ordem=gravidade')
const listarComorbidadesCardiovasculares = () => api.get('/questionarios-dos-idosos/comorbidades-cardiovasculares?ordem=ordem')
const listarComorbidadesEndocrinas = () => api.get('/questionarios-dos-idosos/comorbidades-endocrinas?ordem=ordem')
const listarComorbidadesGastrointestinais = () => api.get('/questionarios-dos-idosos/comorbidades-gastrointestinais?ordem=ordem&tamanhoDaPagina=1000')
const listarComorbidadesNeurologicas = () => api.get('/questionarios-dos-idosos/comorbidades-neurologicas?ordem=ordem')
const listarComorbidadesOncologicas = () => api.get('/questionarios-dos-idosos/comorbidades-oncologicas?ordem=ordem')
const listarComorbidadesOsteomusculares = () => api.get('/questionarios-dos-idosos/comorbidades-osteomusculares?ordem=ordem')
const listarComorbidadesPsiquiatricas = () => api.get('/questionarios-dos-idosos/comorbidades-psiquiatricas?ordem=ordem')
const listarComorbidadesPulmonares = () => api.get('/questionarios-dos-idosos/comorbidades-pulmonares?ordem=ordem')
const listarComorbidadesRenais = () => api.get('/questionarios-dos-idosos/comorbidades-renais?ordem=ordem')
const listarComorbidadesReumatologicas = () => api.get('/questionarios-dos-idosos/comorbidades-reumatologicas?ordem=ordem')
const listarCondicoesDosPacientes = () => api.get('/questionarios-dos-idosos/condicoes-dos-pacientes?ordem=ordem')
const listarDispositivosEmUsoDosPacientes = () => api.get('/questionarios-dos-idosos/dispositivos-em-uso-pelos-pacientes?ordem=ordem')
const listarEstadosDeDeambulacao = () => api.get('/questionarios-dos-idosos/estados-de-deambulacao?ordem=ordem')
const listarQuedasNosUltimosDozeMeses = () => api.get('/questionarios-dos-idosos/quedas-nos-ultimos-doze-meses?ordem=ordem')
const listarUsosDeMedicamentosPelosPacientes = () => api.get('/questionarios-dos-idosos/usos-de-medicamentos-pelos-pacientes?ordem=ordem')
const listarVacinasEmDia = () => api.get('/questionarios-dos-idosos/vacinas-em-dia?ordem=ordem')

export default { 
  adicionarQuestionarioDoIdosoDoPaciente,
  alterarQuestionarioDoIdosoDoPaciente,
  removerQuestionarioDoIdosoDoPaciente,
  listarQuestionariosDoIdosoDoPaciente,
  listarQuestionarioDoIdosoDoPaciente,
  registrarEnvioDoQuestionarioDoIdosoDoPaciente,
  removerRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente,
  recuperarSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente,
  listarEscalasDeFragilidade,
  listarComQuemOsPacientesMoram,
  listarCognicoesDosPacientes,
  listarComorbidadesCardiovasculares,
  listarComorbidadesEndocrinas,
  listarComorbidadesGastrointestinais,
  listarComorbidadesNeurologicas,
  listarComorbidadesOncologicas,
  listarComorbidadesOsteomusculares,
  listarComorbidadesPsiquiatricas ,
  listarComorbidadesPulmonares,
  listarComorbidadesRenais,
  listarComorbidadesReumatologicas,
  listarCondicoesDosPacientes,
  listarDispositivosEmUsoDosPacientes,
  listarEstadosDeDeambulacao,
  listarQuedasNosUltimosDozeMeses,
  listarUsosDeMedicamentosPelosPacientes,
  listarVacinasEmDia
}