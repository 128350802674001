import api from '../../../api'
import notificacao from '../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../notificacoes'
import {
  ADICIONOU_DIAGNOSTICO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  ALTEROU_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  LISTOU_DIAGNOSTICOS_DO_PACIENTE,
  ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE
} from '../../tipos'

const adicionouDiagnosticoDoPaciente = (identificadorDoPaciente, diagnostico) => ({
  type: ADICIONOU_DIAGNOSTICO_DO_PACIENTE,
  identificadorDoPaciente,
  diagnostico
})

const erroAoAdicionarDiagnosticoDoPaciente = erro => ({
  type: ERRO_AO_ADICIONAR_DIAGNOSTICO_DO_PACIENTE,
  erro
})

export const adicionarDiagnosticoDoPaciente = (identificadorDoPaciente, diagnostico) => async dispatch => {
  dispatch({ type: ADICIONAR_DIAGNOSTICO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarDiagnosticoDoPaciente(identificadorDoPaciente, diagnostico)

    dispatch(adicionouDiagnosticoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(listarDiagnosticosDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarDiagnosticoDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o diagnóstico do paciente.')))

    return false
  }
}

const alterouStatusDoDiagnosticoDoPaciente = (identificadorDoPaciente, identificadorDoDiagnostico, dados) => ({
  type: ALTEROU_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  identificadorDoPaciente,
  identificadorDoDiagnostico,
  dados
})

const erroAoAlterarStatusDoDiagnosticoDoPaciente = erro => ({
  type: ERRO_AO_ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE,
  erro
})

export const alterarStatusDoDiagnosticoDoPaciente = (identificadorDoPaciente, identificadorDoDiagnostico, dados) => async dispatch => {
  dispatch({ type: ALTERAR_STATUS_DO_DIAGNOSTICO_DO_PACIENTE })

  try {
    const resultado = await api.alterarStatusDoDiagnosticoDoPaciente(identificadorDoPaciente, identificadorDoDiagnostico, dados)

    dispatch(alterouStatusDoDiagnosticoDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(listarDiagnosticosDoPaciente(identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarStatusDoDiagnosticoDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o status do diagnóstico do paciente.')))

    return false
  }
}

const alterouPosicaoDosDiagnosticosDoPaciente = (identificadorDoPaciente, itensOrdenados) => ({
  type: ALTEROU_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  identificadorDoPaciente,
  itensOrdenados
})

const erroAoAlterarPosicaoDosDiagnosticosDoPaciente = erro => ({
  type: ERRO_AO_ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE,
  erro
})

export const alterarPosicaoDosDiagnosticosDoPaciente = (identificadorDoPaciente, identificadorDoDiagnostico, posicao, itensOrdenados) => async dispatch => {
  dispatch({ type: ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE })

  try {
    const resultado = await api.alterarPosicaoDosDiagnosticosDoPaciente(identificadorDoPaciente, identificadorDoDiagnostico, posicao)
    dispatch(alterouPosicaoDosDiagnosticosDoPaciente(identificadorDoPaciente, itensOrdenados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarPosicaoDosDiagnosticosDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar a posição do diagnóstico do paciente.')))
    return false
  }
}

const listouDiagnosticosDoPaciente = (identificadorDoPaciente, diagnosticosDoPaciente) => ({
  type: LISTOU_DIAGNOSTICOS_DO_PACIENTE,
  identificadorDoPaciente,
  diagnosticosDoPaciente
})

const erroAoListarDiagnosticosDoPaciente = erro => ({
  type: ERRO_AO_LISTAR_DIAGNOSTICOS_DO_PACIENTE,
  erro
})

export const listarDiagnosticosDoPaciente = (identificadorDoPaciente) => async dispatch => {
  dispatch({ type: LISTAR_DIAGNOSTICOS_DO_PACIENTE })

  try {
    const resultado = await api.listarDiagnosticosDoPaciente(identificadorDoPaciente)

    dispatch(listouDiagnosticosDoPaciente(identificadorDoPaciente, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarDiagnosticosDoPaciente(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os diagnósticos do paciente.')))
    
    return false
  }
}