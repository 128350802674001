import { connect } from 'react-redux'
import acoes from '../../acoes'
import Cids from './index'

const mapStateToProps = state => {
  const cidAtual = state.cids.itemAtual

  let cid = {
    codigo: '',
    nome: '',
    csap: false,
    habilitadoParaDiagnosticos: true,
    habilitadoParaInternacoes: true,
    habilitadoParaIdasAoProntoAtendimento: true,
    tags: [],
    observacoes: '',
  }

  if (cidAtual) {
    cid.identificador = cidAtual.identificador
    cid.codigo = cidAtual.codigo
    cid.nome = cidAtual.nome
    cid.csap = cidAtual.csap
    cid.habilitadoParaDiagnosticos = cidAtual.habilitadoParaDiagnosticos
    cid.habilitadoParaInternacoes = cidAtual.habilitadoParaInternacoes
    cid.habilitadoParaIdasAoProntoAtendimento = cidAtual.habilitadoParaIdasAoProntoAtendimento
    cid.tags = cidAtual.tags
    cid.observacoes = cidAtual.observacoes
  }

  return {
    filtros: state.cids.filtros,
    ordenacao: state.cids.ordenacao,
    paginaDeDados: state.cids.paginaDeDados,
    cid
  }
}

export default connect(mapStateToProps, acoes)(Cids)