import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import Spinner from '../../../spinner'
import { useProntuario } from '../../contexto'
import { Coluna, Tabela } from '../../../tabela-dnd'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import AdicionarDiagnostico from './adicionar'
import ConfirmarDiagnostico from './confirmar'
import RemoverDiagnostico from './remover'
import { LISTAR_DIAGNOSTICOS_DO_PACIENTE, ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE } from '../../../../acoes/tipos'

export default function Diagnosticos({ diagnosticosDoPaciente, identificadorDoPaciente }) {
  const {
    adicionarDiagnosticoDoPaciente,
    alterarStatusDoDiagnosticoDoPaciente,
    alterarPosicaoDosDiagnosticosDoPaciente,
    listarDiagnosticosDoPaciente,
    listarCids,
    cids
  } = useProntuario()

  const [exibirAdicionarDiagnostico, setExibirAdicionarDiagnostico] = useState(false)
  const [exibirConfirmarDiagnostico, setExibirConfirmarDiagnostico] = useState(false)
  const [exibirRemoverDiagnostico, setExibirRemoverDiagnostico] = useState(false)
  const [diagnosticoSelecionado, setDiagnosticoSelecionado] = useState(false)

  const podeListarCids = usePossuiAsPermissoes([permissoes.LISTAR_CIDS])
  const podeConfirmar = usePossuiAsPermissoes([permissoes.CONFIRMAR_DIAGNOSTICO_DO_PACIENTE])
  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_DIAGNOSTICO_DO_PACIENTE])

  useEffect(() => {
    if (diagnosticosDoPaciente === undefined) {
      listarDiagnosticosDoPaciente(identificadorDoPaciente)
    }
  }, [listarDiagnosticosDoPaciente, identificadorDoPaciente, diagnosticosDoPaciente])

  useEffect(() => {
    if (podeListarCids) { listarCids() }
  }, [listarCids, podeListarCids ])

  function abrirAdicionarDiagnostico() { setExibirAdicionarDiagnostico(true)}
  function fecharAdicionarDiagnostico() { setExibirAdicionarDiagnostico(false)}

  function abrirConfirmarDiagnostico(item) {
    setDiagnosticoSelecionado(item)
    setExibirConfirmarDiagnostico(true)
  }

  function fecharConfirmarDiagnostico() { setExibirConfirmarDiagnostico(false)}

  function abrirRemoverDiagnostico(item) {
    setDiagnosticoSelecionado(item)
    setExibirRemoverDiagnostico(true)
  }

  function fecharRemoverDiagnostico() { setExibirRemoverDiagnostico(false)}

  const alterarPosicaoDoDiagnostico = async (result) => {
    if (!result.destination) return

    const [diagnostico] = diagnosticosDoPaciente.splice(result.source.index, 1)
    const posicao = result.destination.index + 1
    diagnosticosDoPaciente.splice(result.destination.index, 0, diagnostico)

    if (diagnostico.posicao !== posicao) {
      const alterou = await alterarPosicaoDosDiagnosticosDoPaciente(identificadorDoPaciente, diagnostico.identificador, { posicao }, diagnosticosDoPaciente)

      if (!alterou) listarDiagnosticosDoPaciente(identificadorDoPaciente)
    }
  }

  const removerCidsAdicionadosENaoHabilitadosParaDiagnostico = (diagnosticosDoPaciente = [], cids = []) => {
    const cidsHabilitadosParaDiagnostico = cids.filter(x => x.habilitadoParaDiagnosticos)

    if (diagnosticosDoPaciente.length === 0) return cids

    const filtro = cid => {
      return diagnosticosDoPaciente.findIndex(x => x.diagnostico.identificador === cid.identificador) === -1
    }

    return cidsHabilitadosParaDiagnostico.filter(filtro)
  }

  const cidsFiltrados = removerCidsAdicionadosENaoHabilitadosParaDiagnostico(diagnosticosDoPaciente, cids)

  const retornarDadosDoProfissional = (usuario, profissao) => {
    let texto = profissao ? "Profissional: " : "Usuário: "

    if (usuario.tratamento) {
      texto += usuario.tratamento
      texto += " "
    }

    texto += usuario.nome

    if (profissao) {
      texto += " | "
      texto += profissao.nome
    }

    return texto
  }

  return (
    <>
      <Modal
        isOpen={exibirAdicionarDiagnostico}
        className='modal'
        contentLabel='Modal para adição de diagnóstico'
      >
        <AdicionarDiagnostico
          adicionarDiagnosticoDoPaciente={adicionarDiagnosticoDoPaciente}
          fechar={fecharAdicionarDiagnostico}
          cids={cidsFiltrados}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirConfirmarDiagnostico}
        className='modal'
        contentLabel='Modal para confirmação do diagnóstico'
      >
        <ConfirmarDiagnostico
          alterarStatusDoDiagnosticoDoPaciente={alterarStatusDoDiagnosticoDoPaciente}
          fechar={fecharConfirmarDiagnostico}
          diagnostico={diagnosticoSelecionado}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <Modal
        isOpen={exibirRemoverDiagnostico}
        className='modal'
        contentLabel='Modal para remoção do diagnóstico'
      >
        <RemoverDiagnostico
          alterarStatusDoDiagnosticoDoPaciente={alterarStatusDoDiagnosticoDoPaciente}
          fechar={fecharRemoverDiagnostico}
          diagnostico={diagnosticoSelecionado}
          identificadorDoPaciente={identificadorDoPaciente}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={5}>
        <Spinner operacoes={[LISTAR_DIAGNOSTICOS_DO_PACIENTE, ALTERAR_POSICAO_DOS_DIAGNOSTICOS_DO_PACIENTE]}>
          <Tabela
            acoes={[
              item => (
                <Permissao key='remover' permissoes={[permissoes.REMOVER_DIAGNOSTICO_CONFIRMADO_DO_PACIENTE, permissoes.REMOVER_DIAGNOSTICO_DO_PACIENTE]}>
                  <button
                    className='table-items__bt --remove'
                    title='Remover'
                    onClick={() => abrirRemoverDiagnostico(item)}
                    type='button'
                    key={`remove_${item.identificador}`}
                  >
                    <i className='icon icon-remove'></i>
                  </button>
                </Permissao>
              ),
              item => {
                const permissaoParaConfirmar = podeConfirmar && item.status !== 'Confirmado'
                return (
                  permissaoParaConfirmar &&
                  <button
                    className='table-items__bt --edit'
                    title='Confirmar'
                    onClick={() => abrirConfirmarDiagnostico(item)}
                    type='button'
                    key={`confirma_${item.identificador}`}
                  >
                    <i className='icon icon-checked'></i>
                  </button>
                )
              },
              item => (
                <div key='informacao'>
                  <button
                    data-tip
                    data-for={`info_${item.diagnostico.identificador}`}
                    key={`info_${item.identificador}`}
                    className='table-items__bt --edit'
                    type='button'
                  >
                    <i className='icon icon-info'></i>
                  </button>
                  <ReactTooltip
                    id={`info_${item.diagnostico.identificador}`}
                    place='top'
                    effect='solid'
                    type='info'
                    key={item.identificador}
                    className='tamanho-tooltip-diagnostico'
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'rgb(232, 232, 232)'
                      }}><strong>{item.diagnostico.codigo} - {item.diagnostico.nome}</strong></div>
                    <span>Status: {item.status}</span> <br />
                    <span>{retornarDadosDoProfissional(item.usuario, item.usuario.profissao)}</span> <br />
                    <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    {item.observacoes &&
                      <pre>
                        <strong>Observações: </strong>
                        {item.observacoes}
                      </pre>
                    }
                    {item.detalheDaMigracao &&
                      <div className='font-italic'>
                        <hr className="separator"></hr>
                        <pre>
                            {item.detalheDaMigracao}
                        </pre>
                      </div>
                    }

                  </ReactTooltip>
                </div>
              ),
            ]}
            chave='identificador'
            className='--secondary'
            dados={diagnosticosDoPaciente}
            exibirBotaoAdicionar={podeAdicionar && podeListarCids}
            acaoDoBotaoAdicionar={abrirAdicionarDiagnostico}
            mensagemTabelaVazia='Não existem registros de diagnósticos.'
            classNameAdicionar='col-xs-6 col-lg-4'
            nome='diagnosticos'
            handleOnDragEndDrop={alterarPosicaoDoDiagnostico}
          >
            <Coluna
              className='col-xs-6 col-lg-8'
              principal={true}
              exibirTitulo={true}
              nome='Diagnósticos'
              renderizar={item =>
                item.status !== 'Confirmado' ?
                <span style={{ color: '#e74c3c' }}>{item.diagnostico.codigo} - {item.diagnostico.nome}</span> :
                `${item.diagnostico.codigo} - ${item.diagnostico.nome}`
              }
            />
          </Tabela>
        </Spinner>
      </r-cell>
    </>
  )
}