import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { useUsuarioLogado } from '../seguranca/provedor-de-autenticacao'
import RotaComAutenticacao from '../seguranca/rota-com-autenticacao'
import Permissao, { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import AlertaGeral from '../alerta-geral'
import Atalhos from './atalhos'
import AtendimentosNaoAssinados from '../prontuario/nao-assinados'
import BarraDeMenu from './barra-de-menu'
import Empresa from '../empresa/com-redux'
import Usuarios from '../usuarios/com-redux'
import Medicamentos from '../medicamentos/com-redux'
import MotivosDeInternacao from '../motivo-de-internacao/com-redux'
import MotivosDeIdaAoProntoAtendimento from '../motivos-de-ida-ao-pronto-atendimento/com-redux'
import EstabelecimentosDeSaude from '../estabelecimentos-de-saude/com-redux'
import Celulas from '../celulas/com-redux'
import Fabricantes from '../fabricantes/com-redux'
import Operadoras from '../operadoras/com-redux'
import NovoAgendamento from '../agendamentos/wizard/com-redux'
import TiposDeAgendamento from '../tipos-de-agendamento/com-redux'
import RelatorioDeIdasAoProntoAtendimento from '../relatorios/idas-ao-pronto-atendimento/com-redux'
import RelatorioDeInternacoes from '../relatorios/internacoes/com-redux'
import RelatorioDeReinternacoes from '../relatorios/reinternacoes/com-redux'
import Programas from '../programas/com-redux'
import Pacientes from '../pacientes/com-redux'
import RelatorioDeAtendimentos from '../relatorios/atendimentos/com-redux'
import RelatorioDePacientes from '../relatorios/pacientes/com-redux'
import RelatorioDePacientesDoPrograma from '../relatorios/pacientes-do-programa/com-redux'
import ResumoDeInternacoes from '../relatorios/resumo-de-internacoes/com-redux'
import RelatorioHorasDosProfissionais from '../relatorios/horas-dos-profissionais/com-redux'
import Feriados from '../feriados/com-redux'
import AlterarSenha from '../alterar-senha/com-redux'
import SalaDeEspera from '../agendamentos/sala-de-espera/com-redux'
import ConfirmarAgendamento from '../agendamentos/confirmar-agendamento/com-redux'
import Agenda from '../agendamentos/agenda/com-redux'
import PesquisaDeAgendamentos from '../agendamentos/pesquisa-de-agendamentos/com-redux'
import InformarChegadaDoPaciente from '../agendamentos/informar-chegada-do-paciente/com-redux'
import Associacoes from '../associacoes/com-redux'
import Profissoes from '../profissoes/com-redux'
import Prontuario from '../prontuario/com-redux'
import ProfissionaisExternos from '../profissionais-externos/com-redux'
import Encaminhamentos from '../encaminhamentos/com-redux'
import GruposDeCuidado from '../grupos-de-cuidado/com-redux'
import PlanosDeCuidado from '../planos-de-cuidado/com-redux'
import Orientacoes from '../orientacoes/com-redux'
import Rodape from './rodape'
import Dashboards from '../relatorios/dashboards/com-redux'
import Templates from '../templates/com-redux'
import Tarefas from '../tarefas/com-redux'
import Nps from '../nps/habilitacoes/com-redux'
import UrlDeRetornoAposAutenticacaoEmNuvem from '../url-de-retorno-apos-autenticacao-em-nuvem'
import UsuariosBloqueados from '../usuarios-bloqueados/com-redux'
import EspecialidadesMedicasInternas from '../especialidades-medicas-internas/com-redux'
import BarraDeTarefas from '../barra-de-tarefas'
import ImportacaoDePacientes from '../importacoes/pacientes'
import Setores from '../setores/com-redux'
import PedidosDeContato from '../pedidos-de-contato/com-redux'
import BoxVideoConsulta from '../prontuario/componentes/video-consulta/box'
import Cids from '../cids/com-redux'

export default function Principal({ logout, match, alterarStatusDeConexaoDeRede, sistema, listarAtendimentosIniciados, listarRelatoriosDoPowerBI }) {
  const { usuario } = useUsuarioLogado()

  useEffect(() => {
    const verificarStatusDeConexaoDeRede = (event) => {
      if (event.type === 'offline') {
        alterarStatusDeConexaoDeRede(false)
      }
      if (event.type === 'online') {
        alterarStatusDeConexaoDeRede(true)
      }
    }

    window.addEventListener('online', verificarStatusDeConexaoDeRede)
    window.addEventListener('offline', verificarStatusDeConexaoDeRede)

    return () => {
      window.removeEventListener('online', verificarStatusDeConexaoDeRede)
      window.removeEventListener('offline', verificarStatusDeConexaoDeRede)
    }
  })

  const podeListarRelatoriosPowerBI = usePossuiAsPermissoes([permissoes.LISTAR_RELATORIOS_DO_POWER_BI])

  useEffect(() => {
    if (podeListarRelatoriosPowerBI) {
      listarRelatoriosDoPowerBI()
    }
  }, [listarRelatoriosDoPowerBI, podeListarRelatoriosPowerBI])

  const acessarAtendimentosIniciados = usePossuiAsPermissoes([
    permissoes.LISTAR_ATENDIMENTOS_DO_PACIENTE,
    permissoes.LISTAR_ATENDIMENTOS_INICIADOS_PELO_USUARIO
  ])

  useEffect(() => {
    let identificador = undefined
    if (acessarAtendimentosIniciados) {
      listarAtendimentosIniciados()
      identificador = setInterval(() => listarAtendimentosIniciados(), 300000)
    }

    return () => clearInterval(identificador)
  }, [listarAtendimentosIniciados, acessarAtendimentosIniciados])

  if (!usuario || !usuario.empresa || !usuario.unidade) {
    return <div className='form is-loading' />
  }

  return (
    <>      
      <BarraDeMenu logout={logout} statusDoSistema={sistema.status} />
      <AlertaGeral />
      <BoxVideoConsulta />
      <div className='sidebar'>
        <main>
          <Switch>
            <RotaComAutenticacao path={match.path} exact component={Atalhos} />
            <RotaComAutenticacao
              path={`/configuracoes/empresa`}
              render={props => <Permissao permissoes={['RECUPERAR_EMPRESA_DO_USUARIO']}><Empresa {...props} /></Permissao>}
            />
            <RotaComAutenticacao path={`/configuracoes/usuarios`} component={Usuarios} />
            <RotaComAutenticacao path={`/cadastros/medicamentos`} component={Medicamentos} />
            <RotaComAutenticacao path={`/cadastros/motivos-de-internacao`} component={MotivosDeInternacao} />
            <RotaComAutenticacao path={`/cadastros/motivos-de-ida-ao-pronto-atendimento`} component={MotivosDeIdaAoProntoAtendimento} />
            <RotaComAutenticacao path={`/cadastros/estabelecimentos-de-saude`} component={EstabelecimentosDeSaude} />
            <RotaComAutenticacao path={`/cadastros/celulas`} component={Celulas} />
            <RotaComAutenticacao path={`/cadastros/fabricantes`} component={Fabricantes} />
            <RotaComAutenticacao path={`/cadastros/operadoras`} component={Operadoras} />
            <RotaComAutenticacao path={`/cadastros/programas`} component={Programas} />
            <RotaComAutenticacao path={`/agendamentos/tipos-de-agendamento`} component={TiposDeAgendamento} />
            <RotaComAutenticacao path={`/agendamentos/novo-agendamento`} component={NovoAgendamento} />
            <RotaComAutenticacao path={`/agendamentos/confirmar-agendamento`} component={ConfirmarAgendamento} />
            <RotaComAutenticacao path={`/agendamentos/pesquisa-de-agendamentos`} component={PesquisaDeAgendamentos} />
            <RotaComAutenticacao path={`/agendamentos/informar-chegada-do-paciente`} component={InformarChegadaDoPaciente} />
            <RotaComAutenticacao path={`/relatorios/idas-ao-pronto-atendimento`} component={RelatorioDeIdasAoProntoAtendimento} />
            <RotaComAutenticacao path={`/relatorios/internacoes`} component={RelatorioDeInternacoes} />
            <RotaComAutenticacao path={`/relatorios/reinternacoes`} component={RelatorioDeReinternacoes} />
            <RotaComAutenticacao path={`/cadastros/pacientes/:identificador/prontuario/:identificadorDoAtendimento/confirmacao`} exact component={Prontuario} />
            <RotaComAutenticacao path={`/cadastros/pacientes/:identificador/prontuario/:identificadorDoAtendimento`} exact component={Prontuario} />
            <RotaComAutenticacao path={`/cadastros/pacientes/:identificador/prontuario/novo/origem/:identificadorDoAgendamento`} exact component={Prontuario} />
            <RotaComAutenticacao path={`/cadastros/pacientes/:identificador/prontuario`} component={Prontuario} />
            <RotaComAutenticacao path={`/cadastros/pacientes`} component={Pacientes} />
            <RotaComAutenticacao path={`/relatorios/dashboards`} component={Dashboards} />
            <RotaComAutenticacao path={`/relatorios/atendimentos`} component={RelatorioDeAtendimentos} />
            <RotaComAutenticacao path={`/relatorios/pacientes`} component={RelatorioDePacientes} />
            <RotaComAutenticacao path={`/relatorios/pacientes-do-programa`} component={RelatorioDePacientesDoPrograma} />
            <RotaComAutenticacao path={`/relatorios/resumo-de-internacoes`} component={ResumoDeInternacoes} />
            <RotaComAutenticacao path={`/relatorios/horas-dos-profissionais`} component={RelatorioHorasDosProfissionais} />
            <RotaComAutenticacao path={`/configuracoes/feriados`} component={Feriados} />
            <RotaComAutenticacao path={`/configuracoes/alterar-senha`} exact component={AlterarSenha} />
            <RotaComAutenticacao path={`/atendimentos/sala-de-espera`} component={SalaDeEspera} />
            <RotaComAutenticacao path={`/atendimentos/nao-assinados`} component={AtendimentosNaoAssinados} />
            <RotaComAutenticacao path={`/assinatura-em-nuvem`} component={UrlDeRetornoAposAutenticacaoEmNuvem} />
            <RotaComAutenticacao path={`/encaminhamentos`} component={Encaminhamentos} />
            <RotaComAutenticacao path={`/agendamentos/agenda`} component={Agenda} />
            <RotaComAutenticacao path={`/cadastros/associacoes`} component={Associacoes} />
            <RotaComAutenticacao path={`/cadastros/profissoes`} component={Profissoes} />
            <RotaComAutenticacao path={`/cadastros/profissionais-externos`} component={ProfissionaisExternos} />
            <RotaComAutenticacao path={`/cadastros/cids`} component={Cids} />
            <RotaComAutenticacao path={`/cadastros/grupos-de-cuidado`} component={GruposDeCuidado} />
            <RotaComAutenticacao path={`/cadastros/planos-de-cuidado`} component={PlanosDeCuidado} />
            <RotaComAutenticacao path={`/cadastros/orientacoes`} component={Orientacoes} />
            <RotaComAutenticacao path={`/configuracoes/templates`} component={Templates} />
            <RotaComAutenticacao path={`/tarefas/:identificadorDoTipoDeTarefa?/(tarefa)?/:identificador?`} component={Tarefas} />
            <RotaComAutenticacao path={`/configuracoes/nps`} component={Nps} />
            <RotaComAutenticacao path={`/configuracoes/usuarios-bloqueados`} component={UsuariosBloqueados} />
            <RotaComAutenticacao path={`/configuracoes/especialidades-medicas-internas`} component={EspecialidadesMedicasInternas} />
            <RotaComAutenticacao path={`/configuracoes/importacao-de-pacientes`} component={ImportacaoDePacientes} />
            <RotaComAutenticacao path={`/configuracoes/setores`} component={Setores} />
            <RotaComAutenticacao path={`/configuracoes/pedidos-de-contato`} component={PedidosDeContato} />
          </Switch>
        </main>
        <BarraDeTarefas />
      </div>
      <Rodape />
    </>
  )
}