import React from 'react'
import avatar from '../../design/img/no-thumb.png'

export const Foto = ({ foto }) => {
  return (    
    <div className='foto'>
      <div className='foto-images'>
        <div className='foto-image'>
          <img src={foto || avatar} alt='foto-do-paciente' />
        </div>
      </div>
    </div>
  )
}