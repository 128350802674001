import api from '../../../../api'
import notificacao from '../../../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../../../notificacoes'
import {
  ADICIONOU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ALTEROU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REMOVEU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_REMOVER_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REMOVER_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  LISTOU_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  LISTOU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_LISTAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  LISTAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REGISTROU_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REMOVEU_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_REMOVER_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  REMOVER_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  LISTOU_ESCALAS_DE_FRAGILIDADE,
  ERRO_AO_LISTAR_ESCALAS_DE_FRAGILIDADE,
  LISTAR_ESCALAS_DE_FRAGILIDADE,
  LISTOU_COM_QUEM_OS_PACIENTES_MORAM,
  ERRO_AO_LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
  LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
  LISTOU_COGNICOES_DOS_PACIENTES,
  ERRO_AO_LISTAR_COGNICOES_DOS_PACIENTES,
  LISTAR_COGNICOES_DOS_PACIENTES,
  LISTOU_COMORBIDADES_CARDIOVASCULARES,
  ERRO_AO_LISTAR_COMORBIDADES_CARDIOVASCULARES,
  LISTAR_COMORBIDADES_CARDIOVASCULARES,
  LISTOU_COMORBIDADES_ENDOCRINAS,
  ERRO_AO_LISTAR_COMORBIDADES_ENDOCRINAS,
  LISTAR_COMORBIDADES_ENDOCRINAS,
  LISTOU_COMORBIDADES_GASTROINTESTINAIS,
  ERRO_AO_LISTAR_COMORBIDADES_GASTROINTESTINAIS,
  LISTAR_COMORBIDADES_GASTROINTESTINAIS,
  LISTOU_COMORBIDADES_NEUROLOGICAS,
  ERRO_AO_LISTAR_COMORBIDADES_NEUROLOGICAS,
  LISTAR_COMORBIDADES_NEUROLOGICAS,
  LISTOU_COMORBIDADES_ONCOLOGICAS,
  ERRO_AO_LISTAR_COMORBIDADES_ONCOLOGICAS,
  LISTAR_COMORBIDADES_ONCOLOGICAS,
  LISTOU_COMORBIDADES_OSTEOMUSCULARES,
  ERRO_AO_LISTAR_COMORBIDADES_OSTEOMUSCULARES,
  LISTAR_COMORBIDADES_OSTEOMUSCULARES,
  ERRO_AO_LISTAR_COMORBIDADES_PSIQUIATRICAS,
  LISTOU_COMORBIDADES_PSIQUIATRICAS,
  LISTAR_COMORBIDADES_PSIQUIATRICAS,
  LISTOU_COMORBIDADES_PULMONARES,
  ERRO_AO_LISTAR_COMORBIDADES_PULMONARES,
  LISTAR_COMORBIDADES_PULMONARES,
  LISTOU_COMORBIDADES_RENAIS,
  ERRO_AO_LISTAR_COMORBIDADES_RENAIS,
  LISTAR_COMORBIDADES_RENAIS,
  LISTOU_COMORBIDADES_REUMATOLOGICAS,
  ERRO_AO_LISTAR_COMORBIDADES_REUMATOLOGICAS,
  LISTAR_COMORBIDADES_REUMATOLOGICAS,
  LISTOU_CONDICOES_DOS_PACIENTES,
  ERRO_AO_LISTAR_CONDICOES_DOS_PACIENTES,
  LISTAR_CONDICOES_DOS_PACIENTES,
  LISTOU_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  ERRO_AO_LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  LISTOU_ESTADOS_DE_DEAMBULACAO,
  ERRO_AO_LISTAR_ESTADOS_DE_DEAMBULACAO,
  LISTAR_ESTADOS_DE_DEAMBULACAO,
  LISTOU_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  ERRO_AO_LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  LISTOU_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  ERRO_AO_LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  LISTOU_VACINAS_EM_DIA,
  ERRO_AO_LISTAR_VACINAS_EM_DIA,
  LISTAR_VACINAS_EM_DIA,
  RECUPERAR_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  RECUPEROU_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  ERRO_AO_RECUPERAR_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE
} from '../../../tipos'

const adicionouQuestionarioDoIdosoDoPaciente = (identificador, questionario) => ({
  type: ADICIONOU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  questionario
})

const erroAoAdicionarQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const adicionarQuestionarioDoIdosoDoPaciente = (identificador, questionario) => async dispatch => {
  dispatch({ type: ADICIONAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.adicionarQuestionarioDoIdosoDoPaciente(identificador, questionario)

    dispatch(adicionouQuestionarioDoIdosoDoPaciente(identificador, questionario))
    dispatch(listarQuestionariosDoIdosoDoPaciente(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarQuestionarioDoIdosoDoPaciente(erro, { identificador, questionario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar o Questionário do Idoso do paciente.')))

    return false
  }
}

const alterouQuestionarioDoIdosoDoPaciente = (identificador, questionario) => ({
  type: ALTEROU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  questionario
})

const erroAoAlterarQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const alterarQuestionarioDoIdosoDoPaciente = (identificador, questionario) => async dispatch => {
  dispatch({ type: ALTERAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.alterarQuestionarioDoIdosoDoPaciente(identificador, questionario)

    dispatch(alterouQuestionarioDoIdosoDoPaciente(identificador, questionario))
    dispatch(listarQuestionariosDoIdosoDoPaciente(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAlterarQuestionarioDoIdosoDoPaciente(erro, { identificador, questionario }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível alterar o Questionário do Idoso do paciente.')))

    return false
  }
}

const removeuQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => ({
  type: REMOVEU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  identificadorDoQuestionarioDoIdoso
})

const erroAoRemoverQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const removerQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => async dispatch => {
  dispatch({ type: REMOVER_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.removerQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso)

    dispatch(removeuQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso))
    dispatch(listarQuestionariosDoIdosoDoPaciente(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverQuestionarioDoIdosoDoPaciente(erro, { identificador, identificadorDoQuestionarioDoIdoso }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível remover o Questionário do Idoso do paciente.')))

    return false
  }
}

const listouQuestionariosDoIdosoDoPaciente = (identificador, questionarios) => ({
  type: LISTOU_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  identificador,
  questionarios
})

const erroAoListarQuestionariosDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const listarQuestionariosDoIdosoDoPaciente = identificador => async dispatch => {
  dispatch({ type: LISTAR_QUESTIONARIOS_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.listarQuestionariosDoIdosoDoPaciente(identificador)

    dispatch(listouQuestionariosDoIdosoDoPaciente(identificador, resultado.data.dados.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarQuestionariosDoIdosoDoPaciente(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os Questionários do Idoso do paciente.')))
  }
}

const listouQuestionarioDoIdosoDoPaciente = (identificador, questionario) => ({
  type: LISTOU_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  questionario
})

const erroAoListarQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const listarQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => async dispatch => {
  dispatch({ type: LISTAR_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.listarQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso)

    dispatch(listouQuestionarioDoIdosoDoPaciente(identificador, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarQuestionarioDoIdosoDoPaciente(erro, { identificador, identificadorDoQuestionarioDoIdoso }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar o Questionário do Idoso do paciente.')))
  }
}

const recuperouSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente = (identificador, sugestoes) => ({
  type: RECUPEROU_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  sugestoes
})

const erroAoRecuperarSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_RECUPERAR_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const recuperarSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente = identificador => async dispatch => {
  dispatch({ type: RECUPERAR_SUGESTOES_DE_RESPOSTAS_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.recuperarSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente(identificador)

    dispatch(recuperouSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente(identificador, resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoRecuperarSugestoesDeRespostasDoQuestionarioDoIdosoDoPaciente(erro, { identificador }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível recuperar as sugestões de respostas do Questionário do Idoso do paciente.')))
  }
}

const registrouEnvioDoQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => ({
  type: REGISTROU_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  identificadorDoQuestionarioDoIdoso
})

const erroAoRegistrarEnvioDoQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const registrarEnvioDoQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => async dispatch => {
  dispatch({ type: REGISTRAR_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.registrarEnvioDoQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso)

    dispatch(registrouEnvioDoQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso))
    dispatch(listarQuestionariosDoIdosoDoPaciente(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRegistrarEnvioDoQuestionarioDoIdosoDoPaciente(erro, { identificador, identificadorDoQuestionarioDoIdoso }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível registrar o envio do questionário do idoso do paciente.')))

    return false
  }
}

const removeuRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => ({
  type: REMOVEU_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  identificador,
  identificadorDoQuestionarioDoIdoso
})

const erroAoRemoverRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente = (erro, parametros) => ({
  type: ERRO_AO_REMOVER_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE,
  erro,
  parametros
})

export const removerRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente = (identificador, identificadorDoQuestionarioDoIdoso) => async dispatch => {
  dispatch({ type: REMOVER_REGISTRO_DE_ENVIO_DO_QUESTIONARIO_DO_IDOSO_DO_PACIENTE })

  try {
    const resultado = await api.removerRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso)

    dispatch(removeuRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente(identificador, identificadorDoQuestionarioDoIdoso))
    dispatch(listarQuestionariosDoIdosoDoPaciente(identificador))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoRemoverRegistroDeEnvioDoQuestionarioDoIdosoDoPaciente(erro, { identificador, identificadorDoQuestionarioDoIdoso }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível cancelar o registro de envio do questionário do idoso do paciente.')))

    return false
  }
}

const listouEscalasDeFragilidade = escalas => ({
  type: LISTOU_ESCALAS_DE_FRAGILIDADE,
  escalas
})

const erroAoListarEscalasDeFragilidade = erro => ({
  type: ERRO_AO_LISTAR_ESCALAS_DE_FRAGILIDADE,
  erro
})

export const listarEscalasDeFragilidade = () => async dispatch => {
  dispatch({ type: LISTAR_ESCALAS_DE_FRAGILIDADE })

  try {
    const resultado = await api.listarEscalasDeFragilidade()

    dispatch(listouEscalasDeFragilidade(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEscalasDeFragilidade(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as escalas de fragilidade.')))
  }
}

const listouComQuemOsPacientesMoram = dados => ({
  type: LISTOU_COM_QUEM_OS_PACIENTES_MORAM,
  dados
})

const erroAoListarComQuemOsPacientesMoram = erro => ({
  type: ERRO_AO_LISTAR_COM_QUEM_OS_PACIENTES_MORAM,
  erro
})

export const listarComQuemOsPacientesMoram = () => async dispatch => {
  dispatch({ type: LISTAR_COM_QUEM_OS_PACIENTES_MORAM })

  try {
    const resultado = await api.listarComQuemOsPacientesMoram()

    dispatch(listouComQuemOsPacientesMoram(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComQuemOsPacientesMoram(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar com quem os pacientes moram.')))
  }
}

const listouCognicoesDosPacientes = dados => ({
  type: LISTOU_COGNICOES_DOS_PACIENTES,
  dados
})

const erroAoListarCognicoesDosPacientes = erro => ({
  type: ERRO_AO_LISTAR_COGNICOES_DOS_PACIENTES,
  erro
})

export const listarCognicoesDosPacientes = () => async dispatch => {
  dispatch({ type: LISTAR_COGNICOES_DOS_PACIENTES })

  try {
    const resultado = await api.listarCognicoesDosPacientes()

    dispatch(listouCognicoesDosPacientes(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCognicoesDosPacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as cognições dos pacientes.')))
  }
}

const listouComorbidadesCardiovasculares = dados => ({
  type: LISTOU_COMORBIDADES_CARDIOVASCULARES,
  dados
})

const erroAoListarComorbidadesCardiovasculares = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_CARDIOVASCULARES,
  erro
})

export const listarComorbidadesCardiovasculares = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_CARDIOVASCULARES })

  try {
    const resultado = await api.listarComorbidadesCardiovasculares()

    dispatch(listouComorbidadesCardiovasculares(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesCardiovasculares(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades cardiovasculares.')))
  }
}

const listouComorbidadesEndocrinas = dados => ({
  type: LISTOU_COMORBIDADES_ENDOCRINAS,
  dados
})

const erroAoListarComorbidadesEndocrinas = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_ENDOCRINAS,
  erro
})

export const listarComorbidadesEndocrinas = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_ENDOCRINAS })

  try {
    const resultado = await api.listarComorbidadesEndocrinas()

    dispatch(listouComorbidadesEndocrinas(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesEndocrinas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades endócrinas.')))
  }
}

const listouComorbidadesGastrointestinais = dados => ({
  type: LISTOU_COMORBIDADES_GASTROINTESTINAIS,
  dados
})

const erroAoListarComorbidadesGastrointestinais = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_GASTROINTESTINAIS,
  erro
})

export const listarComorbidadesGastrointestinais = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_GASTROINTESTINAIS })

  try {
    const resultado = await api.listarComorbidadesGastrointestinais()

    dispatch(listouComorbidadesGastrointestinais(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesGastrointestinais(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades gastrointestinais.')))
  }
}

const listouComorbidadesNeurologicas = dados => ({
  type: LISTOU_COMORBIDADES_NEUROLOGICAS,
  dados
})

const erroAoListarComorbidadesNeurologicas = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_NEUROLOGICAS,
  erro
})

export const listarComorbidadesNeurologicas = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_NEUROLOGICAS })

  try {
    const resultado = await api.listarComorbidadesNeurologicas()

    dispatch(listouComorbidadesNeurologicas(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesNeurologicas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades neurológicas.')))
  }
}

const listouComorbidadesOncologicas = dados => ({
  type: LISTOU_COMORBIDADES_ONCOLOGICAS,
  dados
})

const erroAoListarComorbidadesOncologicas = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_ONCOLOGICAS,
  erro
})

export const listarComorbidadesOncologicas = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_ONCOLOGICAS })

  try {
    const resultado = await api.listarComorbidadesOncologicas()

    dispatch(listouComorbidadesOncologicas(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesOncologicas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades oncológicas.')))
  }
}

const listouComorbidadesOsteomusculares = dados => ({
  type: LISTOU_COMORBIDADES_OSTEOMUSCULARES,
  dados
})

const erroAoListarComorbidadesOsteomusculares = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_OSTEOMUSCULARES,
  erro
})

export const listarComorbidadesOsteomusculares = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_OSTEOMUSCULARES })

  try {
    const resultado = await api.listarComorbidadesOsteomusculares()

    dispatch(listouComorbidadesOsteomusculares(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesOsteomusculares(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades osteomusculares.')))
  }
}

const listouComorbidadesPsiquiatricas = dados => ({
  type: LISTOU_COMORBIDADES_PSIQUIATRICAS,
  dados
})

const erroAoListarComorbidadesPsiquiatricas = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_PSIQUIATRICAS,
  erro
})

export const listarComorbidadesPsiquiatricas = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_PSIQUIATRICAS })

  try {
    const resultado = await api.listarComorbidadesPsiquiatricas()

    dispatch(listouComorbidadesPsiquiatricas(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesPsiquiatricas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades psiquiátricas.')))
  }
}

const listouComorbidadesPulmonares = dados => ({
  type: LISTOU_COMORBIDADES_PULMONARES,
  dados
})

const erroAoListarComorbidadesPulmonares = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_PULMONARES,
  erro
})

export const listarComorbidadesPulmonares = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_PULMONARES })

  try {
    const resultado = await api.listarComorbidadesPulmonares()

    dispatch(listouComorbidadesPulmonares(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesPulmonares(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades pulmonares.')))
  }
}

const listouComorbidadesRenais = dados => ({
  type: LISTOU_COMORBIDADES_RENAIS,
  dados
})

const erroAoListarComorbidadesRenais = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_RENAIS,
  erro
})

export const listarComorbidadesRenais = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_RENAIS })

  try {
    const resultado = await api.listarComorbidadesRenais()

    dispatch(listouComorbidadesRenais(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesRenais(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades renais.')))
  }
}

const listouComorbidadesReumatologicas = dados => ({
  type: LISTOU_COMORBIDADES_REUMATOLOGICAS,
  dados
})

const erroAoListarComorbidadesReumatologicas = erro => ({
  type: ERRO_AO_LISTAR_COMORBIDADES_REUMATOLOGICAS,
  erro
})

export const listarComorbidadesReumatologicas = () => async dispatch => {
  dispatch({ type: LISTAR_COMORBIDADES_REUMATOLOGICAS })

  try {
    const resultado = await api.listarComorbidadesReumatologicas()

    dispatch(listouComorbidadesReumatologicas(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarComorbidadesReumatologicas(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as comorbidades reumatológicas.')))
  }
}

const listouCondicoesDosPacientes = dados => ({
  type: LISTOU_CONDICOES_DOS_PACIENTES,
  dados
})

const erroAoListarCondicoesDosPacientes = erro => ({
  type: ERRO_AO_LISTAR_CONDICOES_DOS_PACIENTES,
  erro
})

export const listarCondicoesDosPacientes = () => async dispatch => {
  dispatch({ type: LISTAR_CONDICOES_DOS_PACIENTES })

  try {
    const resultado = await api.listarCondicoesDosPacientes()

    dispatch(listouCondicoesDosPacientes(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarCondicoesDosPacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as condições dos pacientes.')))
  }
}

const listouDispositivosEmUsoDosPacientes = dados => ({
  type: LISTOU_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  dados
})

const erroAoListarDispositivosEmUsoDosPacientes = erro => ({
  type: ERRO_AO_LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES,
  erro
})

export const listarDispositivosEmUsoDosPacientes = () => async dispatch => {
  dispatch({ type: LISTAR_DISPOSITIVOS_EM_USO_DOS_PACIENTES })

  try {
    const resultado = await api.listarDispositivosEmUsoDosPacientes()

    dispatch(listouDispositivosEmUsoDosPacientes(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarDispositivosEmUsoDosPacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os dispositivos em uso pelos pacientes.')))
  }
}

const listouEstadosDeDeambulacao = dados => ({
  type: LISTOU_ESTADOS_DE_DEAMBULACAO,
  dados
})

const erroAoListarEstadosDeDeambulacao = erro => ({
  type: ERRO_AO_LISTAR_ESTADOS_DE_DEAMBULACAO,
  erro
})

export const listarEstadosDeDeambulacao = () => async dispatch => {
  dispatch({ type: LISTAR_ESTADOS_DE_DEAMBULACAO })

  try {
    const resultado = await api.listarEstadosDeDeambulacao()

    dispatch(listouEstadosDeDeambulacao(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarEstadosDeDeambulacao(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os estados de deambulação.')))
  }
}

const listouQuedasNosUltimosDozeMeses = dados => ({
  type: LISTOU_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  dados
})

const erroAoListarQuedasNosUltimosDozeMeses = erro => ({
  type: ERRO_AO_LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES,
  erro
})

export const listarQuedasNosUltimosDozeMeses = () => async dispatch => {
  dispatch({ type: LISTAR_QUEDAS_NOS_ULTIMOS_DOZE_MESES })

  try {
    const resultado = await api.listarQuedasNosUltimosDozeMeses()

    dispatch(listouQuedasNosUltimosDozeMeses(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarQuedasNosUltimosDozeMeses(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as quedas nos últimos doze meses.')))
  }
}

const listouUsosDeMedicamentosPelosPacientes = dados => ({
  type: LISTOU_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  dados
})

const erroAoListarUsosDeMedicamentosPelosPacientes = erro => ({
  type: ERRO_AO_LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES,
  erro
})

export const listarUsosDeMedicamentosPelosPacientes = () => async dispatch => {
  dispatch({ type: LISTAR_USOS_DE_MEDICAMENTOS_PELOS_PACIENTES })

  try {
    const resultado = await api.listarUsosDeMedicamentosPelosPacientes()

    dispatch(listouUsosDeMedicamentosPelosPacientes(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarUsosDeMedicamentosPelosPacientes(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os usos de medicamentos pelos pacientes.')))
  }
}

const listouVacinasEmDia = dados => ({
  type: LISTOU_VACINAS_EM_DIA,
  dados
})

const erroAoListarVacinasEmDia = erro => ({
  type: ERRO_AO_LISTAR_VACINAS_EM_DIA,
  erro
})

export const listarVacinasEmDia = () => async dispatch => {
  dispatch({ type: LISTAR_VACINAS_EM_DIA })

  try {
    const resultado = await api.listarVacinasEmDia()

    dispatch(listouVacinasEmDia(resultado.data.dados))
    dispatch(adicionarNotificacoesDaApi(resultado))
  } catch (erro) {
    dispatch(erroAoListarVacinasEmDia(erro))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar as vacinas em dia.')))
  }
}